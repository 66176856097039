/**


=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from 'react';
import { useState, useEffect } from "react"
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session'
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import $ from 'jquery';
// @mui material components
import Grid from "@mui/material/Grid"
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"
// Soft UI Dashboard PRO React components
import SuiEditor from "components/SuiEditor";
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiInput from "components/SuiInput"
import SuiSelect from "components/SuiSelect"
import SuiButton from "components/SuiButton"
import SuiTagInput from "components/SuiTagInput"


function valuetext(value) {
 return `$${value}`;
}


function ValueLabelComponent(props) {
 const { children, value } = props;


 return (
     <Tooltip enterTouchDelay={0} placement="top" title={value} open="true">
       {children}
     </Tooltip>
   );
}


ValueLabelComponent.propTypes = {
 children: PropTypes.element.isRequired,
 value: PropTypes.number.isRequired,
};

const CustomSlider = styled(Slider)({
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: 'transparent !important',
    color: 'transparent !important',
  },
  '& .css-1cjy8su-MuiSlider-track': {
    'background-image': 'none',
  },  
});

function OnePage({ 
  formData, 
  firstView, 
  setSelectedRoleCommitment, 
  selectedRoleBenefitsIds, 
  setSelectedRoleBenefitsIds, 
  commitmentOptions, 
  skillLevels, 
  setSkillLevels, 
  fullCountriesList, 
  selectedRoleCommitment, 
  showMaxSalary, 
  setShowMaxSalary }) {

  // States Start
  const method = [
   { value: 'dh', label: "Direct Hire (contractor)" },
   { value: 'dhp', label: "Direct Hire (payroll)" },
   { value: 'sa', label: "Staff Augmentation" },
 ]
 const jobtype = [
    { value: 'r', label: "Remote" },
    { value: 'rr', label: "Remote + Relocation" },
    { value: 'ir', label: "Immediate Relocation" },
    { value: 'os', label: "On-site" },
    { value: 'hy', label: "Hybrid" }
 ]

 const seniority = [
   { value: 'jr', label: "Junior (1-2 years of experience)" },
   { value: 'ml', label: "Mid Level (3-5 years of experience)" },
   { value: 'sr', label: "Senior (6+ years of experience)" },
 ]
 const category = [
   { value: 'eng', label: "Engineering" },
   { value: 'qa', label: "QA" },
   { value: 'dg', label: "Design" },
   { value: 'do', label: "DevOps" },
   { value: 'da', label: "Data" },
   { value: 'pro', label: "Product" },
   { value: 'mg', label: "Management" },
   { value: 'mk', label: "Marketing" },
   { value: 'sl', label: "Sales" },
   { value: 'ta', label: "Talent Acquisition" },
   { value: 'op', label: "Operations" }
 ]


 ReactSession.setStoreType("localStorage")
 const token = ReactSession.get("token")
 let lastApplySesion = ReactSession.get("lastApply")
 const [lastApply, setLastApply] = useState({})


 const override = css`
   display: block;
   margin: 0 auto;
   border-color: red;
 `;


 const searchParams = new URLSearchParams(window.location.search)
 const jobId = searchParams.get('id')


 let defaultLoading = false;
 if (jobId) {
   defaultLoading = true;
 }
 const [loading, setLoading] = useState(defaultLoading); // eslint-disable-line


 /* BENEFITS PICKER */
 const [benefitsOpts, setBenefitsOpts] = useState([])
 const [selectedRoleBenefitsNames, setSelectedRoleBenefitsNames] = useState([])



 const { formField, values, errors, touched } = formData;
  const { bio } = formField;
  const { bio: bioV } = values;

  let defaultDescriptionValue = "";
  if (lastApply.description) defaultDescriptionValue = lastApply.description;

  const [companyBio, setCompanyBio] = useState("");
  const [description, setDescription] = useState(defaultDescriptionValue);

  const defaultRSkills = [];
  const defaultOSkills = [];
  if (lastApply.requiredSkills) {
    // eslint-disable-next-line
    for (const requiredSkill of lastApply.requiredSkills) {
      defaultRSkills.push(`${requiredSkill.skill.name} - ${requiredSkill.level}`);
    }
  }
  if (lastApply.optionalSkills) {
    // eslint-disable-next-line
    for (const optionalSkill of lastApply.optionalSkills) {
      defaultOSkills.push(`${optionalSkill.skill.name} - ${optionalSkill.level}`);
    }
  }

  const [skills, setSkills] = useState([]);
  const [skillLevelsExp, setSkillLevelsExp] = useState(defaultRSkills);
  const [optionalSkillLevels, setOptionalSkillLevels] = useState(defaultOSkills);



  const [questionsCount, setQuestionsCount] = useState(lastApply?.questions?.length) // eslint-disable-line
  const [countries, setCountries] = useState([]);

  // Stores wether the job has an hourly rate or not. Freelance positions have hourly rate.
  const hasHourlyRate = selectedRoleCommitment === 3


  let defaultValue = [5000, 10000];
  if (lastApply.minSalary && lastApply.maxSalary) defaultValue = [Number(lastApply?.minSalary), Number(lastApply?.maxSalary)];
  if (hasHourlyRate) defaultValue = [30, 50]
  const [value, setValue] = React.useState(defaultValue);

  let noMaxSalaryDefaultValue = 5000
  if (lastApply.minSalary) noMaxSalaryDefaultValue = Number(lastApply.minSalary)
  if (hasHourlyRate) noMaxSalaryDefaultValue = 30
  const [noMaxSalaryValue, setNoMaxSalaryValue] = React.useState(noMaxSalaryDefaultValue)
  // States End

  // Common Start
 useEffect(() => {
   lastApplySesion = ReactSession.get("lastApply");


   if (firstView && jobId) {
     const headers = { "Content-Type": "application/x-www-form-urlencoded" };
     let url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/jobStandalone/`;
     if (token) {
       url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/jobDetail/?jobId=${jobId}`;
       headers.Authorization = `Token ${token}`;
     }
     fetch(url, { headers, })
       .then(async response => {
         const res = await response.json();
         validateToken(res) // eslint-disable-line
         if (res.success) {
           // eslint-disable-next-line
           const job = res.data.job;
           lastApplySesion = {};
           lastApplySesion.name = job.name;
           lastApplySesion.hiringMethod = job.hiringMethod;
           lastApplySesion.location = job.location;
           lastApplySesion.seniority = job.seniority;
           lastApplySesion.category = job.category;


           lastApplySesion.description = job.description;
           lastApplySesion.companyDescription = job.companyDescription;


           lastApplySesion.requiredSkills = [];
           lastApplySesion.optionalSkills = [];


           // eslint-disable-next-line
           for (const requiredSkill of job.requiredSkills) {
             lastApplySesion.requiredSkills.push({ "level": requiredSkill.level, "skill": { "id": requiredSkill.skill.id, "name": requiredSkill.skill.name } });
           }
           // eslint-disable-next-line
           for (const optionalSkill of job.optionalSkills) {
             lastApplySesion.optionalSkills.push({ "level": optionalSkill.level, "skill": { "id": optionalSkill.skill.id, "name": optionalSkill.skill.name } });
           }


           lastApplySesion.questions = [];
           lastApplySesion.jobQuestions = [];
           // eslint-disable-next-line
           for (const question of job.questions) {
             if (!question.isJobQuestion) {
               lastApplySesion.questions.push({ "title": question.title });
             } else {
               lastApplySesion.jobQuestions.push({ "title": question.title });
             }
           }


           lastApplySesion.countries = [];
           // eslint-disable-next-line
           for (const country of job.countries) {
             lastApplySesion.countries.push({ "name": country.name, "id": country.id });
           }
           lastApplySesion.minSalary = job.minSalary;
           lastApplySesion.maxSalary = job.maxSalary;
           lastApplySesion.applicationsCount = job.applicationsCount;


           /* UPDATE SELECTED BENEFITS NAMES */
           const benefitsNamesArr = []
           job?.benefits?.forEach(item => benefitsNamesArr?.push(item?.name))
           if (benefitsNamesArr.length > 0) {
             setSelectedRoleBenefitsNames(benefitsNamesArr)
           }


           /* UPDATE SELECTED BENEFITS IDS */
           const benefitsIdsArr = []
           job?.benefits?.forEach(item => benefitsIdsArr?.push({id: parseInt(item?.id, 10)}))
           if (benefitsIdsArr.length > 0) {
             lastApplySesion.benefits = benefitsIdsArr
             setSelectedRoleBenefitsIds(benefitsIdsArr)
           }


           ReactSession.set("lastApply", lastApplySesion);
           setLastApply(lastApplySesion);
           setLoading(false);
         }
       })
       .catch(error => {
         console.error('There was an error!', error);
       });
   } else if (!jobId && firstView) {
     ReactSession.set("lastJobId", 0);
     ReactSession.set("lastApply", {});
     setLastApply({});
   } else {
     lastApplySesion = ReactSession.get("lastApply");
     setLastApply(lastApplySesion);
   }
 }, [])


 /* BENEFITS PICKER */
 const addBenefit = () => {
   const selectedBenefitId = $(".benefitsBox input[name=benefit]").val()


   /* UPDATE SELECTED BENEFITS IDS */
   const benefitIdWasPreviouslySelected = selectedRoleBenefitsIds?.filter(item => item?.id === parseInt(selectedBenefitId, 10))?.length > 0


   if (selectedBenefitId && !benefitIdWasPreviouslySelected) {
     const newBenefitsArr = [...selectedRoleBenefitsIds]
     newBenefitsArr.push({id: parseInt(selectedBenefitId, 10)})
     setSelectedRoleBenefitsIds(newBenefitsArr)
   }
  
   /* UPDATE NAMES BOX */
   const selectedBenefitName = benefitsOpts?.filter(opt => opt?.value === parseInt(selectedBenefitId, 10))[0]?.label
  
   if (selectedBenefitName && !selectedRoleBenefitsNames.includes(selectedBenefitName)) {
     const newNamesArr = [...selectedRoleBenefitsNames]
     newNamesArr.push(selectedBenefitName)
     setSelectedRoleBenefitsNames(newNamesArr)
   }

   $(".benefitsBox .react-tag-input").removeClass("error")
 }


 const updateBenefits = newBenefits => {
   /* UPDATE SELECTED BENEFITS IDS */
   const newBenefitsArr = []
   newBenefits?.forEach(benefit => {
     const benefitId = benefitsOpts?.filter(opt => opt?.label === benefit)[0]?.value
     newBenefitsArr.push({id: parseInt(benefitId, 10)})
   })
   setSelectedRoleBenefitsIds(newBenefitsArr)


   /* UPDATES NAMES BOX */
   setSelectedRoleBenefitsNames(newBenefits)
 }


 const getBenefitsOpts = async () => {
   try {
     const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/getBenefits/`, {
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Token ${token}`
       },
     })


     const data = await res.json()


     if (data?.success) {
       const benefits = data?.data


       const newBenefits = []
       benefits?.forEach(benefit => {
         newBenefits?.push({ value: benefit?.id, label: benefit?.name })
       })
       setBenefitsOpts(newBenefits)
     }
      
   } catch (error) {
       console.error('There was an error!', error)
   }
 }

 useEffect(() => { getBenefitsOpts() }, [])
 // Common end

 // Description Start
 // Description End

 // Experiencie Start
 // const [levelPlaceHolder, setLevelPlaceHolder] = useState("Select a English Level");
  let skillLevelStrings = [];
  let parentString = "";
  let skillLevelString = "";
  let skillString = "";
  let levelMinString = "";
  let tagtext = "";
  let timerid = "";
  // const levels = [
  //   { value: 3, label: "Junior (1-3 years)" },
  //   { value: 5, label: "Midlevel (4-6 years)" },
  //   { value: 7, label: "Senior (7+ years)" },
  // ];
  const years = [
    { value: 1, label: "1+ Year" },
    { value: 2, label: "2+ Years" },
    { value: 3, label: "3+ Years" },
    { value: 4, label: "4+ Years" },
    { value: 5, label: "5+ Years" },
    { value: 6, label: "6+ Years" },
    { value: 7, label: "7+ Years" },
    { value: 8, label: "8+ Years" },
    { value: 9, label: "9+ Years" },
    { value: 10, label: "10+ Years" },
  ]

  function changeskillPlaceholder(remove, parent) {
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      if (remove) {
        $(`${parent} .react-tag-input__input`).attr("placeholder", "");
      } else {
        $(`${parent} .react-tag-input__input`).attr("placeholder", "Skills selected...");
      }
    }, 100);
  }

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  const populateSkills = async () => {
    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const newData = [];
    // eslint-disable-next-line
    for (let i = 0; i < interests?.data?.skills?.length; i++) {
      newData.push({ value: interests?.data?.skills[i]?.name, label: interests?.data?.skills[i]?.name });
    }

    $(".requiredBox").attr("data-skills", JSON.stringify(interests?.data?.skills));

    setSkills(newData);
  }

  useEffect(() => { populateSkills() }, []);

  function notInArray(skillStr, skillArray) {
    // eslint-disable-next-line
    for (const skillLevel of skillArray) {
      if (skillLevel.includes(`${skillStr} -`)) {
        return false;
      }
    }

    return true;
  }

  function addSkill() {
    skillString = $(".requiredBox input[name=skill]").val();
    levelMinString = $(".requiredBox input[name=levelMin]").val();
    if (skillString && levelMinString && notInArray(skillString, skillLevelsExp)) {
      skillLevelString = `${skillString} - ${levelMinString}`;
      skillLevelStrings = [...skillLevelsExp];
      skillLevelStrings.push(skillLevelString);
      setSkillLevelsExp(skillLevelStrings);
      changeskillPlaceholder(1, ".skillsBoxExperiencie");
    }

    // If skill is in array, replace it with the new one
    if (skillString && levelMinString && !notInArray(skillString, skillLevelsExp)) {
      skillLevelString = `${skillString} - ${levelMinString}`;
      skillLevelStrings = [...skillLevelsExp];
      // eslint-disable-next-line
      for (let i = 0; i < skillLevelStrings.length; i++) {
        if (skillLevelStrings[i].includes(`${skillString} -`)) {
          skillLevelStrings[i] = skillLevelString;
          break;
        }
      }
      setSkillLevelsExp(skillLevelStrings);
    }

    $(".skillsBoxExperiencie .react-tag-input").removeClass("error")
  }

  function addOptionalSkill() {
    skillString = $(".optionalBox input[name=skill]").val();
    levelMinString = $(".optionalBox input[name=yearsMin]").val();
    if (skillString && levelMinString && notInArray(skillString, optionalSkillLevels)) {
      skillLevelString = `${skillString} - ${levelMinString}`;
      skillLevelStrings = [...optionalSkillLevels];
      skillLevelStrings.push(skillLevelString);
      setOptionalSkillLevels(skillLevelStrings);
      changeskillPlaceholder(1, ".optionalSkillsBox");
    }

    // If skill is in array, replace it with the new one
    if (skillString && levelMinString && !notInArray(skillString, optionalSkillLevels)) {
      skillLevelString = `${skillString} - ${levelMinString}`;
      skillLevelStrings = [...optionalSkillLevels];
      // eslint-disable-next-line
      for (let i = 0; i < skillLevelStrings.length; i++) {
        if (skillLevelStrings[i].includes(`${skillString} -`)) {
          skillLevelStrings[i] = skillLevelString;
          break;
        }
      }
      setOptionalSkillLevels(skillLevelStrings);
    }
  }

  function removeTag() {
    console.log("remove tag");
  }
 // Experiencie End

 // Questions Start
 function showError(text) {
    let timerErrorid = "";
    $(".alertErrorBox div div").text(text);
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerErrorid) {
      clearTimeout(timerErrorid);
    }
    timerErrorid = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 4000);
  }

  const questionsAddedArrayDefault = [
    { id: 0, value: "" },
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
  ]
  
  const [questionsAddedArray, setQuestionsAddedArray] = useState(questionsAddedArrayDefault);
  function addQuestion() {
    const questionVal = $("input[name=question]").val();
    if (questionVal && $(".questionButton").length <= 3 && questionVal.length <= 90) {
      if ($(".questionButton").length === 3){
        $(".addBox").hide();
      }
      setQuestionsCount($(".questionButton").length + 1);
      const questionsAddedArrayTemp = [...questionsAddedArray]
      // eslint-disable-next-line
      for (const questionAddeTemp in questionsAddedArrayTemp) {
        if (questionsAddedArrayTemp[questionAddeTemp].value === "" && questionsAddedArrayTemp[questionAddeTemp].id) {
          questionsAddedArrayTemp[questionAddeTemp].value = questionVal;
          break;
        }
      }
      setQuestionsAddedArray(questionsAddedArrayTemp)
      $("input[name=question]").val("");
    } else if (questionVal.length > 72) {
      showError("Your question cannot have more than 72 characters")
    }
  }
  
  function removeQuestion(questionToRemove) {
    const questionsAddedArrayTemp = [...questionsAddedArray]
    questionsAddedArrayTemp[questionToRemove].value = "";
    setQuestionsAddedArray(questionsAddedArrayTemp)
    setQuestionsCount(questionsCount - 1);
    $(".addBox").show();
  }

  let noHasQuestions = false;
  if (jobId && lastApply.questions && lastApply.questions.length === 0) {
    noHasQuestions = true;
  }
 // Questions End

 // Salary Start
 let skillLevelStringsSalary = [];
  let skillStringSalary = "";
  let tagtextSalary = "";


 function changeskillPlaceholderSalary(remove) {
   if (timerid) {
     clearTimeout(timerid);
   }
   timerid = setTimeout(() => {
     if (remove) {
       $(".react-tag-input__input").attr("placeholder", "");
     } else {
       $(".react-tag-input__input").attr("placeholder", "Countries selected...");
     }
   }, 100);
 }


 function loadCountries(setTags) {
   const newData = [];
   const countryTags = [];
   const initCountries = [];
   // eslint-disable-next-line
   Object.keys(fullCountriesList).forEach(function (key) {
     if (fullCountriesList[key] && !fullCountriesList[key].excludeInJobs) {
       newData.push({ value: fullCountriesList[key].name, label: fullCountriesList[key].name });
       countryTags.push(fullCountriesList[key].name);
       initCountries.push(fullCountriesList[key]);
     }
   });


   $(".skillsBoxSalary").attr("data-countries", JSON.stringify(initCountries));
   setCountries(newData);
   if (setTags) {
     setSkillLevels(countryTags);
   }
 }


 useEffect(() => {
   // lastApply = ReactSession.get("lastApply");
   if (lastApply.countries && lastApply.countries.length) {
     const countryTags = [];
     // eslint-disable-next-line
     for (const countryIndex in lastApply.countries) {
       countryTags.push(lastApply.countries[countryIndex].name);
     }
     setSkillLevels(countryTags);
     loadCountries(false);
   }


   if (lastApply.minSalary && lastApply.maxSalary) {
     $(".salaryRange").attr("data-min", lastApply.minSalary).attr("data-max", lastApply.maxSalary);
   }


 }, []);


 const handleChange = (event, newValue) => {
   setValue(newValue);
   $(".salaryRange").attr("data-min", newValue[0]).attr("data-max", newValue[1]);
 };

 const handleNoMaxSalaryChange = (event, newValue) => {
    setNoMaxSalaryValue(newValue);
    $(".salaryRange").attr("data-min", newValue);
  };

 function notInArraySalary(skillStr) {
   // eslint-disable-next-line
   for (const skillLevel of skillLevels) {
     if (skillLevel === skillStr) {
       return false;
     }
   }


   return true;
 }


 function addCountry() {
   skillStringSalary = $("input[name=countries]").val();
   if (skillStringSalary && notInArraySalary(skillStringSalary)) {
     skillLevelStringsSalary = [...skillLevels];
     skillLevelStringsSalary.push(skillStringSalary);
     setSkillLevels(skillLevelStringsSalary);
     changeskillPlaceholderSalary(1);
   }
 }


 // eslint-disable-next-line
 $(document).unbind().on("click", ".skillsBoxSalary .react-tag-input__tag__remove, .skillsBoxExperiencie .react-tag-input__tag__remove, .optionalSkillsBox .react-tag-input__tag__remove, .validateField", function () {
  if ($(this).hasClass("validateField")) {
    $(this).removeClass("error");
  } else {
    // eslint-disable-next-line
     if ($(this).parents(".skillsBoxSalary").length) {
       tagtextSalary = $(this).prev().text();
       if (tagtextSalary === "Anywhere in LATAM") {
         loadCountries(true);
       } else {
         skillLevelStringsSalary = [...skillLevels];
         if (skillLevelStringsSalary.length && skillLevelStringsSalary.indexOf(tagtextSalary) >= 0) {
           skillLevelStringsSalary.splice(skillLevelStringsSalary.indexOf(tagtextSalary), 1);
           setSkillLevels(skillLevelStringsSalary);
           if (!skillLevelStringsSalary.length) {
             changeskillPlaceholderSalary(0);
           }
         }
       }
     } else {
      tagtext = $(this).prev().text();
      skillLevelStrings = [...skillLevelsExp];
      parentString = ".skillsBoxExperiencie";
      if ($(this).parents(".optionalSkillsBox").length) {
        skillLevelStrings = [...optionalSkillLevels];
        parentString = ".optionalSkillsBox";
      }
      if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
        skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1);
        if ($(this).parents(".skillsBoxExperiencie").length) {
          setSkillLevelsExp(skillLevelStrings);
        }
        if ($(this).parents(".optionalSkillsBox").length) {
          setOptionalSkillLevels(skillLevelStrings);
        }
        if (!skillLevelStrings.length) {
          changeskillPlaceholder(0, parentString);
        }
      }
     }
  }
 });
 // Salary End



 return (
   <SuiBox>
     { (loading || benefitsOpts.length === 0) ? (
       <SuiBox className="moonItem" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={0}>
         <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
       </SuiBox>
     ) : null }
     <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
       <SuiTypography variant="h5" fontWeight="regular">What role are you looking for?</SuiTypography>
     </SuiBox>
     <SuiBox>
       <Grid container>
         <Grid item xs={12} mb={2}>
           <SuiTypography variant="button" ml={1}>Role title</SuiTypography>
           <SuiTypography variant="overline" color="secondary" ml={1}>*Please do not include seniority level in your title</SuiTypography>
           <SuiInput
             placeholder="i.e. Backend Developer (max 40 characters)"
             name="name"
             defaultValue={lastApply.name}
             key={lastApply.name}
             inputProps={{ maxLength: 40 }}
             className="validateField"
           />
         </Grid>

         <Grid item xs={12} mb={2}>
           <SuiTypography variant="button" ml={1}>Subtitle</SuiTypography>
           <SuiTypography variant="overline" color="secondary" ml={1}>*Add a brief elevator pitch for your company or this opportunity (optional)</SuiTypography>
           <SuiInput
             placeholder="i.e. We are revolutionizing the payments industry! (max 120 characters)"
             name="subtitle"
             defaultValue=""
             key={lastApply.name}
             inputProps={{ maxLength: 120 }}
             className="validateField"
           />
         </Grid>


         <Grid item xs={12} mb={2}>
           <SuiTypography variant="button" ml={1} mb={1}>
               Role seniority
             </SuiTypography>
             {lastApply.seniority ? (
               <SuiSelect
                 placeholder="Select a seniority level"
                 options={seniority}
                 name="seniority"
                 defaultValue={seniority.find(({ tempValue }) => tempValue === lastApply.seniority)}
                 key={lastApply.seniority}
                 className="validateField"
               />
             ) : (
               <SuiSelect
                 placeholder="Select a seniority level"
                 options={seniority}
                 name="seniority"
                 className="validateField"
               />
             )}
         </Grid>
        
         <Grid container spacing={3}>
           <Grid item xs={6} mb={2}>
             <SuiTypography variant="button" ml={1} mb={1}>
                 Role category
               </SuiTypography>
               {lastApply.category ? (
                 <SuiSelect
                   placeholder="Select category"
                   options={category}
                   name="category"
                   defaultValue={category.find(({ tempValue }) => tempValue === lastApply.category)}
                   key={lastApply.category}
                   className="validateField"
                 />
               ) : (
                 <SuiSelect
                   placeholder="Select category"
                   options={category}
                   name="category"
                   className="validateField"
                 />
               )}
           </Grid>


           <Grid item xs={6} mb={2}>
             <SuiTypography variant="button" ml={1} mb={1}>
                 Commitment
               </SuiTypography>
               {lastApply?.commitment ? (
                 <SuiSelect
                   placeholder="Select time commitment"
                   options={commitmentOptions}
                   name="Commitment"
                   defaultValue={commitmentOptions?.find(({ tempValue }) => tempValue === lastApply?.commitment)}
                   onChange={e => setSelectedRoleCommitment(e.value)}
                   key={lastApply?.commitment}
                   className="validateField"
                 />
               ) : (
                 <SuiSelect
                   placeholder="Select time commitment"
                   options={commitmentOptions}
                   onChange={e => setSelectedRoleCommitment(e.value)}
                   name="Commitment"
                   className="validateField"
                 />
               )}
           </Grid>
         </Grid>


         <Grid container spacing={3}>
           <Grid item xs={12} sm={6}>
             <SuiTypography variant="button" ml={1} mb={1}>
               Hiring method
             </SuiTypography>
             {lastApply.hiringMethod ? (
               <SuiSelect
                 placeholder="Select contract type"
                 options={method}
                 name="hiringMethod"
                 defaultValue={method.find(({ tempValue }) => tempValue === lastApply.hiringMethod)}
                 key={lastApply.hiringMethod}
                 className="validateField"
               />
             ) : (
               <SuiSelect
                 placeholder="Select contract type"
                 options={method}
                 name="hiringMethod"
                 className="validateField"
               />
             )}
           </Grid>
           <Grid item xs={12} sm={6}>
             <SuiTypography variant="button" ml={1} mb={1}>
               Job type
             </SuiTypography>
             {lastApply.location ? (
               <SuiSelect
                 placeholder="Select job type"
                 options={jobtype}
                 name="location"
                 defaultValue={jobtype.find(({ tempValue }) => tempValue === lastApply.location)}
                 key={lastApply.location}
                 className="validateField"
               />
             ) : (
               <SuiSelect
                 placeholder="Select job type"
                 options={jobtype}
                 name="location"
                 className="validateField"
               />
             )}
           </Grid>
         </Grid>


         {/* BENEFITS PICKER */}
         <Grid item xs={12} mb={2}>
           <SuiBox mt={2} ml={1} mb={0.5}>
             <SuiTypography variant="button">
               Job benefits
             </SuiTypography>
           </SuiBox>
          
           <Grid container xs={12} md={12} spacing={1} className="benefitsBox">
             <Grid item xs={12} sm={6}>
               <SuiSelect
                 placeholder="Benefit"
                 options={benefitsOpts}
                 name="benefit"
               />
             </Grid>


             <Grid item xs={12} sm={6}>
               <SuiButton color="info" onClick={addBenefit} fullWidth>Add</SuiButton>
             </Grid>
            
             <Grid item xs={12} md={12} className="benefitsBox">
               <SuiTagInput
                 placeholder="Benefits selected..."
                 tags={selectedRoleBenefitsNames}
                 size="large"
                 onChange={updateBenefits}
               />
             </Grid>
           </Grid>
         </Grid>


       </Grid>
     </SuiBox>


     <SuiBox width="100%" textAlign="center" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Add a detailed description for your job</SuiTypography>
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">Company description</SuiTypography>
        </SuiBox>

        <SuiEditor
          maxLength={5000}
          name={bio.name}
          onChange={setCompanyBio}
          placeholder="Company description (optional - max 5000 chars.)"
          error={errors.bio && touched.bio}
          success={bioV.length > 0 && !errors.bio}
        />

        <SuiTypography
          variant="overline"
          color={companyBio.length > 5000 ? "error" : "secondary"}
          textAlign="right"
        >
          {companyBio.length}/5000 chars.
        </SuiTypography>
      </SuiBox>

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mb={4}
      >
        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SuiTypography variant="button">Job description</SuiTypography>
          <SuiTypography variant="overline" color="secondary" ml={1}>(responsibilities, requirements, benefits, etc.)</SuiTypography>
        </SuiBox>

        <SuiEditor
          defaultValue={description}
          value={description}
          name="description"
          placeholder="Job description (max 15000 chars.)"
          onChange={setDescription}
          maxLength={15000}
          className="validateField"
        />

        <SuiTypography
          variant="overline"
          color={description.length > 15000 ? "error" : "secondary"}
          textAlign="right"
        >
          {description.length}/15000 chars.
        </SuiTypography>

        <span data-name="description" data-html={description} data-bio={companyBio} style={{ display: "none" }} >test</span>
      </SuiBox>


      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">
          {/* Necessary experience to fulfill the role */}
          What skills does this position require?
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={2}>
        <SuiBox ml={1} mb={0.5}>
          <SuiTypography variant="button">
            Required skills
          </SuiTypography>
        </SuiBox>
        <Grid container xs={12} md={12} spacing={1} className="requiredBox">
          <Grid item xs={12} sm={5}>
            <SuiSelect
              placeholder="Skill"
              options={skills}
              name="skill"
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SuiSelect
              placeholder="Years"
              options={years}
              name="levelMin"
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <SuiButton color="info" onClick={addSkill} fullWidth>Add</SuiButton>
          </Grid>
          <Grid item xs={12} md={12} className="skillsBoxExperiencie validateField">
            <SuiTagInput
              placeholder="Skills selected..."
              tags={skillLevelsExp}
              size="large"
              onChange={removeTag}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={2}>
        <SuiBox ml={1} mb={0.5}>
          <SuiTypography variant="button">
            Optional skills
          </SuiTypography>
        </SuiBox>
        <Grid container xs={12} md={12} spacing={1} className="optionalBox">
          <Grid item xs={12} sm={5}>
            <SuiSelect
              placeholder="Skill"
              options={skills}
              name="skill"
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SuiSelect
              placeholder="Years"
              options={years}
              name="yearsMin"
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <SuiButton color="info" onClick={addOptionalSkill} fullWidth>Add</SuiButton>
          </Grid>
          <Grid item xs={12} md={12} className="optionalSkillsBox">
            <SuiTagInput
              placeholder="Skills selected..."
              tags={optionalSkillLevels}
              size="large"
              onChange={removeTag}
            />
          </Grid>
        </Grid>
      </SuiBox>

      <br/>
      <SuiBox width="100%" textAlign="center" mb={4} mt={2}>
        <SuiTypography variant="h5" fontWeight="regular">
          Please enter any pre-screening questions you would like candidates to answer (Optional)
        </SuiTypography>
        { noHasQuestions || lastApply.applicationsCount ? (
          <SuiTypography variant="overline" color="secondary" ml={1}>This position already have applications, it&apos;s questions can&apos;t be edited</SuiTypography>
        ) : null }
      </SuiBox>
      <SuiBox mt={2}>
        <SuiBox ml={1} mb={0.5}>
          { !lastApply.applicationsCount ? (
            <SuiTypography variant="button">
              Add up to three questions (optional):
            </SuiTypography>
          ) : null }
        </SuiBox>
        <Grid container>
          {/*  */}
          { !lastApply.applicationsCount ? (
            <Grid container xs={12} spacing={1} mb={1} className="addBox">
              <Grid item xs={10}>
                <SuiInput placeholder="Type your question here" name="question" inputProps={{ maxLength: 120 }}/>
              </Grid>
              <Grid item xs={2} >
                <SuiButton color="info" onClick={addQuestion} fullWidth>Add</SuiButton>
              </Grid>
            </Grid>
          ) : null }

          { lastApply && lastApply.jobQuestions && lastApply.jobQuestions.length ? (<>
            {lastApply.jobQuestions.map(question => (
              <Grid container xs={12} spacing={1} mb={1} className="">
                <Grid item xs={10}>
                  <SuiInput placeholder="..." defaultValue={question.title} disabled />
                </Grid>
              </Grid>
            ))}
          </>) : null }
          { questionsAddedArray && questionsAddedArray.length ? (<>
            {questionsAddedArray.map(questionsAdded => (
              <>
                { questionsAdded.value && questionsAdded.id ? (
                  <Grid container xs={12} spacing={1} mb={1} className="questionBox">
                    <Grid item xs={10}>
                      <SuiInput placeholder="..." disabled defaultValue={questionsAdded.value} />
                    </Grid>
                    <Grid item xs={2} >
                      <SuiButton color="error" variant="outlined" onClick={() => removeQuestion(questionsAdded.id)} className="questionButton" name="questionValue" fullWidth>Remove</SuiButton>
                    </Grid>
                  </Grid>
                ) : null }
              </>
            ))}
          </>) : null }

          <Grid container xs={12} spacing={1} mb={1} className="questionBox" style={{ opacity: 0 }}>
            <Grid item xs={10}>
              <SuiInput placeholder="..." disabled />
            </Grid>
            <Grid item xs={2} >
              <SuiButton color="error" variant="outlined" onClick={removeQuestion} className="questionButton" name="questionValue" fullWidth>Remove</SuiButton>
            </Grid>
          </Grid>

        </Grid>
      </SuiBox>


      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">
          How much are you offering for this job?
        </SuiTypography>
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        width="100%"
        height="100%"
        mt={4}
        mb={5}
      >
        <SuiBox
          mb={3}
          lineHeight={0}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {hasHourlyRate ?
            <SuiTypography variant="button">What is your hourly rate offering for this position?</SuiTypography>
            :
            <SuiTypography variant="button">What is your monthly salary offering for this position?</SuiTypography>
          }
        </SuiBox>


        <Grid container spacing={3} className="salaryRange" data-min="0" data-max="20000">
          <Grid item xs={12}>
            <SuiBox mt={5}>
              {showMaxSalary ?
                <>
                  {hasHourlyRate ? 
                    <Slider
                      getAriaLabel={() => 'Salary range'}
                      value={value}
                      onChange={handleChange}
                      getAriaValueText={valuetext}
                      components={{ ValueLabel: ValueLabelComponent }}
                      valueLabelDisplay="on"
                      step={1}
                      min={1}
                      max={100}
                    />
                  :
                    <Slider
                      getAriaLabel={() => 'Salary range'}
                      value={value}
                      onChange={handleChange}
                      getAriaValueText={valuetext}
                      components={{ ValueLabel: ValueLabelComponent }}
                      valueLabelDisplay="on"
                      step={100}
                      min={100}
                      max={20000}
                    />
                  }
                </>
                :
                <>
                  {hasHourlyRate ? 
                    <CustomSlider
                      getAriaLabel={() => 'Salary range'}
                      value={noMaxSalaryValue}
                      onChange={handleNoMaxSalaryChange}
                      getAriaValueText={valuetext}
                      components={{ ValueLabel: ValueLabelComponent }}
                      valueLabelDisplay="on"
                      step={1}
                      min={1}
                      max={100}
                      // edit styles so the mui slider highlights from the right side instead than from the left side
                    />
                  :
                    <CustomSlider
                      getAriaLabel={() => 'Salary range'}
                      value={noMaxSalaryValue}
                      onChange={handleNoMaxSalaryChange}
                      getAriaValueText={valuetext}
                      components={{ ValueLabel: ValueLabelComponent }}
                      valueLabelDisplay="on"
                      step={100}
                      min={100}
                      max={20000}
                    />
                  }
                </>
              }
            </SuiBox>
          </Grid>
        </Grid>

        <SuiBox
            mb={3}
            lineHeight={0}
            width="100%"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >          
            <SuiBox>
              <Checkbox checked={showMaxSalary} onChange={() => setShowMaxSalary(prev => !prev)}/>
              <SuiTypography variant="button" fontWeight="regular" sx={{ userSelect: "none" }}>Include max salary</SuiTypography>
            </SuiBox>
          </SuiBox>
      </SuiBox>

      <SuiBox mt={2} style={{ "zIndex": 2 }}>
        <SuiBox ml={1} mb={0.5}>
          <SuiTypography variant="button">
            Hire from:
          </SuiTypography>
        </SuiBox>
        <Grid container xs={12} md={12} spacing={1}>
          <Grid item xs={8}>
            <SuiSelect
              placeholder="Anywhere in LATAM"
              options={countries}
              name="countries"
            />
          </Grid>
          <Grid item xs={4} >
            <SuiButton color="info" onClick={addCountry} fullWidth>Add</SuiButton>
          </Grid>
          <Grid item xs={12} md={12} className="skillsBoxSalary">
            <SuiTagInput
              placeholder="Countries selected..."
              tags={skillLevels}
              onChange={removeTag}
            />
          </Grid>
        </Grid>
      </SuiBox>
   </SuiBox>
 );
}


OnePage.defaultProps = {
 firstView: false,
}


OnePage.propTypes = {
 skillLevels: PropTypes.array.isRequired, // eslint-disable-line
 setSkillLevels: PropTypes.func.isRequired,
 fullCountriesList: PropTypes.array.isRequired, // eslint-disable-line
 selectedRoleCommitment: PropTypes.object.isRequired, // eslint-disable-line
 showMaxSalary: PropTypes.bool.isRequired,
 setShowMaxSalary: PropTypes.func.isRequired,
 firstView: PropTypes.bool,
 setSelectedRoleCommitment: PropTypes.func.isRequired,
 selectedRoleBenefitsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
 setSelectedRoleBenefitsIds: PropTypes.func.isRequired,
 formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
 commitmentOptions: PropTypes.array.isRequired // eslint-disable-line
}


export default OnePage
