/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
// import borders from "assets/theme/base/borders";
// import typography from "assets/theme/base/typography";
// import colors from "assets/theme/base/colors";
// import boxShadows from "assets/theme/base/boxShadows";

// // Soft UI Dashboard PRO React helper functions
// import linearGradient from "assets/theme/functions/linearGradient";
// import pxToRem from "assets/theme/functions/pxToRem";

// const { borderRadius } = borders;
// const { fontWeightMedium, fontWeightBold, size } = typography;
// const { gradients, transparent, white } = colors;
// const { buttonBoxShadow } = boxShadows;
import spellingBg from "assets/images/spelling-bg.png";

export default {
  ".moonLoaderBox": {
    width: "100%",
    height: "100%",
    position: "absolute",
    paddingTop: "20%",
    background: "rgba(222, 222, 222, 0.3) !important",
    display: "none !important",

    "&.active": {
      display: "block !important",
      zIndex: "9 !important",
      textAlign: "center !important",
    },
  },

  ".MuiModal-root table": {
    maxHeight: "500px",
    overflowX: "auto",
    display: "block"
  },

  "td.tableEditCell": {
    paddingRight: "35px",
    position: "relative",

    "&.editing svg": {
      display: "none",

      "&.closeNote": {
        display: "block",
      },
    },

    "& svg": {
      position: "absolute",
      top: "10px",
      right: "10px",
      cursor: "pointer",
      width: "20px",
      height: "20px",

      "&.closeNote": {
        display: "none",
      },
    },
  },

  ".talentRight": {
    float: "right",

    "&.talentInput": {
      width: "calc(100% - 590px)",
      marginRight: "10px"
    },

    "& .MuiInputBase-root": {
      width: "600px !important",
      top: "0 !important",
      maxWidth: "95%",
      float: "right",
    }
  },

  ".moonBox": {
    position: "relative",

    "& .moonItem": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex: 9,
      background: "rgba(255, 255, 255, 0.3)"
    }
  },

  ".profileIcon": {
    width: "1em",
    height: "1em",
  },

  "div.user-globe": {
    width: "35px",
    height: "35px",
    background: "linear-gradient(135deg, rgba(33,82,255,1) 0%, rgba(33,212,253,1) 90%)",
    borderRadius: "18px",
    display: "inline-block",
    margin: "0 0 0 5px",
    verticalAlign: "top",
    color: "#fff",
    border: "1px rgba(33,212,253,1)",
    cursor: "default",

    "& h6": {
      color: "#fff",
      fontSize: "0.85rem",
      fontWeight: "200",
      paddingTop: "6px",
    },

    "&.isRecruiter": {
      background: "#F8F9FA",
      border: "1px #e2e6e9",
      borderStyle: "solid !important",
      width: "36px",
      height: "36px",

      "& h6": {
        color: "#000",
      },
    },

    "& img": {
      height: "100%",
      width: "100%",
      borderRadius: "18px",
    }
  },

  ".closeFileBox": {
    position: "relative",

    "& .closeFile": {
      position: "absolute",
      right: "5px",
      top: "5px",
    }
  },

  ".swal2-popup .swal2-confirm.btn-custom-size, .swal2-popup .swal2-cancel.btn-custom-size": {
    fontSize: "16px !important",
    color: "#fff !important",
    fontWeight: "500 !important",
  },

  ".notificationsBox": {
    height: "100%",
    width: "100%",
    padding: "0px 6px",
    position: "relative",

    "& svg": {
      marginBottom: "-3px"
    },

    "& .count": {
      color: "#fff",
      fontSize: "10px",
      fontWeight: "bold",
      position: "absolute",
      right: "-7px",
      bottom: "-7px",
      padding: "2px 0",
      borderRadius: "20px",
      background: "#8392ab",
      width: "20px",
      textAlign: "center",
      opacity: "0",
    },

    "&.active .count": {
      opacity: "1"
    },

    "&.modal": {
      width: "19px",
      height: "19px",
      position: "absolute",
      left: "5px",
      top: "14px",
      background: "red",
      borderRadius: "15px",
      fontSize: "14px",
      padding: "2px 3px",
      color: "#fff",

      "& svg": {
        marginBottom: "0"
      },
    }

  },

  ".deleteNotifications": {
    position: "absolute",
    bottom: "0",
    right: "0",
  },

  ".skillsBox.readOnly .react-tag-input__tag__remove": {
    display: "none",
  },

  ".skillsBox.readOnly .react-tag-input__tag__content": {
    padding: "3px 10px !important",
  },

  ".rateIconsBox": {
    "&.upRated .upRate": {
      color: "#3e71c9 !important",
    },

    "&.downRated .downRate": {
      color: "#3e71c9 !important",
    },
  },

  ".rateIconsBox .upRate, .rateIconsBox .downRate": {
    display: "inline-block",
    "&:hover": {
      color: "#3e71c9",
      cursor: "pointer",
    },
  },

  ".dropdownRadio": {
    float: "right", 
    width: "15px", 
    height: "15px", 
    marginTop: "2px",
    cursor: "pointer"
  },

  ".closeMedia": {
    position: "absolute", 
    top: "10px", 
    right: "10px",
    cursor: "pointer"
  },

  "div.videoList": {
    background: "rgba(221, 221, 221, 0.5)", 
    border: "1px solid rgba(221, 221, 221, 0.5)",
    borderRadius: "10px",
    marginTop: "10px",
    "&:hover, &.videoActive": {
      cursor: "pointer",
      background: "rgba(139, 139, 139, 0.3)", 
      border: "1px solid rgba(139, 139, 139, 0.7)",
    },
  },

  ".MuiCollapse-vertical .videoActive": {
    fontWeight: 600,
  },

  ".videoIframe iframe, .audioIframe, .audioPdfIframe": {
    maxWidth: "100% !important"
  },

  ".audioIframe, .audioPdfIframe": {
    width: "100%",
    marginTop: "10px",
  },

  ".audioImage": {
    width: "100%",
    marginTop: "10px",
    height: "560px",
    display: "block",
    textAlign: "center",

    "& img": {
      maxHeight: "500px",
      display: "inline-block",
      verticalAlign: "middle",
      maxWidth: "calc(100% - 2px)",
    },

    "& .audioImageHelper": {
      width: "1px",
      height: "100%",
      display: "inline-block",
      verticalAlign: "middle",
    },
  },

  

  ".videoButtons button": {
    marginTop: "10px", 
    marginLeft: "5px",
    "&:hover": {
      cursor: "pointer",
      background: "rgba(242, 239, 244, 0.5)",
    },
  },

  ".customCourse": {
    marginBottom: "15px",
    width: "98%",
    margin: "0 auto",

    "& .courseProgress": {
      margin: "20px 0 30px 0", 
      padding: "20px 30px 25px 30px" , 
      border: "2px solid #edeff2", 
      borderRadius: "10px", 
      background: "#fff"
    },
  },

  ".customAccordion": {
    boxShadow: 'none !important', 
    background: "#edeff2 !important", 
    border: '1px solid rgba(0, 0, 0, .125)',
    margin: "-2px !important",

    "&:first-child": {
      borderRadius: "10px 10px 0 0 !important",
    },

    "& .Mui-expanded, & .MuiAccordionSummary-content": {
      margin: "0 !important"
    },
  },

  ".courseCollapse, .lessonCollapse": {
    margin: "0 !important",
    padding: "15px 5px !important",

    "& span": {
      fontWeight: "bold !important",
      fontSize: "16px !important",
    },
  },

  ".courseCollapse": {
    background: "#fff",
    borderRadius: "0 !important",
    padding: "15px 20px 15px 0 !important",
    minWidth: "150px",

    "& span": {
      fontWeight: "bold !important",
      fontSize: "18px !important",
      margin: "0 8px 0 0px !important",
    },

    "& .MuiListItemText-root": {
      marginLeft: "0",
    },

    
  },

  ".courseCollapseChildren": {
    background: "#fff", 
    zIndex: 9, 
    position: "absolute"
  },

  ".courseTypography": {
    fontWeight: "normal", 
    padding: "7px 15px 5px 15px", 
    cursor: "pointer", 
    background: "#fff", 
    margin: "0 !important", 
    minWidth: "175px",
    "&:hover": {
      background: "#f9f9f9 !important"
    },
  },

  ".spellingBg": {
    backgroundImage: `url(${spellingBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "cover",
  },

  ".vimeos": {
    width: "calc(100% - 300px)",
    height: "100%",
    display: "inline-block",
  },

  ".aiContainer": {
    position: "fixed",
    width: "100%",
    height: "100%",
    padding: "0 0 0 275px !important",
    left: 0,
    top: 0,
    marginTop: "0 !important",
    "& .aiBox": {
      padding: "20px 0 135px 0!important",
      background: "#f8f9fa",
      height: "100%",
      overflow: "hidden",
      position: "relative",
      "& .aiDialog": {
        width: "100%",
        display: "block",
        height: "100%",
        overflow: "auto",
        padding: "0 33px 0 38px",
        "& .aiRow": {
          background: "#f8f9fa",
          "&.bot": {
            background: "#fff",
          },
          "& .aiCell": {
            padding: "20px 35px 20px 35px",
            "& p": {
              display: "inline-block",
              verticalAlign: "top",
              marginLeft: "10px",
              maxWidth: "calc(100% - 35px)",
            },
          },
        },
      }
    },
    "& .aiInput": {
      padding: "0 35px !important",
      background: "transparent",
      position: "absolute",
      bottom: "45px",
      left: 0,
      width: "100%",
    }
  },

  ".botThinking": {
    color: "#8392ab",
    fontSize: "1rem",
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "5px",
  },

  ".coachTypes": {
    "&.disabledCoach > div": {
      background: "rgba(0, 0, 0, 0.1)",
      "&:hover, &.active": {
        boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.3)",
        cursor: "auto"
      },
    },
    "& > div": {
      boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
      paddingBottom: "20px",
      "&:hover, &.active": {
        boxShadow: "0px 0px 2px 1px rgba(33, 82, 255, 0.9)",
        cursor: "pointer"
      },
    },
  },

  ".MuiFormGroup-root": {
    "& label": {
      marginBottom: "5px",
      "& span": {
        verticalAlign: "middle",
        "&.MuiFormControlLabel-label": {
          maxWidth: "calc(100% - 40px)",
        },
      },
    },
  },
  
  ".validateField": {
    "&.error": {
      borderColor: "#fd5c70 !important",
      border: "1px solid",
      borderRadius: "9px",
    },
    "& .react-tag-input": {
      "&.error": {
        borderColor: "#fd5c70 !important",
        border: "1px solid",
        borderRadius: "9px",
      },
    },
  },

  ".pdfBackground": {
    "& > div": {
      background: "rgb(82, 87, 88) !important",
      borderRadius: "5px",
    },
  },

  


  




  ".application-box": {
    "& span.MuiBadge-badge": {
      whiteSpace: "normal",
      textAlign: "left",
      borderRadius: "1rem",
      lineHeight: "1rem",
    }
  },

  ".bottomRight": {
    position: "absolute",
    right: "20px",
    bottom: "15px",
  },

  ".noteContent": {
    "& img": {
      maxWidth: "20px",
    }
  },

  ".welcomeWizard": {
    background: "rgba(255, 255, 255, 0.9)",
  },

  ".alertButton": {
    border: "1px solid rgb(23, 193, 232)",
    background: "transparent",
    borderRadius: "160px",
    color: "rgb(23, 193, 232) !important",
    display: "block",
    fontSize: "12px",
    fontWeight: "700",
    margin: "0 auto",
    padding: "12px 24px",
    width: "fit-content",
    transition: "transform .2s",

    "&:hover": {
      cursor: "pointer",
      border: "1px solid rgba(23, 193, 232, 0.8)",
      color: "rgba(23, 193, 232, 0.8) !important",
      transform: "scale(1.05)",
    },
  },

  ".moonCover": {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: 9,
    background: "rgba(255, 255, 255, 0.25) !important",
  },

  ".hiddenField": {
    display: "none !important"
  },

  ".inlineMiddle": {
    display: "inline-block !important", 
    verticalAlign: "middle !important"
  },

  "a[href*='c/invoices'], a[href*='t/invoices']": {
    display: "none",
  },

  ".dangerouslyHTML": {
    padding: "20px",
    "& ul, & ol": {
      paddingLeft: "17px"
    },
    "& a": {
      color: "rgba(23, 193, 232, 1)",
      "&:hover": {
        color: "rgba(23, 193, 232, 0.7)",
      },
      "&.active": {
        color: "rgba(13, 15, 198, 1)",
        "&:hover": {
          color: "rgba(13, 15, 198, 0.8)",
        },
      },
    },
  },

  ".coueseBox": {
    width: "31%",
    margin: "1%",
    cursor: "pointer",
  },

  "@media (max-width: 768px)": {
    ".talentRight.talentInput": {
      width: "100%",
      margin: "10px 0",
      float: "none",

      "&>div": {
        float: "none",
      },
    },

    ".coueseBox": {
      width: '30rem', 
      maxWidth: "95%",
      margin: "15px auto",
    },

    ".levelUpBox": {
      maxWidth: "100% !important",
      margin: "0 !important",
    },

    ".lessonBox": {
      marginLeft: "0 !important",
      width: "100%"
    },
  },

  "@media (max-width: 1024px)": {
    ".aiContainer": {
      padding: "0 !important",
      "& .aiBox": {
        paddingTop: "0 !important",
        "& .aiDialog": {
          padding: "0",
        "& .aiRow": {
          "&.bot": {
          },
          "& .aiCell": {
            padding: "10px 10px 10px 35px",
          },
        },
      }
      }
    }
  }

};
