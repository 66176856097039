/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// react-routers components
import { useState } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Settings from "examples/Icons/Settings";
import { formatDate, validateToken } from "utils/commonFunctions"; // eslint-disable-line

function ProfilesList({ title, profiles, jobId }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function createData(name, message) {
    return { name, message };
  }

  const rows = [
    createData('Edap', 'Esta aplicacíon está incompleta, le falta xxx'),
    createData('Andrés', 'Ya pedí información extra al candidato para completarla'),
  ];

  const formatAvailability = availability => {
    switch (availability) {
      case '1-week':
        return '1 week'
      case '2-weeks':
        return '2 weeks'
      case '3-weeks':
        return '3 weeks'
      case '4-weeks':
        return '4 weeks'  
      default:
        return availability
    }
  }

  const getStringDeclineReason = reason => {
    switch (reason) {
      case '1':
        return "Employment duration too short"
      case '2':
        return "English skills not up to par"
      case '3':
        return "Location"
      case '4':
        return "Offer declined"
      case '5':
        return "Overqualified"
      case '6':
        return "Motivations misalignment"
      case '7':
        return "Underqualified"
      case '8':
        return "Unresponsive"
      case '9':
        return "Withdrew"
      case '10':
        return "Position closed"
      case '11':
        return "Better for another role"
      case '12':
        return "Culture Fit"
      case '13':
        return "Keep for future opportunities"
      case '14':
        return "Timing"
      default:
        return "Compensation misalignment"
    }
  }

  const isFreelanceJob = application => application?.job?.commitment?.name === "Freelance"

  const isDeclinedbyRecruiter = declinedBy => {
    if (declinedBy?.type === "tecla_recruiter") return true;
    if (declinedBy?.type === "tecla_manager") return true;
    if (declinedBy?.type === "tecla_admin") return true;

    return false
  }

  const renderProfiles = profiles.map(({ isRecruiter, applicationDate, salary, candidateSalary, availability, question1, answer1, question2, answer2, question3, answer3, question4, answer4, question5, answer5, question6, answer6, question7, answer7, question8, answer8, isLimited, application }) => (

    <SuiBox width="100%" className="application-box">
      {isRecruiter ? (
        <SuiBox width="100%">
          <SuiButton variant="outlined" color="info" size="large" onClick={handleOpen} fullWidth>
            <Settings color="info" />&nbsp;
            <SuiTypography variant="button">
              <Link to="/job/apply?id=:appId" color="info" sx={{ textDecoration: "none" }}>
                Edit
              </Link>
            </SuiTypography>
          </SuiButton>
        </SuiBox>
      ) : null}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SuiBox sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: "100vw", md: "80vw", xl: "60vw" },
          height: { xs: "101vh", md: "80vh" },
          bgcolor: 'background.paper',
          border: 'none',
          boxShadow: 24,
          borderRadius: { xs: 0, md: 4 },
          p: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center">
            <SuiBox display="flex" flexWrap="wrap">
              <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Notes about this application</SuiTypography>

            </SuiBox>
            <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
          </SuiBox>
          <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="100%" my={2}>
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:last-child th': { width: "4rem" }, '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" } }}
                    >
                      <TableCell component="th" scope="row" size="small">
                        <SuiTypography variant="body2" fontWeight="medium" color="dark">
                          {row.name}
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        <SuiTypography variant="body2" color="secondary">
                          {row.message}
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </SuiBox>
          <SuiBox display="inherit">
            <SuiInput type="text" placeholder="Leave a note..." />
            <SuiButton color="info" variant="gradient" sx={{ marginLeft: 1 }}>Send</SuiButton>
          </SuiBox>
        </SuiBox>
      </Modal>
                    
      {applicationDate && (
        <SuiBox key={applicationDate} alignItems="center" py={1} mb={1}>
          <SuiBadge badgeContent="Application date" color="secondary" variant="contained" circular="true" size="sm" container />

          <SuiBox width="100%" mt={1}>
            <SuiTypography variant="body2" color="secondary" ml={2}>
              {formatDate(applicationDate)}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      )}

      {!isLimited ? (
        <SuiBox key={salary} alignItems="center" pb={1}>
          <SuiBadge badgeContent="MONTHLY RATE" color="secondary" variant="contained" circular="true" size="xl" container />
          { candidateSalary && !salary ? (
            <SuiBadge badgeContent="NOT VALIDATED" color="error" variant="contained" circular="true" size="xl" container />
          ) : null }
          <SuiBox width="100%" mt={1}>
            {isFreelanceJob(application) ? 
              <>
                    { candidateSalary && !salary  ? (
                      <SuiTypography variant="body2" color="secondary" ml={2}>
                      Candidate wants: {!candidateSalary?.includes("$") && "$"} {candidateSalary} / Hour
                      </SuiTypography>
                    ) : null}

                    { candidateSalary && salary ? (
                      <SuiTypography variant="body2" color="secondary" ml={2}>
                      Candidate wants: {!candidateSalary?.includes("$") && "$"} {candidateSalary} - The client sees: ${salary} / Hour
                      </SuiTypography>
                    ) : null}

                    { !candidateSalary ? (
                      <SuiTypography variant="body2" color="secondary" ml={2}>
                        { salary !== "undefined" ? (<> {!salary?.includes("$") && "$"} {salary} / Hour </>) : "-" }
                      </SuiTypography>
                    ) : null}
              </>
            :
              <>
                { candidateSalary && !salary  ? (
                  <SuiTypography variant="body2" color="secondary" ml={2}>
                  Candidate wants: {!candidateSalary?.includes("$") && "$"} {candidateSalary} / month
                  </SuiTypography>
                ) : null}

                { candidateSalary && salary ? (
                  <SuiTypography variant="body2" color="secondary" ml={2}>
                  Candidate wants: {!candidateSalary?.includes("$") && "$"} {candidateSalary} - The client sees: ${salary} / month
                  </SuiTypography>
                ) : null}

                { !candidateSalary ? (
                  <SuiTypography variant="body2" color="secondary" ml={2}>
                    { salary !== "undefined" ? (<> {!salary?.includes("$") && "$"} {salary} / month </>) : "-" }
                  </SuiTypography>
                ) : null}
              </>
            }

          </SuiBox>
        </SuiBox>
      ) : null}

      <SuiBox key={availability} alignItems="center" py={1} mb={1}>
        <SuiBadge badgeContent="Available to start" color="secondary" variant="contained" circular="true" size="sm" container />
        <SuiBox width="100%" mt={1}>
          <SuiTypography variant="body2" color="secondary" ml={2}>
            {formatAvailability(availability)}
          </SuiTypography>
        </SuiBox>
      </SuiBox>

      { application && application.status === "declined" ? (
        <SuiBox key="declineReason" alignItems="center" py={1} mb={1}>
          <SuiBadge badgeContent={`${isDeclinedbyRecruiter(application?.declinedBy) ? "DECLINED BY RECRUITER" : "DECLINE REASON"}: ${getStringDeclineReason(application.declinedReason)}`} color="error" variant="contained" circular="true" size="sm" container />

          { application.declinedComment ? (
            <SuiBox width="100%" mt={1}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                Decline comments: {application.declinedComment}
              </SuiTypography>
            </SuiBox>
          ) : (
            <SuiBox width="100%" mt={1}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                No comments
              </SuiTypography>
            </SuiBox>
          ) }
        </SuiBox>
      ) : null }

      { application && application.status === "withdrew" ? (
        <SuiBox key="declineReason" alignItems="center" py={1} mb={1}>
          <SuiBadge badgeContent="Withdrawn" color="error" variant="contained" circular="true" size="sm" container />

          { application.withdrawReason ? (
            <SuiBox width="100%" mt={1}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                Withdraw comments: {application.withdrawReason}
              </SuiTypography>
            </SuiBox>
          ) : (
            <SuiBox width="100%" mt={1}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                No comments
              </SuiTypography>
            </SuiBox>
          ) }
        </SuiBox>
      ) : null }

      {
        question1 ? (
          <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Pre-Screening questions
          </SuiTypography>
        ) : null
      }

      {
        question1 ? (
          <SuiBox key={question1} alignItems="center" py={1}>
            <SuiBadge badgeContent={question1} color="secondary" variant="contained" circular="true" size="sm" container />

            <SuiBox mt={1}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                {answer1}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null
      }

      {
        question2 ? (
          <SuiBox key={question2} alignItems="center" py={1}>
            <SuiBadge badgeContent={question2} color="secondary" variant="contained" circular="true" size="sm" container />

            <SuiBox mt={1}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                {answer2}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null
      }

      {
        question3 ? (
          <SuiBox key={question3} alignItems="center" py={1}>
            <SuiBadge badgeContent={question3} color="secondary" variant="contained" circular="true" size="sm" container />

            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                {answer3}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null
      }

      {
        question4 ? (
          <SuiBox key={question4} alignItems="center" py={1}>
            <SuiBadge badgeContent={question4} color="secondary" variant="contained" circular="true" size="sm" container />

            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                {answer4}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null
      }

      {
        question5 ? (
          <SuiBox key={question5} alignItems="center" py={1}>
            <SuiBadge badgeContent={question5} color="secondary" variant="contained" circular="true" size="sm" container />

            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                {answer5}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null
      }

      {
        question6 ? (
          <SuiBox key={question6} alignItems="center" py={1}>
            <SuiBadge badgeContent={question6} color="secondary" variant="contained" circular="true" size="sm" container />

            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                {answer6}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null
      }

      {
        question7 ? (
          <SuiBox key={question7} alignItems="center" py={1}>
            <SuiBadge badgeContent={question7} color="secondary" variant="contained" circular="true" size="sm" container />

            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                {answer7}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null
      }

      {
        question8 ? (
          <SuiBox key={question8} alignItems="center" py={1}>
            <SuiBadge badgeContent={question8} color="secondary" variant="contained" circular="true" size="sm" container />

            <SuiBox mt={2}>
              <SuiTypography variant="body2" color="secondary" ml={2}>
                {answer8}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ) : null
      }

    </SuiBox >
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox pt={2} px={2}>
        <Link to={`/job?id=${jobId}`} target="_blank">
          <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SuiTypography>
        </Link>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox display="flex" flexDirection="row" flexWrap="wrap" p={0} m={0}>
          {renderProfiles}
        </SuiBox>
      </SuiBox>
    </Card >
  );
}

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  jobId: PropTypes.number.isRequired,
};

export default ProfilesList;
