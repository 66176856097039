/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react"
import { ReactSession } from 'react-client-session'
// react-router-dom components
import { Link } from "react-router-dom"
// formik components
import { Formik, Form } from "formik"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
// import SuiInput from "components/SuiInput"
import SignUp from "layouts/pages/users/new-user/components/SignUp"
import SuiAlert from "components/SuiAlert"
// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations"
import form from "layouts/pages/users/new-user/schemas/form"
import initialValues from "layouts/pages/users/new-user/schemas/initialValues"
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout"
// Images
import companyAndRecruitersLogin from "assets/images/companyAndRecruitersLogin.jpg"


function getContent(formData) {
  return <SignUp formData={formData} />;
}

function RecruiterSignUp() {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');

  ReactSession.setStoreType("localStorage");

  // Validation
  const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = false;
  let timerid = "";

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    // actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      // setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  const signUpClick = async () => {
    await sleep(100);
    if ($(".MuiTypography-caption:visible").text().length === 0) {
      const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/signupTecla/`;
      const lastEmail = $("input[name=email]").val();
      ReactSession.set("lastEmail", lastEmail);

      const postBody = {
        'email': $("input[name=email]").val(),
        'password': $("input[name=password]").val(),
        'password_confirmation': $("input[name=password]").val(),
        'first_name': $("input[name=firstName]").val(),
        'last_name': $("input[name=lastName]").val()
      };

      if (code) {
        postBody.code = code;
      }

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: new URLSearchParams(postBody)
      };

      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          $(".suiAlertMsg").remove();
          if (response.success) {
            $(".suiAlertMsg").remove();
            const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(successAlert);

            if (timerid) {
              clearTimeout(timerid);
            }

            timerid = setTimeout(() => {
              window.location.replace('/recruiterActivate');
            }, 2000);
          } else {
            $(".suiAlertMsg").remove();

            const errorAlert = $($('.alertSuccessBox .alertError').text(response.data.error).parent().html()).addClass("suiAlertMsg");
            $(".suiAlertBox").parent().prepend(errorAlert);

            if (timerid) {
              clearTimeout(timerid);
            }

            if (response.data.confirmed) {
              timerid = setTimeout(() => {
                window.location.replace(`/r/login`);
              }, 2000);
            }

            if (response.data.candidateExists && !response.data.confirmed && response.data.fromLever) {
              timerid = setTimeout(() => {
                window.location.replace(`/t/claim`);
              }, 2000);
            }
          }
        });
    } else if (!$("input[type='checkbox']").is(':checked')) {
      $(".suiAlertMsg").remove();
      $(".suiAlertMsg").remove();

      const errorAlert = $($('.alertSuccessBox .alertError').text("Agreement with Terms of Service is required").parent().html()).addClass("suiAlertMsg");
      $(".suiAlertBox").parent().prepend(errorAlert);
    }
  }


  return (
    <IllustrationLayout
      color="info"
      illustration={{
        image: companyAndRecruitersLogin,
      }}
    >
      <SuiBox className="suiAlertBox">
        <SuiBox display="flex" alignItems="end">
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, issubmitting }) => (
              <Form id={formId} autoComplete="off" type="POST" actions="#">
                <SuiBox>
                  {getContent({
                    values,
                    touched,
                    formField,
                    errors,
                  })}

                  <SuiBox mt={4} mb={1}>
                    <SuiButton type="submit" variant="contained" color="warning" size="large" issubmitting={issubmitting} onClick={signUpClick} fullWidth sx={{ backgroundColor: "#feec1e" }}>
                      <SuiTypography color="dark" fontWeight="bold">Sign up</SuiTypography>
                    </SuiButton>
                  </SuiBox>

                  <SuiBox opacity={0.25} my={4}>
                    <hr />
                  </SuiBox>

                  <SuiBox mt={3} textAlign="center">
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      Already have an account?&nbsp;
                      <SuiTypography
                        component={Link}
                        to="/r/login"
                        variant="button"
                        // color="info"
                        sx={{ color: "#097df7", fontSize: "18px" }}
                        fontWeight="bold"
                      >
                        Log in
                      </SuiTypography>
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </Form>
            )}
          </Formik>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertSuccess">Welcome to TECLA!</SuiAlert>
        </SuiBox>
        <SuiBox opacity={0} className="alertSuccessBox" display="none">
          <SuiAlert className="alertError" color="error">EMAIL IS ALREADY IN USE</SuiAlert>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  )
}

export default RecruiterSignUp
