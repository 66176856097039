/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
import { ReactSession } from 'react-client-session';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { stripHtml, capitalize } from 'utils/commonFunctions'
// Assets
import TeclaLogo from 'assets/images/logos/Tecla_Logo_bg.png'
// React Player
import ReactPlayer from 'react-player';
// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
// assets
import GitlabIcon from 'assets/images/socialIcons/Gitlab.png';
import PortfolioIcon from 'assets/images/socialIcons/Portfolio.png';
import stackOverflowIcon from 'assets/images/socialIcons/StackO.png';
import WebsiteIcon from 'assets/images/socialIcons/Website.png';
import placeholder from 'assets/images/placeholder.jpg'
import emptyCVImg from 'assets/images/illustrations/recommended.png';
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SuiAvatar from 'components/SuiAvatar';
// Soft UI Dashboard PRO React example components
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import SkillsetList from 'examples/Lists/SkillsetList';
import InterestList from 'examples/Lists/InterestList';
import ApplicationDetails from 'examples/Lists/ApplicationList';
import CompanyDashboardLayout from 'examples/LayoutContainers/CompanyDashboardLayout'
// Overview page components
import Header from 'layouts/pages/profile/components/Header';
import PdfViewer from 'layouts/pdfViewer/PdfViewer'
import ApplicationNoteModal from './applicationNoteModal';
import CompanyCandidateScorecardModal from "./companyCandidateScorecardModal/CompanyCandidateScorecardModal";


function Overview() {
  
  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  ReactSession.setStoreType("localStorage");
  let canShare = true;
  const token = ReactSession.get("token");

  const [application, setApplication] = useState({});
  const [recruiters, setRecruiters] = useState([]);
  const [notes, setNotes] = useState([]);
  const [updateNotes, setUpdateNotes] = useState(0);
  const [appCandidateId, setAppCandidateId] = useState(0);
  
  const [isFetchingFormattedCV, setIsFetchingFormattedCV] = useState(false);
  const [isFetchingApplicationData, setIsFetchingApplicationData] = useState(false);

  // Check if the application is being seen for the first time or not
  // If it's visited for the first time, display note modal and add it to localstorage
  const companyVisitedApplications = ReactSession.get("companyVisitedApplications")
  const [showApplicationNoteModal, setShowApplicationNoteModal] = useState(false)

  const [showCompanyCandidateScorecardModal, setShowCompanyCandidateScorecardModal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!companyVisitedApplications && application?.id) {
        if (application?.notes && stripHtml(application?.notes)) setShowApplicationNoteModal(true)
        ReactSession.set("companyVisitedApplications", [application?.id])
      }
  
      else if (application?.id && !companyVisitedApplications?.includes(application?.id)) { 
        if (application?.notes && stripHtml(application?.notes)) setShowApplicationNoteModal(true)
        const newArr = companyVisitedApplications
        newArr.push(application?.id)
  
        ReactSession.set("companyVisitedApplications", newArr)
      }
    }, 1000)
  }, [application])

  // eslint-disable-next-line
  let candidate = application.candidate;
  if (!candidate) {
    candidate = {};
  }
  const { appId } = useParams();
  if (appId) {
    canShare = false;
  }

  function createData(id, name, date, message, recruitersText, authorId, recruitersData) {
    return { id, name, date, message, recruitersText, authorId, recruitersData };
  }

  function getRecruiters() {
    const headers = { "Content-Type": "application/json" };
    const recruiterUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/getRecruiters/`;
    headers.Authorization = `Token ${token}`;
    fetch(recruiterUrl, { headers, })
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          const users = [];
          // eslint-disable-next-line
          for (const user of data.data) {
            users.push({
              id: user.id,
              display: user.uname,
              uname: `@${user.uname}`,
              checked: false
            });
          }

          setRecruiters(users);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function getStringDate(date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');
    const hour = String(date.getHours());
    const min = String(date.getMinutes());

    return `${mm}/${dd}/${yyyy} - ${hour}:${min}`;
  }

  function getNotes(userId) {
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const url = `${process.env.REACT_APP_API_ROUTE}/api/notes/getNotes/?candidate=${Number(userId)}`;
    headers.Authorization = `Token ${token}`;
    fetch(`${url}`, { headers, })
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          const tempDefaultNotes = [];
          let noteDate = "";
          let recruitersText = "";
          if (data.data && data.data.length) {
            // eslint-disable-next-line
            for (const note of data.data) {
              recruitersText = "";
              // eslint-disable-next-line
              for (const recruiter of note.recruiters) {
                recruitersText = `${recruitersText}${recruiter.user.first_name} ${recruiter.user.last_name}, `
              }
              if (recruitersText.length) {
                recruitersText = recruitersText.substring(0, recruitersText.length - 2);
              }

              noteDate = getStringDate(new Date(note.createdAt));
              tempDefaultNotes.push(createData(note.id, `${note.author.first_name} ${note.author.last_name}`, noteDate, note.content, recruitersText, note?.author?.id, note?.recruiters));
            }
          }
          $(".moonLoaderBox").removeClass("active");

          if (tempDefaultNotes.length) {
            setNotes(tempDefaultNotes);
            const searchParams = new URLSearchParams(window.location.search);
            const urlNotes = searchParams.get('notes');
            if (urlNotes && Number(urlNotes) === 1) {
              let timerid = "";
              if (timerid) {
                clearTimeout(timerid);
              }
              timerid = setTimeout(() => {
                $("button:contains('Notes')").click();
              }, 500);
            }
          }
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    if (appCandidateId) {
      getNotes(appCandidateId);
    }
  }, [updateNotes]);

  useEffect(() => {
    const headers = { "Content-Type": "application/json" };
    if (appId || token) {
      setIsFetchingApplicationData(true)

      const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${appId}/companyApplicationDetail/`;
      headers.Authorization = `Token ${token}`;
      fetch(url, { headers, })
        .then(async response => {
          const data = await response.json();
          if (data.success) {
            const newApplication = { ...data.data };
            const candidateId = newApplication.user.id;
            const newCandidate = { ...data.data.user.candidate };
            newApplication.user = {};
            newCandidate.user = data.data.user;
            if (appId) {
              newCandidate.isParameter = true;
            }
            if (data.data.cv) {
              newCandidate.cv = data.data.cv;
            } else if (data.data.user.candidate.cvCreated) {
              newCandidate.cv = data.data.user.candidate.cvCreated;
            }
            newApplication.candidate = newCandidate;
            const cfirstName = newCandidate.user ? newCandidate.user.first_name : "";
            const clastName = newCandidate.user ? newCandidate.user.last_name : "";
            document.title = `Application - ${cfirstName} ${clastName} | TECLA`;
            setApplication(newApplication);
            if (newApplication && candidateId && token) {
              getNotes(candidateId);
              setAppCandidateId(candidateId)
              getRecruiters();
            }
            setIsFetchingApplicationData(false)
          } else {
            window.location.replace("/");
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, []);

  const firstName = candidate.user ? candidate.user.first_name : "";
  const lastName = candidate.user ? candidate.user.last_name : "";
  const country = candidate.country ? candidate.country.name : "";
  const profileUser = {
    fullName: `${firstName} ${lastName}`,
    location: country,
    birthday: candidate.dateOfBirth,
    mobile: candidate.phone
  }

  const githubLink = candidate.github ? candidate.github : false;
  const gitLabLink = candidate.gitLab ? candidate.gitLab : false;
  const websiteLink = candidate.website ? candidate.website : false;
  const stackOverflowLink = candidate.stackOverflow ? candidate.stackOverflow : false;
  const portfolioLink = candidate.portfolio ? candidate.portfolio : false;
  const facebookLink = candidate.facebook ? candidate.facebook : false;
  const twitterLink = candidate.twitter ? candidate.twitter : false;
  const linkedinLink = candidate.linkedin ? candidate.linkedin : false;
  const instagramLink = candidate.instagram ? candidate.instagram : false;
  const videoLink = candidate.videoUrl ? candidate.videoUrl : false;

  let cv
  if (candidate.cv) {
    cv = `${process.env.REACT_APP_API_ROUTE}${candidate.cv}`;
  }

  function returAsYears(years) {
    if (years >= 10) {
      return `${years}+ Years`
    }

    if (years >= 2) {
      return `${years} Years`
    }

    if (years === 0) {
      return ""
    }

    return `${years} Year`;
  }

  function getSkillValue(skillValue) {
    if (skillValue >= 5) {
      return 100;
    }

    return skillValue * 20;
  }

  const skillsetListData = [];
  if (candidate.skills) {
    // eslint-disable-next-line
    for (let i = 0; i < candidate.skills.length; i++) {
      skillsetListData.push({
        image: placeholder,
        name: candidate.skills[i].skill.name,
        percent: returAsYears(candidate.skills[i].level),
        color: "info",
        value: getSkillValue(candidate.skills[i].level)
      });
    }
  }

  const englishsetListData = [];
  let comment = "Not able to speak English at all";
  if (candidate.englishLevel) {
    englishsetListData.push({
      image: placeholder,
      name: "Spoken english",
      percent: `${candidate.englishLevel}0%`,
      color: "info",
      value: candidate.englishLevel * 10,
      englishValidator: candidate.englishValidator,
    });
    if (candidate.englishLevel === 10) {
      comment = "English is like my first language";
    } else if (candidate.englishLevel === 9) {
      comment = "Excellent English; no issues at all";
    } else if (candidate.englishLevel === 8) {
      comment = "I can hold a conversation easily; minor mistakes";
    } else if (candidate.englishLevel === 7) {
      comment = "I can hold a conversation with a few mistakes";
    } else if (candidate.englishLevel === 6) {
      comment = "I can speak but with lots of mistakes";
    } else if (candidate.englishLevel === 5) {
      comment = "Currently learning; not able to speak yet";
    }
  }

  const interestListData = [];
  if (candidate.industries) {
    // eslint-disable-next-line
    for (let i = 0; i < candidate.industries.length; i++) {
      interestListData.push({
        image: placeholder,
        name: candidate.industries[i].name,
      });
    }
  }

  const appDetails = [
    {
      id: 1,
      salary: `${application.salaryToShow}`,
      availability: application.availability,
    },
  ];
  if (application.questions && application.questions.length) {
    // eslint-disable-next-line
    for (let i = 0; i < application.questions.length; i++) {
      if (application.questions[i].question.showToClient) {
        appDetails[0][`question${i + 1}`] = application.questions[i].question.title;
        appDetails[0][`answer${i + 1}`] = application.questions[i].answer;
      }
    }
  }

  const candidateLinks = [];
  if (githubLink) {
    candidateLinks.push({
      link: githubLink,
      icon: <GitHubIcon />,
      color: "github",
    });
  }
  if (gitLabLink) {
    candidateLinks.push({
      link: gitLabLink,
      icon: <img src={GitlabIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "pinterest",
    });
  }
  if (websiteLink) {
    candidateLinks.push({
      link: websiteLink,
      icon: <img src={WebsiteIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "vimeo",
    });
  }
  if (stackOverflowLink) {
    candidateLinks.push({
      link: stackOverflowLink,
      icon: <img src={stackOverflowIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "dribbble",
    });
  }
  if (portfolioLink) {
    candidateLinks.push({
      link: portfolioLink,
      icon: <img src={PortfolioIcon} alt="gitlab" className="css-eimhud-MuiSvgIcon-root profileIcon" />,
      color: "youtube",
    });
  }

  const candidateSocials = [];
  if (facebookLink) {
    candidateSocials.push({
      link: facebookLink,
      icon: <FacebookIcon />,
      color: "facebook",
    });
  }
  if (twitterLink) {
    candidateSocials.push({
      link: twitterLink,
      icon: <TwitterIcon />,
      color: "twitter",
    });
  }
  if (instagramLink) {
    candidateSocials.push({
      link: instagramLink,
      icon: <InstagramIcon />,
      color: "instagram",
    });
  }
  if (linkedinLink) {
    candidateSocials.push({
      link: linkedinLink,
      icon: <LinkedInIcon />,
      color: "linkedin",
    });
  }

  let title = "Application Details";
  if (application && application.company && application.job) {
    title = `${title} for ${application.company.name} - ${application.job.seniorityName}`
  }

  const fetchFormattedCV = () => {
    setIsFetchingFormattedCV(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${appId}/exportCV/`, {
      method: 'GET',
      headers: { 'Authorization': `Token ${token}` },
    })
    .then(async response => {
      const data = await response.json();

      setIsFetchingFormattedCV(false)

      if (data.success) {
        // window.open(`${process.env.REACT_APP_API_ROUTE}${data.data}`)
        const a = document.createElement('a')
        a.href = `${process.env.REACT_APP_API_ROUTE}${data.data}`
        a.download = `${process.env.REACT_APP_API_ROUTE}${data.data}`.split('/').pop()
        a.target = "_blank"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
      else {
        console.error(data)
        Swal.fire({ title: "Error", text: "Error downloading the CV, please try again later or contact our support if the problem persist.", icon: "error" });
      }
    })
    .catch(error => {
      Swal.fire({ title: "Error", text: "Error downloading the CV, please try again later or contact our support if the problem persist.", icon: "error" });
      setIsFetchingFormattedCV(false)
      console.error('There was an error!', error);
    });
  }

  return (
    <CompanyDashboardLayout>
      <Header
        candidate={candidate}
        canShare={canShare}
        canNote
        appId={appId}
        token={token}
        recruiters={recruiters}
        setRecruiters={setRecruiters}
        notes={notes}
        updateNotes={updateNotes}
        setUpdateNotes={setUpdateNotes}
        application={application}
        setShowCompanyCandidateScorecardModal={setShowCompanyCandidateScorecardModal}
      />
      
      {isFetchingApplicationData ?
        <SuiBox display="flex" justifyContent="center" alignItems="center" width="100%" height="70vh">
          <CircularProgress size={40}/>
        </SuiBox>
        :
        <SuiBox display="flex" mt={5} mb={3}>
          <Grid container spacing={3}>
            {/* LEFT */}
            <Grid item xs={12} lg={4}>

              {videoLink ? (
                <Grid item xs={12} mb={3}>
                  <Card>
                    <SuiBox pt={2} px={2}>
                      <SuiBox mb={0.5}>
                        <SuiTypography variant="h6" fontWeight="medium">
                          Intro Video
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mb={2}>
                        <ReactPlayer url={videoLink} width="100%" />
                      </SuiBox>
                    </SuiBox>
                  </Card>
                </Grid>
              ) : ("")}

              <Grid item xs={12}>
                <SuiBox mb={3}>
                  <ProfileInfoCard
                    applicationDate={application.createdAt}
                    title="profile"
                    description={candidate.bio}
                    info={profileUser}
                    links={candidateLinks}
                    social={candidateSocials}
                    action={{ route: "", tooltip: "Edit Profile" }}
                  />
                </SuiBox>
              </Grid>

              {skillsetListData.length ? (
                <Grid item xs={12}>
                  <SuiBox mb={3}>
                    <SkillsetList title="Skills" profiles={skillsetListData} />
                  </SuiBox>
                </Grid>
              ) : (null)}
              {englishsetListData.length ? (
                <Grid item xs={12}>
                  <SuiBox mb={3}>
                    <SkillsetList title="English level" profiles={englishsetListData} comment={comment} />
                  </SuiBox>
                </Grid>
              ) : (null)}
              {interestListData.length ? (
                <Grid item xs={12}>
                  <SuiBox mb={2}>
                    <InterestList title="Interests" profiles={interestListData} />
                  </SuiBox>
                </Grid>
              ) : (null)}

            </Grid>
            {/* RIGHT */}
            <Grid item xs={12} lg={8}>

              {/* APPLICATION NOTES */}
              {showApplicationNoteModal && (
                <ApplicationNoteModal
                  showApplicationNoteModal={showApplicationNoteModal}
                  setShowApplicationNoteModal={setShowApplicationNoteModal}
                  setShowCompanyCandidateScorecardModal={setShowCompanyCandidateScorecardModal}
                  application={application}
                />
              )}

              {/* CANDIDATE SCORECARD */}
              {showCompanyCandidateScorecardModal && (
                <CompanyCandidateScorecardModal
                  showCompanyCandidateScorecardModal={showCompanyCandidateScorecardModal}
                  setShowCompanyCandidateScorecardModal={setShowCompanyCandidateScorecardModal}
                  candidateName={`${capitalize(candidate?.user?.first_name)} ${capitalize(candidate?.user?.last_name)}`}
                  scorecardData={application?.scoreboard}
                />
              )}

              {application?.notes && stripHtml(application?.notes) && (
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <Card sx={{ p: 2, backgroundColor: '#fff' }}>
                    <SuiTypography variant="h6" fontWeight="medium">Application Notes</SuiTypography>

                    <SuiBox sx={{ display: "flex", alignItems: "center", paddingLeft: "20px", my: 1 }}>
                      <SuiAvatar
                        src={application?.notesRecruiter?.user?.photo ? `${process.env.REACT_APP_API_ROUTE}${application?.notesRecruiter?.user?.photo}` : TeclaLogo}
                        alt={application?.notesRecruiter?.user?.first_name ? `${capitalize(application?.notesRecruiter?.user?.first_name)} ${capitalize(application?.notesRecruiter?.user?.last_name)}` : "TECLA"}
                        sx={{ borderRadius: "50%", mr: 1 }} 
                        variant="rounded"
                        size="sm"
                        shadow="sm"
                      />

                      <SuiTypography variant="body2" color="secondary" sx={{ width: "130px" }}>
                        {application?.notesRecruiter?.user?.first_name ? `${capitalize(application?.notesRecruiter?.user?.first_name)} ${capitalize(application?.notesRecruiter?.user?.last_name)}` : "TECLA"}
                      </SuiTypography>
                    </SuiBox>

                    <SuiTypography variant="body2" color="secondary">
                      <div dangerouslySetInnerHTML={{ __html: application?.notes }} style={{ paddingTop: "5px", paddingLeft: "20px" }} />
                    </SuiTypography>
                  </Card>
                </Grid>
              )}
              {/* END APPLICATION NOTES */}

              <Card>
                  <Grid item xs={15} lg={15}>
                    <Card sx={{ overflowY: "auto", backgroundColor: cv ? '#525758' : '#fff' }}>
                      {cv ?
                        <PdfViewer cvUrl={cv} />
                        :
                        <SuiBox sx={{ minHeight: 400, py: 4 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                          <SuiTypography variant="h6" fontWeight="medium">The candidate hasn&apos;t uploaded a CV file</SuiTypography>
                          
                          <img src={emptyCVImg} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />
                        </SuiBox>
                      }
                    </Card>

                    {cv && (
                      isFetchingFormattedCV ?
                        <SuiBox mx="auto" my={3} display="flex" justifyContent="center" alignItems="center">
                          <CircularProgress />
                        </SuiBox>
                      :
                        <SuiBox mx="auto" my={3} display="flex" justifyContent="center" alignItems="center">
                          <SuiButton color="info" variant="gradient" onClick={() => fetchFormattedCV()}>Download</SuiButton>
                        </SuiBox>
                    )}
                  </Grid>
                </Card>

              {appDetails.length ? (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <SuiBox mb={2}>
                    <ApplicationDetails title={title} profiles={appDetails} />
                  </SuiBox>
                </Grid>
              ) : (null)}

            </Grid>
          </Grid>
        </SuiBox>
      }
    </CompanyDashboardLayout>
  );
}

export default Overview;
