/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session'
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Badge from '@mui/material/Badge';
// Soft UI Dashboard React components
import SuiAlert from "components/SuiAlert";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React icons
import companyAvatar from "assets/images/mockCompanyAvatarB.jpg";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import headerBackground from "assets/images/headerBackground.jpg";
import MagicLinkModal from "layouts/pages/profile/profile-company/components/magicLinkModal/MagicLinkModal";
import Notes from "layouts/pages/profile/components/CompanyHeader/Notes/notes";
import SalesModal from "layouts/pages/profile/components/CompanyHeader/SalesModal/SalesModal";
import CompanyStatsModal from "./companyStatsModal/companyStatsModal";

function Header({ company,
                  setCompany,
                  canShare,
                  magicLink,
                  canEdit,
                  companySalesData,
                  companyUpdated,
                  setCompanyUpdated,
                  salesNotes,
                  setSalesNotes,
                  saveSales,
                  loading,
                  setLoading,
                  isFetchingCompanyData,
                  getCompanyDataForRecruiterUser
                }) {

  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user")
  const recruiter = ReactSession.get("recruiter")
  const isRecruiter = (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin"));

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const [openSales, setOpenSales] = useState(false);
  const handleOpenSales = () => setOpenSales(true);

  const [openNotes, setOpenNotes] = useState(false);
  const handleOpenNotes = () => setOpenNotes(true);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const fullname = company.name;
  const imgProfile = company && company.photo ? `${process.env.REACT_APP_API_ROUTE}${company.photo}` : companyAvatar;
  const title = company.title ? company.title : "";

  function copyToClipboard(text) {
    const sampleTextarea = document.createElement("textarea");
    document.body.appendChild(sampleTextarea);
    sampleTextarea.value = text;
    sampleTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(sampleTextarea);
  }

  function copyTextClick(e) {
    e.preventDefault();
    const copyText = `${process.env.REACT_APP_PROD_ROUTE}t/profile/${company.id}`;
    copyToClipboard(copyText);
  }

  const [showMagicLinkModal, setshowMagicLinkModal] = useState(false)

  // ACÁ DEBE CAMBIAR A LOS DATOS DE LA COMPANIA?
  function copyMagicLink(e) {
    e.preventDefault()
    setshowMagicLinkModal(true)
  }

  // eslint-disable-next-line
  $(document).on("click", ".prevent", function (e) {
    e.preventDefault();
    $('.alertSuccessBox').show();
    let timerid = "";
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      $('.alertSuccessBox').hide();
    }, 2000);
  });

useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sales = searchParams.get('sales');
    if (sales && sales === "1") {
      handleOpenSales();
    }
  }, []);

  const [openCompanyStatsModal, setOpenCompanyStatsModal] = useState(false)

  const canManageUsers = companyId => {
    /* Admins can edit everything */
    if (user?.type === "tecla_admin") return true
    /* Managers can only edit companies they're assigned to */
    if (user?.type === "tecla_manager" && recruiter?.companies?.filter(comp => comp?.id === companyId).length) return true
    /* No one else can edit anything */
    return false
  }

  return (
    <SuiBox position="relative">
      <MagicLinkModal
        showMagicLinkModal={showMagicLinkModal}
        setshowMagicLinkModal={setshowMagicLinkModal}
        company={company}
        isFetchingCompanyData={isFetchingCompanyData}
        getCompanyDataForRecruiterUser={getCompanyDataForRecruiterUser}
      />

      <SuiBox className="alertSuccessBox" display="none">
        <SuiAlert color="info" display="none">Link copied to clipboard!</SuiAlert>
      </SuiBox>
      <SuiBox className="alertSuccessManagerBox" display="none">
        <SuiAlert color="info" display="none">Managers Updated!</SuiAlert>
      </SuiBox>
      <SuiBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="10rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${headerBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        {/* CANDIDATE INFORMATION BELOW */}
        {/* CANDIDATE INFORMATION BELOW */}
        {/* CANDIDATE INFORMATION BELOW */}
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SuiAvatar
              src={imgProfile}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <SuiBox height="90%" mt={0.5} lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="medium">
                {fullname}
              </SuiTypography>
              <SuiTypography variant="button" color="text" fontWeight="medium">
                {title}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={9} sx={{ ml: "auto" }}>
            <AppBar position="static">
              {!company.isParameter ? (
                <SuiBox display="flex" justifyContent="end" flexWrap="wrap">

                  {magicLink ? (
                    <>
                      <Badge
                        color="secondary"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { xs: 0, sm: 1 }}}
                        badgeContent={isRecruiter ? company?.jobsCount : null}
                        showZero
                        max={999}
                      >
                        <SuiButton component={Link} to={`/r/jobs/?company=${company.id}`} color="info" className="magic" sx={{ width: { xs: "100%", sm: "auto" } }}>
                          JOBS
                        </SuiButton>
                      </Badge>
                    </>
                  ) : null}

                  {magicLink ? (
                    <>
                      <SuiButton color="info" className="magic" onClick={handleOpenSales} sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { xs: 0, sm: 1 } }}>
                        Sales
                      </SuiButton>

                      <SalesModal openSales={openSales} setOpenSales={setOpenSales} company={company} companySalesData={companySalesData} companyUpdated={companyUpdated} setCompanyUpdated={setCompanyUpdated} salesNotes={salesNotes} setSalesNotes={setSalesNotes} saveSales={saveSales} loading={loading} setLoading={setLoading} />

                      <SuiButton color="info" className="magic" onClick={handleOpenNotes} sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { xs: 0, sm: 1 } }}>
                        Company notes
                      </SuiButton>

                      <Notes openNotes={openNotes} setOpenNotes={setOpenNotes} company={company} setCompany={setCompany} loading={loading} setLoading={setLoading} />
                    </>
                  ) : null}

                  {magicLink && canManageUsers(company.id) ? <SuiButton color="info" className="magic" onClick={e => copyMagicLink(e)} sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { xs: 0, sm: 1 } }}>
                    Manage users
                  </SuiButton> : null}

                  {/* {canEdit ? (
                    <SuiButton color="info" sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { xs: 0, sm: 1 } }}>
                      <Settings />&nbsp;
                      <SuiTypography variant="small" color="white" sx={{ color: "white!important", textDecoration: "none" }}>
                        <Link to={user.type === "tecla_manager" || user.type === "tecla_admin" ? "/r/companyWizard" : "/c/wizard"} style={{ textDecoration: 'none' }}>
                          EDIT PROFILE
                        </Link>
                      </SuiTypography>
                    </SuiButton>
                  ) : null} */}

                  {canEdit ? <SuiBox component={Link} to={user.type === "tecla_manager" || user.type === "tecla_admin" ? "/r/companyWizard" : "/c/wizard"} color="white" sx={{ width: { xs: "100%", sm: "auto" } }}>
                    <SuiButton color="info" sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { xs: 0, sm: 1 } }}>
                      Edit Profile
                    </SuiButton>
                  </SuiBox> : null}

                  {canShare ? <SuiButton variant="text" color="info" size="large" onClick={copyTextClick} sx={{ width: { xs: "100%", sm: "auto" }, marginRight: { xs: 0, sm: 1 } }}>
                    <a href="/" className="prevent">Share Profile</a>
                  </SuiButton> : null}

                  {isRecruiter && (
                    <>
                      <SuiButton color="info" py={25} onClick={() => setOpenCompanyStatsModal(true)} sx={{ width: { xs: "100%", sm: "auto" }, ml: 1 }}>
                        See stats
                      </SuiButton>

                      {openCompanyStatsModal && (
                        <CompanyStatsModal
                          openCompanyStatsModal={openCompanyStatsModal}
                          setOpenCompanyStatsModal={setOpenCompanyStatsModal}
                          company={company}
                        />
                      )}
                    </>
                  )}

                </SuiBox>
              ) : ("")}
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SuiBox >
  );
}

Header.defaultProps = {
  canShare: false,
  setCompany: null,
  canEdit: false,
  magicLink: false,
  companySalesData: {},
  companyUpdated: 0,
  setCompanyUpdated: null,
  setSalesNotes: null,
  salesNotes: "<br/><br/><br/><br/>",
  saveSales: null,
  loading: false,
  setLoading: null,
}

// typechecking props
Header.propTypes = {
  company: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setCompany: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  canShare: PropTypes.bool,
  canEdit: PropTypes.bool,
  magicLink: PropTypes.bool,
  companySalesData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  companyUpdated: PropTypes.number,
  setCompanyUpdated: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  setSalesNotes: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  salesNotes: PropTypes.string,
  saveSales: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  loading: PropTypes.bool,
  setLoading: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isFetchingCompanyData: PropTypes.bool.isRequired,
  getCompanyDataForRecruiterUser: PropTypes.func.isRequired,
};

export default Header;
