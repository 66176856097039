/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
import Swal from "sweetalert2";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
// @mui material components
import Grid from "@mui/material/Grid";
import SuiPagination from "components/SuiPagination";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Modal from '@mui/material/Modal';
import Checkbox from "@mui/material/Checkbox";
// Settings page components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
import DashboardNavbar from "examples/Navbars/JobFilterNavbar";
import RecruiterJobCard from "examples/Cards/RecruiterJobCard";
import JobFinder from "examples/JobFinder"
// Images
import placeholder from "assets/images/illustrations/recommended.png";
// Modals
import EnableAISourcingModal from 'layouts/dashboards/recruiter/enableAISourcingModal';
import DisableAISourcingModal from 'layouts/dashboards/recruiter/disableAISourcingModal';
import CompanyHiringManagerCard from 'layouts/pages/profile/profile-company/CompanyProfileInfoCards/companyHiringManagerCard';


function Settings() {
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const recruiter = ReactSession.get("recruiter");
  const user = ReactSession.get("user");

  ReactSession.set("aiSourcingSelectAll", false)  
  
  const [jobs, setJobs] = useState([]);
  const [skills, setSkills] = useState([]);
  const [skillsStart, setSkillsStart] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companiesStart, setCompaniesStart] = useState([]);
  const [pagination, setPagination] = useState({ pages: [] });

  useEffect(() => {
    document.title = `Jobs | TECLA`;
  }, []);

  const canEnableAISourcing = jobCompanyId => {
    /* Admins can edit everything */
    if (user.type === "tecla_admin") return true
    /* Managers can only edit companies they're assigned to */
    if (user.type === "tecla_manager" && recruiter.companies.filter(comp => comp?.id === jobCompanyId).length) return true
    /* No one else can edit anything */
    return false
  }

  // AutoSourcing modals
  const [showEnableAISourcingModal, setShowEnableAISourcingModal] = useState(false)
  const [showDisableAISourcingModal, setShowDisableAISourcingModal] = useState(false)
  const [showCompanyHiringManagerCard, setShowCompanyHiringManagerCard] = useState(false)
  
  const [jobIdOpen, setJobIdOpen] = useState(null) // Hack to identify the jobId to open the modal
  const [jobManagersOpen, setJobManagersOpen] = useState(null) // Hack to identify the job Recruiters to open the modal

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentJob, setCurrentJob] = useState(0);
  const [managers, setManagers] = useState([]);

  // const [jobNameFilter, setJobNameFilter] = useState("")
  const [inReviewStateFilter, setInReviewStateFilter] = useState(true)
  const [activeStateFilter, setActiveStateFilter] = useState(true)
  const [introStateFilter, setIntroStateFilter] = useState(false)
  const [interviewStateFilter, setInterviewStateFilter] = useState(false)
  const [pauseStateFilter, setPauseStateFilter] = useState(false)
  const [closedStateFilter, setClosedStateFilter] = useState(false)
  const [hotJobsFilter, setHotJobsFilter] = useState(false)

  const defaultFilters = {
    skills: [],
    skillString: "All",
    companies: [],
    categories: [],
    minSalary: 0,
    maxSalary: 0,
    page: 1,
    updateCompany: 0,
  };

  const searchParams = new URLSearchParams(window.location.search);
  const companyUrlId = searchParams.get('company');
  if (companyUrlId) {
    defaultFilters.companies.push(Number(companyUrlId));
  }
  const [filters, setFilters] = useState(defaultFilters);
  const [companiesString, setCompaniesString] = useState("All");

  const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [loading, setLoading] = useState(true);
  const [companyJobSelected, setCompanyJobSelected] = useState(true);

  const openHiringManager = (close, jobCompany) => {
    close()
    setCompanyJobSelected(jobCompany)
    setShowCompanyHiringManagerCard(true)
  }

  const openDeveloperFirstMenu = (event) => {
    setDeveloperFirstMenu(event.currentTarget);
    const targetId = ($(event.currentTarget).parents(".job-box").attr("data-id"));
    $(".customMenu > .MuiPaper-root").css("display", "none");
    $(".customMenu").css("z-index", "");
    $(`.menu-${targetId} > .MuiPaper-root`).css("display", "block");
    $(`.menu-${targetId}`).css("z-index", "1311");
  }
  const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  function buildMenu(state, close, status, reopened, jobId, jobCompany, jobRecruiters, hasAutoSourcingActive) {
    const menuItems = [];
    
    /* ONLY ADMINS AND MANGERS CAN SEE DROPDOWN */
    if (user && (user.type === "tecla_admin" || user.type === "tecla_manager")) {
      if (status === "in_review") {
        if (user.type === "tecla_admin" || (user.type === "tecla_manager" && recruiter.companies && recruiter.companies.filter(comp => Number(comp.id) === Number(jobCompany.company.id)).length)) {
          menuItems.push(<MenuItem className="menuActive" data-id={jobId} onClick={close}>Make active</MenuItem>);
        }
      }
      
      let recruiterString = "";
      if (jobRecruiters && jobRecruiters.length) {
        // eslint-disable-next-line
        for (const jobRecruiter of jobRecruiters) {
          recruiterString = `${recruiterString}${jobRecruiter.id},`;
        }
      }

      if (status !== "closed") {
        menuItems.push(<MenuItem className="menuClose" data-id={jobId} onClick={close}>Close</MenuItem>);
      }

      if (status === "closed" && !reopened) {
        menuItems.push(<MenuItem className="menuReopen" data-id={jobId} onClick={close}>Re-open</MenuItem>);
      }

      menuItems.push(<MenuItem className="menuEdit" data-id={jobId} onClick={close}>Edit</MenuItem>);
      menuItems.push(<MenuItem className="addSourcer" data-id={jobId} data-recruiters={recruiterString} onClick={close}>Assign Recruiters</MenuItem>);
    
      // AutoSourcing
      /* Admins can edit everything. Managers can only edit companies they're assigned to. */
      if (canEnableAISourcing(jobCompany.company.id) && status === "active") {
        if (hasAutoSourcingActive) {
          menuItems.push(<MenuItem className="disableAutoSourcing" onClick={() => {
            setShowDisableAISourcingModal(true)
            close()
          }}>Disable AI sourcing</MenuItem>)
        }
        else {
          menuItems.push(<MenuItem className="enableAutoSourcing" onClick={() => {
            setShowEnableAISourcingModal(true)
            close()
          }}>Enable AI sourcing</MenuItem>)
        }
      }

      if (user.type === "tecla_admin" || (user.type === "tecla_manager" && recruiter.companies && recruiter.companies.filter(comp => Number(comp.id) === Number(jobCompany.company.id)).length)) {
        menuItems.push(<MenuItem data-id={jobId} onClick={() => openHiringManager(close, jobCompany)}>Asssign Hiring Manager</MenuItem>);
      }
    }

    const menuName = `menu-${jobId} customMenu`;
    const renderMenu = (
      <Menu
        className={menuName}
        anchorEl={state}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(state)}
        onClose={close}
        keepMounted
      >
        {menuItems}
      </Menu>
    );

    return renderMenu;
  }

  function setPage(page) {
    const filtersCopy = { ...filters };
    filtersCopy.page = page;
    setFilters(filtersCopy);
  }

  const fetchJobsData = () => {
    setLoading(true)
    
    const url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/teclaJobsFind/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    let validGet = true;

    const reqFilters = { page: filters.page };

    if (filters.minSalary) {
      // eslint-disable-next-line
      if (!filters.maxSalary || Number(filters.maxSalary) > Number(filters.minSalary)) {
        reqFilters.minSalary = filters.minSalary;
      } else {
        validGet = false;
      }
    }

    if (filters.maxSalary) {
      // eslint-disable-next-line
      if (!filters.minSalary || Number(filters.maxSalary) > Number(filters.minSalary)) {
        reqFilters.maxSalary = filters.maxSalary;
      } else {
        validGet = false;
      }
    }

    let skillsFilter = "";
    if (filters.skills.length) {
      // eslint-disable-next-line
      for (const skill of filters.skills) {
        skillsFilter = `${skillsFilter}&skills=${skill}`;
      }
    }

    let companyFilter = "";
    if (filters.companies.length) {
      // eslint-disable-next-line
      for (const company of filters.companies) {
        companyFilter = `${companyFilter}&companies=${company}`;
      }
    }

    let categoryFilter = "";
    if (filters.categories.length) {
      // eslint-disable-next-line
      for (const category of filters.categories) {
        categoryFilter = `${categoryFilter}&categories=${category}`;
      }
    }

    if ($("[name=jobName]:last").val()) reqFilters.jobName = $("[name=jobName]:last").val()

    let stateFilter = "";
    if (inReviewStateFilter) stateFilter = `${stateFilter}&status=in_review`
    if (activeStateFilter) stateFilter = `${stateFilter}&status=active`
    if (pauseStateFilter) stateFilter = `${stateFilter}&status=pause`
    if (closedStateFilter) stateFilter = `${stateFilter}&status=closed`
    if (hotJobsFilter) stateFilter = `${stateFilter}&hot=1`

    const data = new URLSearchParams(reqFilters);

    if (validGet) {
      fetch(`${url}?${data}${skillsFilter}${companyFilter}${categoryFilter}${stateFilter}`, { headers, })
        .then(async response => {
          const res = await response.json();
          validateToken(res) // eslint-disable-line

          if (res.success) {
            const resJobs = [...res.data.jobs];
            const resPagination = { ...res.data.pagination };

            resPagination.hasPrev = true;
            resPagination.hasNext = true;
            if (resPagination.current === 1 || resPagination.countPages === 1) {
              resPagination.hasPrev = false;
            }

            if (resPagination.current === resPagination.countPages) {
              resPagination.hasNext = false;
            }

            if (resPagination.countPages >= 5) {
              if (resPagination.current === 1 || resPagination.current === 2) {
                resPagination.pages = [1, 2, 3, 4, 5];
              } else {
                resPagination.pages = [resPagination.current - 2, resPagination.current - 1, resPagination.current];
                if (resPagination.countPages >= resPagination.current + 1) {
                  resPagination.pages.push(resPagination.current + 1);
                }
                if (resPagination.countPages >= resPagination.current + 2) {
                  resPagination.pages.push(resPagination.current + 2);
                }
              }
            } else {
              resPagination.pages = [];
              // eslint-disable-next-line
              for (let i = 0; i < resPagination.countPages; i++) {
                resPagination.pages.push(i + 1);
              }
            }

            resPagination.pagesHtml = [];
            if (resPagination.pages.length > 1) {
              // eslint-disable-next-line
              for (let i = 0; i < resPagination.pages.length; i++) {
                if (resPagination.pages[i] === resPagination.current) {
                  resPagination.pagesHtml.push(<SuiPagination className="pageReload" item active> {resPagination.pages[i]} </SuiPagination>)
                } else {
                  resPagination.pagesHtml.push(<SuiPagination className="pageReload" item onClick={() => setPage(resPagination.pages[i])}> {resPagination.pages[i]} </SuiPagination>)
                }
              }
            }

            setPagination(resPagination);

            let jobSkills = {};
            let count = 0;
            // eslint-disable-next-line
            for (const job of resJobs) {
              jobSkills = {};
              count = 0;
              // eslint-disable-next-line
              for (const requiredSkill of job.requiredSkills) {
                count += 1;
                jobSkills[`skill${count}`] = requiredSkill.skill.name;
              }
              job.requiredSkills = jobSkills;
            }
            setJobs(resJobs);
          }

          setLoading(false)
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }

  function dialogResponse(dataJobId, options, url, textConfirm, isClose, isActive, isOpen, declineApplications, createCopy, selectedValue) {
    const data = {};
    let validPost = false;
    if (textConfirm && textConfirm.length) {
      // eslint-disable-next-line
      if ($("input[name=confirm]").val().toLowerCase() === textConfirm.toLowerCase()) {
        validPost = true;
      }
      if ($("textarea:visible").val()) {
        data.closeReason = $("textarea:visible").val();
      }
    } else if (isActive || isClose || isOpen) {
      validPost = true;
    }

    if (declineApplications || declineApplications === 0) {
      data.declineApplications = declineApplications;
    }

    if (createCopy || createCopy === 0) {
      data.createCopy = createCopy;
    }

    if (selectedValue && selectedValue !== "no_reason") {
      data.reportCloseReason = selectedValue;
    }

    if (validPost) {
      const postToken = `Token ${token}`;
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': postToken
        },
        body: JSON.stringify(data)
      };
      fetch(url, requestMetadata)
        .then(res => res.json())
        .then(response => {

          if (response.success) {
            const filtersCopy = { ...filters };
            filtersCopy.updateCompany += 1;
            setFilters(filtersCopy);
            if (textConfirm === "RE-OPEN") Swal.fire("This search has been re-opened.", "You can see it in your dashboard with IN-REVIEW status.", "success")
            if (isActive) Swal.fire("Job activated.", "", "success")
            fetchJobsData()
          }
        });
    }
  }

  const declineDialog = (dataJobId, options, url, textConfirm, isClose, isActive, isOpen) => Swal.fire(options).then((result) => {
    let declineApplications = null
    if (result.isConfirmed && isClose) {
      declineApplications = 1;
    } else if (result.isDenied && isClose) {
      declineApplications = 0;
    }

    let createCopy = null
    if (result.isConfirmed && isOpen) {
      createCopy = 1;
    } else if (result.isDenied && isOpen) {
      createCopy = 0;
    }

    if (result.isConfirmed || result.isDenied) {
      const selectedValue = $(".swal2-select").val();
      dialogResponse(dataJobId, options, url, textConfirm, isClose, isActive, isOpen, declineApplications, createCopy, selectedValue);
    }
  })

  useEffect(() => {
    if (user.type === "tecla_manager" || user.type === "tecla_admin") {
      const url = `${process.env.REACT_APP_API_ROUTE}/api/recruiters/getRecruiterRecruiters/`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      if (token) {
        headers.Authorization = `Token ${token}`;
      }
      fetch(url, { headers, })
        .then(async response => {
          const res = await response.json();
        validateToken(res) // eslint-disable-line
          if (res.success) {
            const newData = [];
            let fullName = "";
            // eslint-disable-next-line
            for (const manager of res.data) {
              fullName = `${manager.user.first_name} ${manager.user.last_name}`;
              newData.push({ value: manager.user.id, label: fullName, checked: false, recruiter: manager.user });
            }

            setManagers(newData);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, [])

  // eslint-disable-next-line
  $(document).unbind().on("click", ".menuPause, .menuUnpause, .menuReopen, .menuEdit, .menuActive, .menuClose, .addSourcer", function () {
    const dataJobId = $(this).attr("data-id");
    let jobRecruitersString = $(this).attr("data-recruiters");
    if (jobRecruitersString) {
      jobRecruitersString = jobRecruitersString.split(",");
    }
    if ($(this).hasClass("addSourcer")) {
      const jobManagers = [];
      const managersCopy = [...managers];
      // eslint-disable-next-line
      for (const managerCopy of managersCopy) {
        managerCopy.checked = false;
        // eslint-disable-next-line
        for (const jobRecruiterStringId of jobRecruitersString) {
          const recruiterId = Number(jobRecruiterStringId);
          if (recruiterId && recruiterId === managerCopy.value) {
            managerCopy.checked = true;
          }
        }
        jobManagers.push(managerCopy);
      }
      setCurrentJob(Number(dataJobId));
      setManagers(jobManagers);
      handleOpen();
    } else {
      // eslint-disable-next-line
      if ($(this).hasClass("menuEdit")) {
        window.location.replace(`/r/newjob?id=${dataJobId}`);
      } else {
        let title = "Are you sure to pause this search?"
        let text = "Applicants will not be able to see it on their searches.";
        const html = "";
        let url = `${process.env.REACT_APP_API_ROUTE}/api/companies/${dataJobId}/companyPauseJob/`;
        let isClose = false;
        let isOpen = false;
        let isActive = false;
        const textConfirm = "";
        let showCancelButton = false;
        let showDenyButton = false;
        let confirmButtonText = "Confirm";
        let denyButtonText = "";
        if ($(this).hasClass("menuUnpause")) {
          title = "Are you sure to resume this search?"
          text = "Applicants will be able to see it again on their searches.";
        } else if ($(this).hasClass("menuReopen")) {
          isOpen = 1;
          url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${dataJobId}/teclaReopenJob/`;
          title = null
          text = "Do you want to create a NEW copy of this job (without any applications) or just reactivate this same job? (If reactivated the job will have “In review” status).";
          confirmButtonText = "CREATE COPY";
          denyButtonText = "REACTIVATE";
          showCancelButton = true;
          showDenyButton = true;
        } else if ($(this).hasClass("menuClose")) {
          isClose = 1;
          confirmButtonText = "YES, DECLINE";
          showCancelButton = true;
          showDenyButton = true;
          denyButtonText = "NO, KEEP THEM";
          url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${dataJobId}/teclaCloseJob/`;
          title = null
          text = "This job will be closed and won't be visible for candidates. Do you want to also decline all the existing applications for this position?";
        } else if ($(this).hasClass("menuActive")) {
          isActive = true;
          url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${dataJobId}/teclaActivateJob/`;
          title = "Do you want to activate this job?"
          text = "It will be made public and candidates will be able to apply inmediately.";
        }

        const options = {
          title: title || null,
          text: text,
          width: "650",
          height: "auto",
          showCloseButton: true,
          confirmButtonText: confirmButtonText,
          confirmButtonAriaLabel: confirmButtonText,
          focusConfirm: true,
          showCancelButton: showCancelButton,
          cancelButtonText: "Cancel",
          showDenyButton: showDenyButton,
          denyButtonText: denyButtonText,
          allowEscapeKey: "true",
          customClass: {
            confirmButton: "btn btn-custom-size",
            cancelButton: "btn btn-custom-size",
          }
        }

        if (html.length) {
          options.html = html;
        }

        if ($(this).hasClass("menuClose")) {
          options.html = text;
          options.input = "select";
          options.inputOptions = {
            "no_reason": "No close reason selected",
            "client_hired_elsewhere": "Client hired elsewhere",
            "client_closed_search": "Client closed the search",
            "client_unresponsive": "Client unresponsive",
            "client_hired_tecla": "Client hired TECLA candidate",
            "tecla_closed_search": "TECLA closed search due to poor fit with client",
          }
          options.didOpen =  () => {
            $(".swal2-select").css("margin-top", "5px");
            $(".swal2-html-container").html("<span>This job will be closed and won't be visible for candidates.</span></br><br/><span>Select a reason for closing this position</span>")
            $('<div class="swal2-html-container">Do you want to also decline all the existing applications for this position?</div>').insertAfter(".swal2-select");
          }
        }

        declineDialog(dataJobId, options, url, textConfirm, isClose, isActive, isOpen);
      }
    }
  });


  useEffect(() => { fetchJobsData() }, [filters.page]);

  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / 1000;
  }

  function timeAgo(date) {
    const currentDate = new Date();
    const seconds = Math.floor(getDifferenceInSeconds(new Date(date), currentDate));
    const mins = Math.floor(seconds / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    let timePassed = "";
    if (months >= 1) {
      timePassed = `${months}M ago`;
    } else if (days >= 1) {
      timePassed = `${days}d ago`;
    } else if (hours >= 1) {
      timePassed = `${hours}h ago`;
    } else if (mins >= 1) {
      timePassed = `${mins}m ago`;
    } else if (seconds >= 1) {
      timePassed = `${seconds}s ago`;
    }

    return timePassed;
  }

  function setManagerCheck(managerCheckId) {
    const tempManagers = [...managers];
    const newManagers = [];
    // eslint-disable-next-line
    for (const manager of tempManagers) {
      if (Number(manager.value) === Number(managerCheckId)) {
        manager.checked = !manager.checked;
      }
      newManagers.push(manager);
    }

    setManagers(newManagers);
  }

  function saveManagers() {
    console.log(currentJob)
    if (managers && managers.length && currentJob) {
      const recruiters = [];
      const newJobRecruiters = [];
      // eslint-disable-next-line
      for (const manager of managers) {
        if (manager.checked && !recruiters.includes(manager.value)) {
          recruiters.push(manager.value);
          newJobRecruiters.push(manager.recruiter)
        }
      }

      const postBody = {
        jobId: Number(currentJob),
        recruiters: recruiters
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(postBody)
      };

      fetch(`${process.env.REACT_APP_API_ROUTE}/api/recruiters/recruiterJobRecruiters/`, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            const jobsCopy = [...jobs];
            // eslint-disable-next-line
            for (const jobCopy of jobsCopy) {
              if (jobCopy.id === currentJob) {
                jobCopy.recruiters = newJobRecruiters;
              }
            }
            setJobs(jobsCopy);
          }
        })
    }
    handleClose();
  }

  function updateFilters() {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/jobs/filters/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {
          setSkills(res.data.skills);
          setSkillsStart(res.data.skills);
          setCategories(res.data.categories);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  function updateCompanies() {
    const url = `${process.env.REACT_APP_API_ROUTE}/api/companies/teclaCompaniesAll/`;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    fetch(url, { headers, })
      .then(async response => {
        const res = await response.json();
        validateToken(res) // eslint-disable-line
        if (res.success) {
          let companyFound = null;
          const newData = [];
          // eslint-disable-next-line
          for (let i = 0; i < res.data.length; i++) {
            newData.push({ value: res.data[i].id, label: res.data[i].name });
            if (companyUrlId && Number(res.data[i].id) === Number(companyUrlId)) {
              companyFound = res.data[i].name;
            }
          }
          setCompanies(newData);
          setCompaniesStart(newData);
          if (companyFound) {
            setCompaniesString(companyFound);
          }
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  useEffect(() => {
    updateFilters();
    updateCompanies();
  }, []);

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  return (
    <RecruiterDashboardLayout>
      <DashboardNavbar title="Find your dream job" recruiter={recruiter} />

      <SuiBox>
        <Grid container spacing={3}>
          <JobFinder
            skills={skills}
            setSkills={setSkills}
            skillsStart={skillsStart}
            categories={categories}
            companies={companies}
            setCompanies={setCompanies}
            companiesStart={companiesStart}
            filters={filters}
            setFilters={setFilters}
            companiesString={companiesString}
            setCompaniesString={setCompaniesString}
            inReviewStateFilter={inReviewStateFilter}
            setInReviewStateFilter={setInReviewStateFilter}
            activeStateFilter={activeStateFilter}
            setActiveStateFilter={setActiveStateFilter}
            introStateFilter={introStateFilter}
            setIntroStateFilter={setIntroStateFilter}
            interviewStateFilter={interviewStateFilter}
            setInterviewStateFilter={setInterviewStateFilter}
            pauseStateFilter={pauseStateFilter}
            setPauseStateFilter={setPauseStateFilter}
            closedStateFilter={closedStateFilter}
            setClosedStateFilter={setClosedStateFilter}
            hotJobsFilter={hotJobsFilter}
            setHotJobsFilter={setHotJobsFilter}
            setPage={setPage}
            fetchJobsData={fetchJobsData}
          />
          {/* </Grid> */}
          <Grid item xs={12}>
            {/* ROW */}
            {/* eslint-disable-next-line */}
            {loading ? (
              <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={20}>
                <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
              </SuiBox>)
            :
            jobs.length ? (
              <>
                <Grid container xs={12} columnSpacing={{ xs: '0', md: '20' }} rowSpacing={{ xs: '20', md: '20' }}>
                  {jobs.map(job => (
                    <Grid item xs={12} md={6} className="job-box" data-id={job.id}>
                      <RecruiterJobCard
                        recruiter
                        job={job}
                        by={{ name: job.company.name, date: timeAgo(job.createdAt), logo: job.company.photo }}
                        badge={{ color: "success", label: job.status }}
                        title={job.seniorityName}
                        description={job.description}
                        skills={job.requiredSkills}
                        value={{ type: "$", from: job.minSalary, to: job.maxSalary, method: "Month" }}
                        action={{ type: "internal", route: `/job?id=${job.id}`, label: "Details" }}
                        category={job.category}
                        companyPhoto={job.company.photo}
                        subtitle={job.subtitle}
                        manage={{ type: "internal", route: `/r/manage?id=${job.id}`, label: "Applicants" }}
                        dateTime="16.01.22"
                        members={3}
                        companyId={job.company.id}
                        recruiters={job.recruiters}
                        managers={job.managers}
                        applicationsCounter={job.applicationsCounter}
                        dropdown={{
                          action: openDeveloperFirstMenu,
                          menu: buildMenu(developerFirstMenu, closeDeveloperFirstMenu, job.status, job.reopened, job.id, job, job.recruiters, job.autoSourcing, job.isHot),
                        }}
                        setJobIdOpen={setJobIdOpen}
                        setJobManagersOpen={setJobManagersOpen}
                        tags={job?.tags}
                      />
                    </Grid>
                  ))}
                </Grid>

                <SuiBox mt={6} mb={9} className="paginationBox" sx={{ "& > div": { margin: "20px auto 40px auto", display: "table" }, }} >
                  <SuiPagination>
                  {(pagination.count > 0 && pagination.hasPrev) && (
                      <SuiPagination item className="pageReloadPrev" onClick={() => setPage(filters.page - 1)}>
                        <Icon>keyboard_arrow_left</Icon>
                      </SuiPagination>
                    )}

                    {(pagination.count > 0) && pagination.pagesHtml}

                    {(pagination.count > 0 && pagination.hasNext) && (
                      <SuiPagination item className="pageReloadNext" onClick={() => setPage(filters.page + 1)}>
                        <Icon>keyboard_arrow_right</Icon>
                      </SuiPagination>
                    )}
                  </SuiPagination>
                </SuiBox>
              </>
            ) :
              <Grid item>
                {loading ? (
                  <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={20}>
                    <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                  </SuiBox>
                ) : (
                  <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
                    <img src={placeholder} alt="Couple of documents" width={isMobile ? "100%" : "35%"} />
                    <SuiTypography mb={4} variant="h5" textAlign="center" color="dark">No Jobs were found.</SuiTypography>

                    <SuiButton component="a" href="/r/jobs" sx={{ backgroundColor: "#feec1e" }} size="large">
                      <SuiTypography color="dark" fontWeight="bold">REFRESH PAGE</SuiTypography>
                    </SuiButton>
                  </SuiBox>
                )}
              </Grid>
            }
          </Grid>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <SuiBox sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: "100vw", md: "40vw", xl: "30vw" },
              height: { xs: "101vh", md: "fit-content" },
              maxHeight: "90%",
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: { xs: 0, md: 4 },
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                  <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Assign Sourcers</SuiTypography>
                </SuiBox>
                <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
              </SuiBox>
              <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2} sx={{ overflow: "auto" }}>
                {managers.map(manager => (
                  <SuiBox item xs={12} sm={12} style={{ margin: "5px 0", display: "block", width: "100%" }}>
                    <Checkbox data-id={manager.value} className="selectSkill" checked={manager.checked} onChange={() => setManagerCheck(manager.value)} />
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      sx={{ userSelect: "none" }}>
                      {manager.label}
                    </SuiTypography>
                  </SuiBox>
                ))}
              </SuiBox>
              <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                <SuiButton color="info" onClick={saveManagers} >Save</SuiButton>
              </SuiBox>
            </SuiBox>
          </Modal>
        </Grid>
      </SuiBox>

      {/* AutoSourcing modals */}
      {showEnableAISourcingModal && (
        <EnableAISourcingModal
          showEnableAISourcingModal={showEnableAISourcingModal}
          setShowEnableAISourcingModal={setShowEnableAISourcingModal}
          jobId={jobIdOpen}
          jobManagersOpen={jobManagersOpen}
        />
      )}

      {showDisableAISourcingModal && (
        <DisableAISourcingModal
          showDisableAISourcingModal={showDisableAISourcingModal}
          setShowDisableAISourcingModal={setShowDisableAISourcingModal}
          jobId={jobIdOpen}
          jobManagersOpen={jobManagersOpen}
          fetchJobsData={fetchJobsData}
        />
      )}

      {showCompanyHiringManagerCard && (
        <CompanyHiringManagerCard
          showCompanyHiringManagerCard={showCompanyHiringManagerCard}
          setShowCompanyHiringManagerCard={setShowCompanyHiringManagerCard}
          companyJobSelected={companyJobSelected}
          fetchJobsData={fetchJobsData}
          token={token}
        />
      )}

    </RecruiterDashboardLayout >
  );
}

export default Settings;
