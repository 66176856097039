/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef }from "react"
// @mui material components
import Grid from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery'
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiSelect from "components/SuiSelect"
import SuiInput from "components/SuiInput"
import SuiButton from "components/SuiButton"
import SuiTagInput from "components/SuiTagInput"
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField"
import Separator from "../../../CustomSeparator/index"

// eslint-disable-next-line
function Experience({ experienceId, experience, candidateJobExperiences, setCandidateJobExperiences, countries, monthOptions, yearOptions, firstEnter, setFirstEnter, skillOptions }) {

    const [currentTask, setCurrentTask] = useState(null)
    // const [currentAchievement, setCurrentAchievement] = useState(null)

    const [currentStartDateMonth, setCurrentStartDateMonth] = useState(null)
    const [currentStartDateYear, setCurrentStartDateYear] = useState(null)
    const [currentEndDateMonth, setCurrentEndDateMonth] = useState(null)
    const [currentEndDateYear, setCurrentEndDateYear] = useState(null)

    const currentEndDateYearInput = useRef()
    const currentEndDateMonthInput = useRef()

    const [workHereNow, setWorkHereNow] = useState(false)

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')
    /* Mui hook to read screen size - We use it for small screen rendering */
    const isSmallScreen = useMediaQuery('(max-width: 1201px)')

    // Option rules for date picker
    const startDateYearOptions = currentEndDateYear ? yearOptions.slice(yearOptions.findIndex(opt => opt.value === currentEndDateYear)) : yearOptions // eslint-disable-line
    const startDateMonthOptions = currentEndDateYear && currentEndDateMonth && currentStartDateYear && currentStartDateYear === currentEndDateYear ?
        monthOptions.slice(0, monthOptions.findIndex(opt => opt.value === currentEndDateMonth)) // eslint-disable-line
        :
        monthOptions

    const endDateYearOptions = currentStartDateYear ? yearOptions.slice(0, yearOptions.findIndex(opt => opt.value === parseInt(currentStartDateYear))+1) : yearOptions // eslint-disable-line
    const endDateMonthOptions = currentEndDateYear && currentStartDateMonth && currentStartDateYear && currentStartDateYear === currentEndDateYear ?
        monthOptions.slice(monthOptions.findIndex(opt => opt.value === currentStartDateMonth)+1) // eslint-disable-line
        :
        monthOptions

    /* Function that handles input changes and updates the candidateJobExperiences state.
    As parameters it takes the name of the field to change and the value to be assigned to the field. */
    const handleInputChange = (inputName, value) => {
        // Copy the current state
        const prevExperienceRecords = candidateJobExperiences.slice() // eslint-disable-line
        // Copy the record the user is editing
        const experienceRecord = prevExperienceRecords.filter(exp => exp.id === experienceId)[0]
        // Assign the new  value to the corresponding field
        if (inputName === "tasks" || inputName === "achievements") {
            experienceRecord[inputName].push(value)
            $(`input[name=${inputName}]`).val("")
            
            if (inputName === "tasks") setCurrentTask(null)
        }
        else experienceRecord[inputName] = value
        // Replace the state by including the record with the new value
        if (experienceId) {
            const tempCandidateJobExperiences = []
            for (const tempCandidateJobExperience of [...candidateJobExperiences]) { // eslint-disable-line
              if (tempCandidateJobExperience.id === experienceRecord.id) {
                tempCandidateJobExperiences.push(experienceRecord)
              } else {
                tempCandidateJobExperiences.push(tempCandidateJobExperience)
              }
            }

            setCandidateJobExperiences(tempCandidateJobExperiences)
        } else {
            setCandidateJobExperiences([
                experienceRecord,
                ...prevExperienceRecords.slice(1)
            ])
        }

        if (inputName === "tasks") {
            $("textarea[name=tasks]").val("")
            setCurrentTask("")
        }
    }

    /* Function for removing tasks and achievements from the experience state.
    As parameters it takes the name of the field (tasks or achievements) and the item you want to remove. */
    const handleInputRemove = (inputName, value) => {
        // Copy the current state
        const prevExperienceRecords = candidateJobExperiences.slice() // eslint-disable-line
        // Copy the record the user is editing
        const experienceRecord = prevExperienceRecords.filter(exp => exp.id === experienceId)[0]
        // Filter the tasks or achievements record array
        experienceRecord[inputName] = experienceRecord[inputName].filter(item => item !== value)
        // Replace the state by including the record with the new value
        if (experienceId) {
            const tempCandidateJobExperiences = []
            for (const tempCandidateJobExperience of [...candidateJobExperiences]) { // eslint-disable-line
              if (tempCandidateJobExperience.id === experienceRecord.id) {
                tempCandidateJobExperiences.push(experienceRecord)
              } else {
                tempCandidateJobExperiences.push(tempCandidateJobExperience)
              }
            }

            setCandidateJobExperiences(tempCandidateJobExperiences)
        } else {
            setCandidateJobExperiences([
                experienceRecord,
                ...prevExperienceRecords.slice(1)
            ])
        }
    }



    const removeExperience = () => {
        // Copy the current state
        const prevExperienceRecords = candidateJobExperiences.slice() // eslint-disable-line
        // Filter out the current experience
        const experienceRecords = prevExperienceRecords.filter(exp => exp.id !== experienceId)
        // Replace the state by the filtered array
        setCandidateJobExperiences(experienceRecords)
    }

    const handleWorkHereChange = () => {
        if (!workHereNow) {
            setWorkHereNow(true)
            handleInputChange("endDate", "Present")

            if (currentEndDateMonth) {
                setCurrentEndDateMonth(null)
                currentEndDateMonthInput.current.state.value = ""
            }
            if (currentEndDateYear) {
                setCurrentEndDateYear(null)
                currentEndDateYearInput.current.state.value = ""
            }
        }

        else setWorkHereNow(false)
    }

    const handleDateChange = (endOrStart, monthOrYear, value) => {
        if (endOrStart === "start") {
            if (monthOrYear === "month" && currentStartDateYear) handleInputChange("startDate", `${value} ${currentStartDateYear}`)
            if (monthOrYear === "year" && currentStartDateMonth) handleInputChange("startDate", `${currentStartDateMonth} ${value}`)
        }
        else if (endOrStart === "end") {
            if (monthOrYear === "month" && currentEndDateYear) handleInputChange("endDate", `${value} ${currentEndDateYear}`)
            if (monthOrYear === "year" && currentEndDateMonth) handleInputChange("endDate", `${currentEndDateMonth} ${value}`)
        }
    }

    const tasksPlaceholder = () => {
        if (experience.tasks.length === 0) return "Task... (required)" // eslint-disable-line
        if (experience.tasks.length === 12) return "Reached max ammount of tasks" // eslint-disable-line
        return "Task... (optional)" 
    }

    const [experienceSkills, setExperienceSkills] = useState([])

    function setExperiencieSkillsFunc(experienceSkillsValue) {
        const skillIds = []
        // eslint-disable-next-line
        for (const experienceSkillValue of experienceSkillsValue) {
            const optionObj = skillOptions.filter(skillOption => skillOption.value === experienceSkillValue)
            if (optionObj.length) {
                skillIds.push(optionObj[0].id)
            }
        }

        handleInputChange("skills", skillIds)
    }

    useEffect(() => {
        if (experience?.skills) {
            const dataSkills = [];
            // eslint-disable-next-line
            for (const tempSkill of experience?.skills) {
                dataSkills.push(tempSkill.name)
            }
            setExperienceSkills(dataSkills)
            setExperiencieSkillsFunc(dataSkills)
        }

        if (firstEnter && !workHereNow) {
            if (experience?.startDate) {
                const temptStartDateMonth = experience.startDate.substr(0, experience.startDate.indexOf(" "))
                setCurrentStartDateMonth(temptStartDateMonth)
                handleDateChange('start', 'month', temptStartDateMonth)

                const tempStartDateYear = experience.startDate.substr(experience.startDate.indexOf(" ") + 1, 20)
                setCurrentStartDateYear(tempStartDateYear)
                handleDateChange('start', 'year', tempStartDateYear)
            }

            if (experience?.endDate) {
                const temptEndDateMonth = experience.endDate.substr(0, experience.endDate.indexOf(" "))
                setCurrentEndDateMonth(temptEndDateMonth)
                handleDateChange('end', 'month', temptEndDateMonth)

                const tempEndDateYear = experience.endDate.substr(experience.endDate.indexOf(" ") + 1, 20)
                setCurrentEndDateMonth(tempEndDateYear)
                handleDateChange('end', 'year', tempEndDateYear)
            }

            

            if (experience?.endDate === "Present") {
                setWorkHereNow(true)
                // handleInputChange("endDate", "Present")
            }
        }

        if (firstEnter) {
            setFirstEnter(false)
        }

    }, []);


    // Skills Section
    // skillInput.current.state.value.id

    // const [optionValue, setOptionValue] = useState([])

    let skillLevelStrings = []
    let skillLevelString = ""

    // useEffect(() => {

    //     if (candidate && candidate.skills) {
    //         const candidateSkill = []
    //         const skillIds = []
    //         // eslint-disable-next-line
    //         $.each(candidate.skills, function (i, val) {
    //             candidateSkill.push(`${val.skill.name}`)
    //             skillIds.push(val.skill.id)
    //         })

    //         setExperienceSkillsIds(skillIds)

    //         if (candidateSkill.length) {
    //             setExperienceSkills(candidateSkill)
    //         }
    //     }

    //     fetchSkillOptions()
    // }, [])

    function notInArray(skillStr) {
        // eslint-disable-next-line
        for (const skillLevel of experienceSkills) {
            if (skillLevel.toLowerCase() === skillStr.toLowerCase()) return false
        }
        return true
    }

    function addSkill(selectOptionValue) {
        if (selectOptionValue && notInArray(selectOptionValue)) {
            skillLevelString = `${selectOptionValue}`
            skillLevelStrings = [...experienceSkills]
            skillLevelStrings.push(skillLevelString)
            setExperienceSkills(skillLevelStrings)
            setExperiencieSkillsFunc(skillLevelStrings)
        }
    }

    /* Removing skills */
    // console.log(experienceId)
    // eslint-disable-next-line
    // $(document).unbind().on("click", `.skillsBox${experienceId || 1} .react-tag-input__tag__remove`, function () {
    //     console.log("enter here", experienceId)
    //     const tagtext = $(this).prev().text()
    //     skillLevelStrings = [...experienceSkills]
    //     if (skillLevelStrings.length && skillLevelStrings.indexOf(tagtext) >= 0) {
    //         skillLevelStrings.splice(skillLevelStrings.indexOf(tagtext), 1)
    //         setExperienceSkills(skillLevelStrings)
    //         setExperiencieSkillsFunc(skillLevelStrings)
    //     }
    // })

    const removeTag = i => {
        setExperienceSkills(i)
        setExperiencieSkillsFunc(i)
    };

    // useEffect(() => {
        
    // }, [experienceSkills])

    return (
        <SuiBox>

            {experienceId !== 0 && <Separator/>}
            
            {candidateJobExperiences.length > 1 && // eslint-disable-line
                <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                    <SuiButton color="error" variant="outlined" onClick={() => removeExperience()}>Remove experience</SuiButton>
                </SuiBox>
            }

            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Company name</SuiTypography>
                            <FormField
                                type="text"
                                label="Company name"
                                name="Company name"
                                placeholder="Company name (required)"
                                defaultValue={experience?.company}
                                onChange={e => handleInputChange("company", e.target.value)}
                                success={experience.company} // eslint-disable-line
                            />
                        </Grid>

                        <SuiBox ml={2} xs={12} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent={isMobile ? "auto" : "space-between"} alignItems={isMobile ? "auto" : "center"} >
                            <Grid item xs={100} md={9.5}>
                                <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Position name</SuiTypography>
                                <FormField
                                    type="text"
                                    label="Position name"
                                    name="Position name"
                                    placeholder="Position name (required)"
                                    defaultValue={experience?.position}
                                    onChange={e => handleInputChange("position", e.target.value)}
                                    success={experience.position} // eslint-disable-line
                                />
                            </Grid>
                            
                            <SuiBox width={isMobile ? "100%" : "15%"} display={isMobile && "flex"} flexDirection={isMobile && "row"} justifyContent={isMobile && "center"} alignItems={isMobile && "center"} >
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked={experience?.endDate === "Present"} onChange={() => handleWorkHereChange()} />} label="I work here now" />
                                </FormGroup>
                            </SuiBox>

                        </SuiBox>

                        <SuiBox ml={2} xs={12} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" alignItems={isMobile ? "auto" : "center"} >
                            <Grid item xs={12} md={5.9}>
                                <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Start date</SuiTypography>
                                <SuiBox xs={12} md={6} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between">
                                    <Grid item xs={12} md={7} mb={isMobile && 2}>
                                        { experience?.startDate ? (
                                            <SuiSelect
                                                placeholder="Month (required)"
                                                label="Month"
                                                smallFont={isSmallScreen}
                                                size={isSmallScreen ? "small" : "medium"}
                                                options={startDateMonthOptions}
                                                name="Start date month (required)"
                                                defaultValue={{ value: experience.startDate.substr(0, experience.startDate.indexOf(" ")), label: experience.startDate.substr(0, experience.startDate.indexOf(" ")) }}
                                                success={currentStartDateMonth} // eslint-disable-line
                                                onChange={e => {
                                                    setCurrentStartDateMonth(e.value)
                                                    handleDateChange('start', 'month', e.value)
                                                }}
                                            />
                                        ) : (
                                            <SuiSelect
                                                placeholder="Month (required)"
                                                label="Month"
                                                smallFont={isSmallScreen}
                                                size={isSmallScreen ? "small" : "medium"}
                                                options={startDateMonthOptions}
                                                name="Start date month (required)"
                                                success={currentStartDateMonth} // eslint-disable-line
                                                onChange={e => {
                                                    setCurrentStartDateMonth(e.value)
                                                    handleDateChange('start', 'month', e.value)
                                                }}
                                            />
                                        ) }
                                    </Grid>
                                    <Grid item xs={12} md={4.5} mb={isMobile && 2}>
                                        { experience?.startDate ? (
                                            <SuiSelect
                                                placeholder="Year (required)"
                                                label="Year"
                                                smallFont={isSmallScreen}
                                                size={isSmallScreen ? "small" : "medium"}
                                                options={startDateYearOptions}
                                                name="Start date year (required)"
                                                defaultValue={{ value: experience.startDate.substr(experience.startDate.indexOf(" ") + 1, 20), label: experience.startDate.substr(experience.startDate.indexOf(" ") + 1, 20) }}
                                                success={currentStartDateYear} // eslint-disable-line
                                                onChange={e => {
                                                    setCurrentStartDateYear(e.value)
                                                    handleDateChange('start', 'year', e.value)
                                                }}
                                            />
                                        ) : (
                                            <SuiSelect
                                                placeholder="Year (required)"
                                                label="Year"
                                                smallFont={isSmallScreen}
                                                size={isSmallScreen ? "small" : "medium"}
                                                options={startDateYearOptions}
                                                name="Start date year (required)"
                                                success={currentStartDateYear} // eslint-disable-line
                                                onChange={e => {
                                                    setCurrentStartDateYear(e.value)
                                                    handleDateChange('start', 'year', e.value)
                                                }}
                                            />
                                        ) }
                                    </Grid>
                                </SuiBox>
                            </Grid>

                            <Grid item xs={12} md={5.9} display={workHereNow ? "none" : "block"}>
                                <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>End date</SuiTypography>
                                <SuiBox xs={12} md={6} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between">
                                    <Grid item xs={12} md={7} mb={isMobile && 2}>
                                        { experience?.endDate ? (
                                            <SuiSelect
                                                placeholder="Month"
                                                label="Month"
                                                smallFont={isSmallScreen}
                                                size={isSmallScreen ? "small" : "medium"}
                                                options={endDateMonthOptions}
                                                name="EndDateMonth"
                                                isDisabled={workHereNow}
                                                ref={currentEndDateMonthInput}
                                                defaultValue={{ value: experience.endDate.substr(0, experience.endDate.indexOf(" ")), label: experience.endDate.substr(0, experience.endDate.indexOf(" ")) }}
                                                success={currentEndDateMonth} // eslint-disable-line
                                                onChange={e => {
                                                    setCurrentEndDateMonth(e.value)
                                                    handleDateChange('end', 'month', e.value)
                                                }}
                                            />
                                        ) : (
                                            <SuiSelect
                                                placeholder="Month"
                                                label="Month"
                                                smallFont={isSmallScreen}
                                                size={isSmallScreen ? "small" : "medium"}
                                                options={endDateMonthOptions}
                                                name="EndDateMonth"
                                                isDisabled={workHereNow}
                                                ref={currentEndDateMonthInput}
                                                success={currentEndDateMonth} // eslint-disable-line
                                                onChange={e => {
                                                    setCurrentEndDateMonth(e.value)
                                                    handleDateChange('end', 'month', e.value)
                                                }}
                                            />
                                        ) }
                                        
                                    </Grid>
                                    <Grid item xs={12} md={4.5} mb={isMobile && 2}>
                                        { experience?.endDate ? (
                                            <SuiSelect
                                                placeholder="Year"
                                                label="Year"
                                                smallFont={isSmallScreen}
                                                size={isSmallScreen ? "small" : "medium"}
                                                options={endDateYearOptions}
                                                name="EndDateYear"
                                                isDisabled={workHereNow}
                                                ref={currentEndDateYearInput}
                                                defaultValue={{ value: experience.endDate.substr(experience.endDate.indexOf(" ") + 1, 20), label: experience.endDate.substr(experience.endDate.indexOf(" ") + 1, 20) }}
                                                success={currentEndDateYear} // eslint-disable-line
                                                onChange={e => {
                                                    setCurrentEndDateYear(e.value)
                                                    handleDateChange('end', 'year', e.value)
                                                }}
                                            />
                                        ) : (
                                            <SuiSelect
                                                placeholder="Year"
                                                label="Year"
                                                smallFont={isSmallScreen}
                                                size={isSmallScreen ? "small" : "medium"}
                                                options={endDateYearOptions}
                                                name="EndDateYear"
                                                isDisabled={workHereNow}
                                                ref={currentEndDateYearInput}
                                                success={currentEndDateYear} // eslint-disable-line
                                                onChange={e => {
                                                    setCurrentEndDateYear(e.value)
                                                    handleDateChange('end', 'year', e.value)
                                                }}
                                            />
                                        ) }
                                    </Grid>
                                </SuiBox>
                            </Grid>
                        </SuiBox>

                        <Grid item xs={12} md={6}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Company country</SuiTypography>
                            { experience?.country ? (
                                <SuiSelect
                                    placeholder="Country"
                                    label="Country"
                                    options={[{ value: "", label: "-" }, ...countries]}
                                    name="Company country (required)"
                                    defaultValue={{ value: experience.country, label: experience.country }}
                                    success={experience.country} // eslint-disable-line
                                    onChange={e => handleInputChange("country", e.value)}
                                />
                            ) : (
                                <SuiSelect
                                    placeholder="Country"
                                    label="Country"
                                    options={[{ value: "", label: "-" }, ...countries]}
                                    name="Company country (required)"
                                    success={experience.country} // eslint-disable-line
                                    onChange={e => handleInputChange("country", e.value)}
                                />
                            ) }
                        </Grid>
                        
                        <Grid item xs={12}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Main tasks and responsibilities</SuiTypography>

                            <Grid container xs={12} spacing={1} mb={1}>
                                <Grid item xs={9.5} md={10}>
                                    <SuiInput
                                        label="Task"
                                        name="tasks"
                                        placeholder={tasksPlaceholder()}
                                        success={experience.tasks.length} // eslint-disable-line
                                        onChange={e => setCurrentTask(e.target.value)}
                                        multiline 
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={2} >
                                    <SuiButton
                                        color="info"
                                        disabled={!currentTask || experience.tasks.length === 12} // eslint-disable-line
                                        onClick={() => handleInputChange("tasks", currentTask)}
                                        fullWidth>
                                            Add
                                    </SuiButton>
                                </Grid>
                            </Grid>

                            <Grid className="taskBox">
                                {experience?.tasks && experience.tasks.length >= 1 && experience.tasks.map((task, index) => ( // eslint-disable-line
                                    <Grid container xs={12} spacing={1} mb={1} key={task}>
                                        <Grid item xs={9.5} md={10}><SuiInput className="taskInput" defaultValue={task} multiline rows={3} /></Grid>
                                        <Grid item xs={2} >
                                            <SuiButton color="error" variant="outlined" onClick={() => handleInputRemove("tasks", task)} fullWidth>Remove</SuiButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={12} sx={{ marginBottom: 5 }}>
                            <SuiBox ml={1} mb={0.5}>
                                <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Relevant skills</SuiTypography>
                            </SuiBox>
                            <Grid container xs={12} md={12} spacing={1}>
                                <Grid item xs={12}>
                                    <SuiSelect
                                      placeholder="Skill"
                                      options={skillOptions}
                                      name={`experiencieSkill${experienceId || 1}`}
                                      onChange={e => addSkill(e.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} className={`skillsBox${experienceId || 1}`}>
                                    <SuiTagInput
                                      placeholder="Skills"
                                      tags={experienceSkills}
                                      onChange={removeTag}
                                      error={"Add at least one skill." && experienceSkills.length < 1}
                                      success={experienceSkills.length > 0}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </SuiBox>
  )
}

export default Experience