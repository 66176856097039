/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";


function DeleteSQModal({ showDeleteSQModal, setShowDeleteSQModal, sQToDelete, setSQToDelete, fetchScreeningQuestions }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const deleteScreeningQuestion = () => {
    setShowSpinner(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/notes/${sQToDelete}/removeScreeningQuestion/`, {
      method: 'POST',
      headers: { 'Authorization': `Token ${token}` }
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        setShowSpinner(false)
        setShowDoneIcon(true)
        fetchScreeningQuestions()

        setTimeout(() => {
          setShowDeleteSQModal(false)
          setShowDoneIcon(false)
        }, 1000)
      }
      else {
        setShowError(true)
        setShowSpinner(false)
        
        setTimeout(() => setShowError(false), 2000)
      }
    })
    .catch(error => {
      setShowError(true)
      console.error('There was an error!', error)
    })
  }

  return (
    <Modal open={showDeleteSQModal} onClose={() => {
      setShowDeleteSQModal(false)
      setSQToDelete(null)
    }}>
      <SuiBox sx={{
        width: "650px",
        height: "auto",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        borderRadius: { xs: 0, md: 4 },
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>

        {showDoneIcon && <SuiAlert color="info">Screening Question deleted!</SuiAlert>}
        {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

        <SuiTypography sx={{ my: 2.5 }} variant="h4">Delete Screening Question?</SuiTypography>
        <SuiTypography sx={{ my: 2.5 }} variant="body2">This will detele this Screening Question and it won&apos;t ve visible for anyone anymore.</SuiTypography>
        
        <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
          <SuiButton
            sx={{ mx: 1 }}
            fullWidth
            color="info"
            variant="outlined"
            disabled={showSpinner || showDoneIcon}
            onClick={() => {
              setShowDeleteSQModal(false)
              setSQToDelete(null)
            }}
          >
            Cancel
          </SuiButton>

          {/* eslint-disable-next-line */}
          {showSpinner ?
            <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
            :
            showDoneIcon ?
              <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
              :
              <SuiButton
                sx={{ mx: 1 }}
                fullWidth
                disabled={showSpinner || showDoneIcon}
                color="info"
                variant="gradient"
                onClick={() => deleteScreeningQuestion()}
              >
                Delete
              </SuiButton>
          }
        </SuiBox>
      </SuiBox>
    </Modal>
  )
}

// Typechecking props for the ProfilesList
DeleteSQModal.propTypes = {
  showDeleteSQModal: PropTypes.bool.isRequired,
  setShowDeleteSQModal: PropTypes.func.isRequired,
  sQToDelete: PropTypes.number.isRequired,
  setSQToDelete: PropTypes.func.isRequired,
  fetchScreeningQuestions: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
  

export default DeleteSQModal