/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
// @mui material components
import Modal from '@mui/material/Modal'
import Icon from '@mui/material/Icon'
import Checkbox from '@mui/material/Checkbox'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'

function CompanyHiringManagerCard({ showCompanyHiringManagerCard, setShowCompanyHiringManagerCard, companyJobSelected, fetchJobsData, token }) {

  const [hiringManagerUser, setHiringManagerUser] = useState(null)
  const [hirinUses, setHirinUses] = useState([])

  useEffect(() => {
    if (companyJobSelected && companyJobSelected.id) {
      fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${companyJobSelected.id}/teclaGetHiringManager/`, {
          method: 'GET',
          headers: { "Authorization": `Token ${token}` }
        })
        .then(async response => {
          const data = await response.json()
          if (data.success) {
            setHirinUses(data.data)
          }
        })
        .catch(error => console.error('There was an error!', error))

        if (companyJobSelected?.hiringManager) {
          setHiringManagerUser(companyJobSelected.hiringManager)
        }
    }
  }, [companyJobSelected]);
  
  const saveHiringManager = () => {
    if (hiringManagerUser) {
      const postBody = {
        userId: Number(hiringManagerUser.id)
      };
      const requestMetadata = {
        method: 'POST',
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "Authorization": `Token ${token}`
        },
        body: new URLSearchParams(postBody)
      };

      fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${companyJobSelected.id}/teclaJobSetHiringManager/`, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            setShowCompanyHiringManagerCard(false)
            fetchJobsData()
          }
        })
    }
  }

  const handleSetHiringManagerUser = (hiringUser) => {
    if (hiringUser && hiringUser.id !== hiringManagerUser?.id) {
      setHiringManagerUser(hiringUser)
    } else {
      setHiringManagerUser(null)
    }
  }

  return (<>
    {showCompanyHiringManagerCard && (
      <Modal
        open={showCompanyHiringManagerCard}
        onClose={() => setShowCompanyHiringManagerCard(false)}
      >
        <SuiBox
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100vw', md: '40vw', xl: '30vw' },
            height: { xs: '101vh', md: 'fit-content' },
            maxHeight: '90%',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 0, md: 4 },
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <SuiBox display='flex' justifyContent='space-between' alignItems='center'>
            <SuiBox display='flex' flexWrap='wrap' justifyContent='center' alignItems='center'>
              <SuiTypography variant='h6' ml={1.5} color='dark'>Select a Hiring Manager for this Role </SuiTypography>
            </SuiBox>

            <Icon onClick={() => setShowCompanyHiringManagerCard(false)} sx={{ cursor: 'pointer', borderRadius: 1, backgroundColor: { xs: '#21c0fd', md: '#fff' }, color: { xs: '#fff', md: '#344767' } }}>close</Icon>
          </SuiBox>

          <SuiBox borderRadius='16px' height='100%' my={2} p={2} sx={{ overflow: 'auto' }}>
            {hirinUses?.map(hiringUser => 
              <SuiBox key={hiringUser?.id} item xs={12} sm={12} style={{ margin: '5px 0', display: 'block', width: '100%' }}>
                <Checkbox data-id={hiringUser?.id} className='selectSkill' checked={hiringUser?.id === hiringManagerUser?.id} onChange={() => handleSetHiringManagerUser(hiringUser)} />
                <SuiTypography
                  variant='button'
                  fontWeight='regular'
                  sx={{ userSelect: 'none' }}>
                  {hiringUser?.first_name} {hiringUser?.last_name} 
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>

          <SuiBox ml={{ xs: 0, sm: 'auto' }} mt={{ xs: 2, sm: 0 }}>
            <SuiButton color='info' onClick={saveHiringManager}>Save</SuiButton>
          </SuiBox>
        </SuiBox>
      </Modal>
    )}
  </>)
}

// Typechecking props for the CompanyHiringManagerCard
CompanyHiringManagerCard.propTypes = {
  showCompanyHiringManagerCard: PropTypes.bool.isRequired, // eslint-disable-line
  setShowCompanyHiringManagerCard: PropTypes.func.isRequired,
  companyJobSelected: PropTypes.number.isRequired,
  fetchJobsData: PropTypes.func.isRequired,
}

export default CompanyHiringManagerCard
