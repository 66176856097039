/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types"
import { ReactSession } from 'react-client-session'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { sliceString, stripHtml } from "utils/commonFunctions"
// @mui material components
import Card from '@mui/material/Card'
import Modal from '@mui/material/Modal'
import Icon from '@mui/material/Icon'
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import useMediaQuery from '@mui/material/useMediaQuery'
import CircularProgress from '@mui/material/CircularProgress'
import Badge from '@mui/material/Badge'
import ErrorIcon from '@mui/icons-material/Error'
import Tooltip from '@mui/material/Tooltip'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupsIcon from '@mui/icons-material/Groups';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PublicIcon from '@mui/icons-material/Public';
import CodeIcon from '@mui/icons-material/Code';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ShareIcon from '@mui/icons-material/Share';
// Placeholder avatars
import mockCompanyAvatar from 'assets/images/mockCompanyAvatar.png'
// Industry images
import advertisingImg from 'assets/images/jobDetailPics/Advertising-Services.png'
import consumerImg from 'assets/images/jobDetailPics/Consumer-Services.png'
import cybersecurityImg from 'assets/images/jobDetailPics/Cybersecurity.png'
import dataImg from 'assets/images/jobDetailPics/Data-Services.png'
import healthImg from 'assets/images/jobDetailPics/Digital-Health.png'
import elearningImg from 'assets/images/jobDetailPics/E-Learning.png'
import entertainmentImg from 'assets/images/jobDetailPics/Entertainment.png'
import financialImg from 'assets/images/jobDetailPics/Financial-Services.png'
import healthcareImg from 'assets/images/jobDetailPics/Healthcare.png'
import hospitalityImg from 'assets/images/jobDetailPics/Hospitality.png'
import consultingImg from 'assets/images/jobDetailPics/IT-Services-and-IT-Consulting.png'
import managementImg from 'assets/images/jobDetailPics/Management-Consulting.png'
import mobileImg from 'assets/images/jobDetailPics/Mobile-Apps.png'
import realEstateImg from 'assets/images/jobDetailPics/Real-Estate.png'
import SoftwareDevelopmentImg from 'assets/images/jobDetailPics/Software-Development.png'
import technologyInformationImg from 'assets/images/jobDetailPics/Technology,-Information-and-Internet.png'
import transportationImg from 'assets/images/jobDetailPics/Transportation.png'
// Skill icons
import NET from 'assets/images/skillIcons/_NET.png'
import Agile from 'assets/images/skillIcons/Agile.png'
import Android from 'assets/images/skillIcons/Android.png'
import Angular from 'assets/images/skillIcons/Angular.png'
import AutomatedTesting from 'assets/images/skillIcons/Automated-Testing.png'
import AWS from 'assets/images/skillIcons/AWS.png'
import Azure from 'assets/images/skillIcons/Azure.png'
import Backend from 'assets/images/skillIcons/Backend.png'
import CSharp from 'assets/images/skillIcons/CSharp.png'
import CICD from 'assets/images/skillIcons/CICD.png'
import Cloud from 'assets/images/skillIcons/Cloud.png'
import CSS from 'assets/images/skillIcons/CSS.png'
import DataEngineering from 'assets/images/skillIcons/Data-Engineering.png'
import DataScience from 'assets/images/skillIcons/Data-Science.png'
import Data from 'assets/images/skillIcons/Data.png'
import DevOps from 'assets/images/skillIcons/DevOps.png'
import Django from 'assets/images/skillIcons/Django.png'
import Docker from 'assets/images/skillIcons/Docker.png'
import Flask from 'assets/images/skillIcons/Flask.png'
import Frontend from 'assets/images/skillIcons/Frontend.png'
import Fullstack from 'assets/images/skillIcons/Full-Stack.png'
import GCP from 'assets/images/skillIcons/GCP.png'
import generico from 'assets/images/skillIcons/genérico.png'
import GIT from 'assets/images/skillIcons/GIT.png'
import Golang from 'assets/images/skillIcons/Golang.png'
import GraphQL from 'assets/images/skillIcons/GraphQL.png'
import HTML from 'assets/images/skillIcons/HTML.png'
import iOS from 'assets/images/skillIcons/iOS.png'
import Java from 'assets/images/skillIcons/Java.png'
import JavaScript from 'assets/images/skillIcons/JavaScript.png'
import Jira from 'assets/images/skillIcons/JIRA.png'
import Kotlin from 'assets/images/skillIcons/Kotlin.png'
import Kubernetes from 'assets/images/skillIcons/Kubernetes.png'
import Laravel from 'assets/images/skillIcons/Laravel.png'
import Leadership from 'assets/images/skillIcons/Leadership.png'
import Linux from 'assets/images/skillIcons/Linux.png'
import MachineLearning from 'assets/images/skillIcons/Machine-Learning.png'
import ManualTesting from 'assets/images/skillIcons/Manual-Testing.png'
import Mobile from 'assets/images/skillIcons/Mobile.png'
import MongoDB from 'assets/images/skillIcons/MongoDB.png'
import MySQL from 'assets/images/skillIcons/MySQL.png'
import NextJS from 'assets/images/skillIcons/nextjs.png'
import PHP from 'assets/images/skillIcons/PHP.png'
import PostgreSQL from 'assets/images/skillIcons/PostgreSQL.png'
import Python from 'assets/images/skillIcons/Python.png'
import QA from 'assets/images/skillIcons/QA.png'
import ReactNative from 'assets/images/skillIcons/React-Native.png'
import REACT from 'assets/images/skillIcons/React.png'
import Redis from 'assets/images/skillIcons/Redis.png'
import Redux from 'assets/images/skillIcons/Redux.png'
import ROR from 'assets/images/skillIcons/Ruby-on-Rails.png'
import SalesForce from 'assets/images/skillIcons/Salesforce.png'
import SDLC from 'assets/images/skillIcons/SDLC.png'
import SQL from 'assets/images/skillIcons/SQL.png'
import Swift from 'assets/images/skillIcons/Swift.png'
import Terraform from 'assets/images/skillIcons/Terraform.png'
import TypeScript from 'assets/images/skillIcons/TypeScript.png'
import UI from 'assets/images/skillIcons/UI.png'
import UX from 'assets/images/skillIcons/UX.png'
import Vue from 'assets/images/skillIcons/Vue.png'
// Benefits Icons
import Equity from 'assets/images/benefitsIcons/Equity.png'
import FlexibleHours from 'assets/images/benefitsIcons/Flexible-Hours.png'
import HealthStipend from 'assets/images/benefitsIcons/Health-Stipend.png'
import LearningAndDevelopmentStipend from 'assets/images/benefitsIcons/Learning-and-Development-Stipend.png'
import LocalHolidaysOff from 'assets/images/benefitsIcons/Local-Holidays-Off.png'
import OfficeEquipmentStipend from 'assets/images/benefitsIcons/Office-Equipment-Stipend.png'
import PayrollBenefits from 'assets/images/benefitsIcons/Payroll-Benefits.png'
import PTO from 'assets/images/benefitsIcons/PTO.png'
import StockOptions from 'assets/images/benefitsIcons/Stock-Options.png'
import TeamRetreatsMeetups from 'assets/images/benefitsIcons/Team-Retreats-Meetups.png'
import UnlimitedPTO from 'assets/images/benefitsIcons/Unlimited-PTO.png'
// Modals
import SetHotJobModal from 'layouts/dashboards/recruiter/setHotJobModal'
import RemoveHotJobModal from 'layouts/dashboards/recruiter/removeHotJobModal'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiBadge from 'components/SuiBadge'
import SuiEditor from 'components/SuiEditor'
// Components
import Notes from "layouts/pages/profile/components/CompanyHeader/Notes/notes";
import WithAttachedFunction from './components/WithAttachedFunction'
import DeleteJobNoteModal from './DeleteJobNoteModal/DeleteJobNoteModal'
import DeleteSQModal from './DeleteSQModal/DeleteSQModal'
import JobFeedbackModal from './JobFeedbackModal/JobFeedbackModal'


// eslint-disable-next-line
function Job({ job, setJob, candidate, isGuest, code, isCompany, updatesCount, notes, setNotes, screeningQuestions, setScreeningQuestions, fetchScreeningQuestions, token, feedbackCount, setSuccessReport }) {
  ReactSession.setStoreType("localStorage")
  const recruiter = ReactSession.get("recruiter")
  const user = ReactSession.get("user")
  const [noteHtml, setNoteHtml] = useState("<br><br><br>");
  const [sQHtml, setSQHtml] = useState("<br><br><br>");

  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);

  useEffect(() => { 
    if (job && job.company && !company) {
      setCompany(job.company)
    }
  }, [job])

  useEffect(() => { 
    if (company && company?.companyNotes && company?.companyNotes !== job.company?.companyNotes) {
      const tempJob = { ...job }
      tempJob.company = { ...company }
      setJob(tempJob)
    }
  }, [company])

  const [isEditingNote, setIsEditingNote] = useState(false)
 
  const isRecruiter = (user && (user.type === "tecla_recruiter" || user.type === "tecla_manager" || user.type === "tecla_admin"));

  const [notesToDisplay, setNotesToDisplay] = useState(notes)
  useEffect(() => { setNotesToDisplay(notes) }, [notes])

  const getIndustryImage = industry => {
    switch (industry) {
      case "Advertising Services":
        return advertisingImg;
      case "Consumer Services":
        return consumerImg;
      case "Cybersecurity":
        return cybersecurityImg;
      case "Data Services":
        return dataImg;
      case "Digital Health":
        return healthImg;
      case "E-Learning":
        return elearningImg;
      case "Entertainment":
        return entertainmentImg;
      case "Financial Services":
        return financialImg;
      case "Healthcare":
        return healthcareImg;
      case "Hospitality":
        return hospitalityImg;
      case "IT Services and IT Consulting":
        return consultingImg;
      case "Management Consulting":
        return managementImg;
      case "Mobile Apps":
        return mobileImg;
      case "Real Estate":
        return realEstateImg;
      case "Technology, Information and Internet":
        return technologyInformationImg;
      case "Transportation":
        return transportationImg;
      default:
        return SoftwareDevelopmentImg;
    }
  }

  const getSkillIcon = skill => {
    switch (skill) {
      case ('NET'):
        return NET;
      case ('Agile'):
        return Agile;
      case ('Android'):
        return Android;
      case ('Angular'):
        return Angular;
      case ('Automated-Testing'):
        return AutomatedTesting;
      case ('AWS'):
        return AWS;
      case ('Azure'):
        return Azure;
      case ('Backend'):
        return Backend;
      case ('C#'):
        return CSharp;
      case ('CICD'):
        return CICD;
      case ('Cloud'):
        return Cloud;
      case ('CSS'):
        return CSS;
      case ('Data-Engineering'):
        return DataEngineering;
      case ('Data-Science'):
        return DataScience;
      case ('Data'):
        return Data;
      case ('DevOps'):
        return DevOps;
      case ('Django'):
        return Django;
      case ('Docker'):
        return Docker;
      case ('Flask'):
        return Flask;
      case ('Frontend'):
        return Frontend;
      case ('Full-Stack'):
        return Fullstack;
      case ('GCP'):
        return GCP;
      case ('GIT'):
        return GIT;
      case ('Golang'):
        return Golang;
      case ('GraphQL'):
        return GraphQL;
      case ('HTML'):
        return HTML;
      case ('iOS'):
        return iOS;
      case ('Java'):
        return Java;
      case ('JavaScript'):
        return JavaScript;
      case ('JIRA'):
        return Jira;
      case ('Kotlin'):
        return Kotlin;
      case ('Kubernetes'):
        return Kubernetes;
      case ('Laravel'):
        return Laravel;
      case ('Leadership'):
        return Leadership;
      case ('Linux'):
        return Linux;
      case ('Machine-Learning'):
        return MachineLearning;
      case ('Manual-Testing'):
        return ManualTesting;
      case ('Mobile'):
        return Mobile;
      case ('MongoDB'):
        return MongoDB;
      case ('MySQL'):
        return MySQL;
      case ('NextJS'):
        return NextJS;
      case ('PHP'):
        return PHP;
      case ('PostgreSQL'):
        return PostgreSQL;
      case ('Python'):
        return Python;
      case ('QA'):
        return QA;
      case ('React-Native'):
        return ReactNative;
      case ('React'):
        return REACT;
      case ('Redis'):
        return Redis;
      case ('Redux'):
        return Redux;
      case ('Ruby-on-Rails'):
        return ROR;
      case ('Salesforce'):
        return SalesForce;
      case ('SDLC'):
        return SDLC;
      case ('SQL'):
        return SQL;
      case ('Swift'):
        return Swift;
      case ('Terraform'):
        return Terraform;
      case ('TypeScript'):
        return TypeScript;
      case ('UI'):
        return UI;
      case ('UX'):
        return UX;
      case ('Vue'):
        return Vue;
      default:
        return generico;

    }
  }

  const getBenefitIcon = benefit => { // eslint-disable-line
    switch (benefit) {
      case ('Equity'):
        return Equity;
      case ('Flexible Hours'):
        return FlexibleHours;
      case ('Health Stipend'):
        return HealthStipend;
      case ('Learning and Development Stipend'):
        return LearningAndDevelopmentStipend;
      case ('Local Holidays Off'):
        return LocalHolidaysOff;
      case ('Office Equipment Stipend'):
        return OfficeEquipmentStipend;
      case ('Payroll Benefits'):
        return PayrollBenefits;
      case ('PTO'):
        return PTO;
      case ('Stock Options'):
        return StockOptions;
      case ('Team Retreats/Meetups'):
        return TeamRetreatsMeetups;
      case ('Unlimited PTO'):
        return UnlimitedPTO;
      default:
        return FlexibleHours;
    }
  }

  const formatLocation = location => {
    switch (location) {
      case "r":
        return "Remote";
      case "rr":
        return "Remote + Relocation";
      case "ir":
        return "Inmediate relocation";
      case "os":
        return "On-site";
      case "hy":
        return "Hybrid";
      default:
        return null;
    }
  }

  const formatCompanySize = size => {
    switch (size) {
      case "1-10":
        return "1 - 10";
      case "11-50":
        return "11 - 50";
      case "51-100":
        return "51 - 100";
      case "101-500":
        return "101 - 500";
      case "501-1000":
        return "501 - 1000";
      case "1000+":
        return "1000+";
      default:
        return null;
    }
  }

  const formatDeclineReason = declineCode => {
    switch (declineCode) {
      case "0":
        return "Compensation misalignment"
      case "1":
        return "Employment duration too short"
      case "2":
        return "English skills not up to par"
      case "3":
        return "Location"
      case "4":
        return "Offer declined"
      case "5":
        return "Overqualified"
      case "6":
        return "Motivations misalignment"
      case "7":
        return "Underqualified"
      case "8":
        return "Unresponsive"
      case "9":
        return "Withdrew"
      case "10":
        return "Position closed"
      case "11":
        return "Better for another role"
      case "12":
        return "Culture Fit"
      case "13":
        return "Keep for future opportunities"
      case "14":
        return "Timing"
      default:
        return ""
    }
  }

  const formatDeclineType = declineType => {
    switch (declineType) {
      case "company_owner":
        return "Client"
      case "recruiter":
        return "Client"
      case "limited_recruiter":
        return "Client"
      case "tecla_recruiter":
        return "Recruiter"
      case "tecla_manager":
        return "Recruiter"
      case "tecla_admin":
        return "Recruiter"
      default:
        return "Recruiter"
    }
  }

  // Hotjob modals
  const [showSetHotJobModal, setShowSetHotJobModal] = useState(false)
  const [ShowRemoveHotJobModal, SetShowRemoveHotJobModal] = useState(false)

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const handleOpen = () => setOpenNotesModal(true);
  const handleClose = () => {
    setIsEditingNote(false)
    setOpenNotesModal(false)
  };

  const [openScreeningModal, setOpenScreeningModal] = useState(false);
  const handleOpenScreening = () => setOpenScreeningModal(true);
  const handleCloseScreening = () => {
    setIsEditingNote(false)
    setOpenScreeningModal(false)
  };

  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const handleOpenDecline = () => setOpenDeclineModal(true);
  const handleCloseDecline = () => setOpenDeclineModal(false);

  let isAdmin = false;
  if (user && (user.type === "tecla_admin" || (job && job.company && user.type === "tecla_manager" && recruiter.companies && recruiter.companies.filter(comp => Number(comp.id) === Number(job.company.id)).length))) {
    isAdmin = true;
  }

  const title = job.seniorityName;
  // let industries = null;
  let candidateCode = code;
  if (!candidateCode) {
    candidateCode = candidate.code;
  }

  
  let desc = job.description;
  const salaryMin = job?.minSalary;
  const salaryMax = job?.maxSalary;
  let companyDesc = "";
  if (job && job.companyDescription) {
    companyDesc = job.companyDescription;
  }

  if (desc) {
    desc = desc.replaceAll('<a ', '<a target="_blank" ')
  }

  if (companyDesc) {
    companyDesc = companyDesc.replaceAll('<a ', '<a target="_blank" ')
  }

  function showMessage(text) {
    $('.alertSuccessBox div div').text(text);
    window.scrollTo(0, 0);
    $('.alertSuccessBox').show();
    let timerid = "";
    if (timerid) {
      clearTimeout(timerid);
    }
    timerid = setTimeout(() => {
      $('.alertSuccessBox').hide();
    }, 3000);
  }

  function copyToClipboard(text) {
    const sampleTextarea = document.createElement("textarea");
    document.body.appendChild(sampleTextarea);
    sampleTextarea.value = text;
    sampleTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(sampleTextarea);
  }

  function copyTextClick(e) {
    e.preventDefault();
    let copyText = `${process.env.REACT_APP_PROD_ROUTE}job?id=${job.id}`;
    if (job.hasCommission && candidateCode && !isRecruiter) {
      copyText = `${process.env.REACT_APP_PROD_ROUTE}job?id=${job.id}&code=${candidateCode}`;
    }
    if (recruiter && recruiter.applyTag) {
      copyText = `${copyText}&r=${recruiter.applyTag}`;
    }
    copyToClipboard(copyText);
    showMessage("Link copied to your clipboard!");
  }

  function makeActive() {
    const makeUrl = `${process.env.REACT_APP_API_ROUTE}/api/jobs/${job.id}/teclaActivateJob/`;
    const options = {
      title: "Do you want to activate this job?",
      text: "It will be made public and candidates will be able to apply immediately.",
      width: "600",
      height: "auto",
      showCloseButton: true,
      confirmButtonText: 'Confirm',
      confirmButtonAriaLabel: 'Confirm',
      focusConfirm: true,
      showCancelButton: false,
      allowEscapeKey: "true",
    }

    Swal.fire(options).then((result) => {
      if (result.isConfirmed) {
        const postToken = `Token ${token}`;
        const requestMetadata = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': postToken
          },
          body: JSON.stringify({})
        };
        fetch(makeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            if (response.success) {
              const jobCopy = { ...job };
              jobCopy.status = "active";
              setJob(jobCopy);
              Swal.fire("Job activated.", "", "success")
            }
          });
      }
    })

  }

  function isInFavorites() {
    let isFavorite = false;

    if (candidate && candidate.favoriteJobs) {
      // eslint-disable-next-line
      for (const favoriteJob of candidate.favoriteJobs) {
        if (job.id === favoriteJob.id) {
          isFavorite = true;
        }
      }
    }

    else if (recruiter && recruiter.favoriteJobs) {
      // eslint-disable-next-line
      for (const favoriteJob of recruiter.favoriteJobs) {
        if (job.id === favoriteJob.id) {
          isFavorite = true
          break
        }
      }
    }

    return isFavorite;
  }

  function numberFormat(number) {
    return number.toLocaleString('en');
  }

  function appliedAlert() {
    Swal.fire('You have already applied \n for this job');
  }

  // const date = timeAgo(job.createdAt);
  const searchParams = new URLSearchParams(window.location.search);
  const jobId = searchParams.get('id');
  const applyUrl = `/job/apply?id=${jobId}`;
  const editJobUrl = `/r/newjob?id=${jobId}`;

  function getStringDate(date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');
    const hour = String(date.getHours());
    const min = String(date.getMinutes());

    return `${mm}/${dd}/${yyyy} - ${hour}:${min}`;
  }

  function editNote(e) {
    setIsEditingNote(true)
    const thisNote = $(e.target);
    const htmlData = thisNote.attr("data-html");
    if (htmlData) {
      $(".tableEditCell.editing").removeClass("editing");
      thisNote.parent().addClass("editing");
      setNoteHtml($(`<div>${htmlData}<div/>`).html());
      $(".ql-editor").html($(`<div>${htmlData}<div/>`).html());
    }
  }

  function editSQ(e) {
    setIsEditingNote(true)
    const thisNote = $(e.target);
    const htmlData = thisNote.attr("data-html");
    if (htmlData) {
      $(".tableEditCell.editing").removeClass("editing");
      thisNote.parent().addClass("editing");
      setSQHtml($(`<div>${htmlData}<div/>`).html());
      $(".ql-editor").html($(`<div>${htmlData}<div/>`).html());
    }
  }

  function openAppLink(appId) {
    window.open(`/r/application/${appId}`, '_blank');
  }

  const [showNotesLoader, setShowNotesLoader] = useState(false)

  function createNote() {
    setShowNotesLoader(true)

    const noteText = noteHtml;
    let noteId = 0;
    if (noteText) {
      const postBody = { 
        'job': Number(job.id),
        'content': noteText
      };
      const requestMetadata = {
        method: 'POST',
        headers: {
          "content-type": "application/json",
          "Authorization": `Token ${token}`
        },
        body: JSON.stringify(postBody)
      };
      let recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/notes/createJobNote/`;
      if ($(".tableEditCell.editing").length) {
        noteId = Number($(".tableEditCell.editing svg").attr("data-id"));
        recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/notes/${noteId}/editJobNote/`;
      }
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            const tempNotesRows = [...notes];

            if (isEditingNote) {
              // eslint-disable-next-line
              for (const note of tempNotesRows) {
                if (note.id === noteId) {
                  note.message = noteText;
                  note.text = noteText;
                }
              }
              $(".tableEditCell.editing").removeClass("editing");
              setIsEditingNote(false)

            } else {
              const noteDate = getStringDate(new Date(new Date()));
              tempNotesRows.push({
                name: `${user.first_name} ${user.last_name}`, 
                date: noteDate, 
                message: noteText, 
                text: noteText,
                id: response.data,
              });
            }

            setNotes(tempNotesRows);
            $(".ql-editor").html("<br><br><br>");
            setNoteHtml("<br><br><br>");

            setShowNotesLoader(false)
          }
        });
    }
  }

  function createScreeningQuestion() {
    setShowNotesLoader(true)
    let noteId = 0;
    if (sQHtml) {
      const postBody = { 
        'job': Number(job.id),
        'content': sQHtml
      };
      const requestMetadata = {
        method: 'POST',
        headers: {
          "content-type": "application/json",
          "Authorization": `Token ${token}`
        },
        body: JSON.stringify(postBody)
      };
      let recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/notes/createScreeningQuestion/`;
      if ($(".tableEditCell.editing").length) {
        noteId = Number($(".tableEditCell.editing svg").attr("data-id"));
        recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/notes/${noteId}/editScreeningQuestion/`;
      }
      fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            fetchScreeningQuestions();
            $(".ql-editor").html("<br><br><br>");
            setSQHtml("<br><br><br>");

            setShowNotesLoader(false)
          }
        });
    }
  }

  // eslint-disable-next-line
  const placeHolderAvatar = () => {
    if (job.company) {
      if (job?.company?.photo) return process.env.REACT_APP_API_ROUTE + job.company.photo
      return mockCompanyAvatar
    }
  }

  /* Mui hook to read screen size - We use it for mobile rendering */
  const isMobile = useMediaQuery('(max-width:500px)')

  const formatHiringMethod = hm => {
    if (hm === "dh") return "Direct Hire"
    if (hm === "dhp") return "Direct Hire - Payroll"
    if (hm === "dhf") return "Direct Hire"
    if (hm === "saf") return "Staff Augmentation"
    return "Staff Augmentation" 
  }

  const [showDeleteJobNoteModal, setShowDeleteJobNoteModal] = useState(false)
  const [showDeleteSQModal, setShowDeleteSQModal] = useState(false)
  
  const canEditAndDeleteNote = (noteAuthorId, jobCompanyId) => {
    if (user.type === "tecla_admin") return true
    if (user.type === "tecla_manager" && recruiter.companies.filter(comp => comp?.id === jobCompanyId).length) return true
    if (noteAuthorId === user.id) return true
    if (user.type === "tecla_admin" || user.type === "tecla_manager" || user.type === "tecla_recruiter") return true
    return false
  }

  const canScreeningQuestions = () => {
    if (user.type === "tecla_admin" || user.type === "tecla_manager" || user.type === "tecla_recruiter") return true
    return false
  }
  
  const [noteIdToDelete, setNoteIdToDelete] = useState(null)
  const [sQToDelete, setSQToDelete] = useState(null)
  
  useEffect(() => { if (noteIdToDelete) setShowDeleteJobNoteModal(true) }, [noteIdToDelete])
  useEffect(() => { if (sQToDelete) setShowDeleteSQModal(true) }, [sQToDelete])

  const renderCountries = () => {
  
    // Si tiene solo un país  mostrar  “Open to: Country1 only”
    if (job?.countries?.length === 1) {
      return (
        <SuiTypography
          variant="button"
          ml={.5}
          color={job?.excludedCountries?.length > 16 && !job?.countries?.length ? "error" : "auto"}
          display="flex"
          alignItems="center"
        >
         {job?.countries[0].name} only
        </SuiTypography>
      )
    }

    // Si tiene entre [2 - 16] países mostrar  “Open to: Country1, Country2, and CountryX only”
    if (job?.countries?.length > 1 && job?.countries?.length < 17) {

      let str = ''

      job?.countries?.forEach(country => {
        if (job?.countries?.indexOf(country) === job?.countries?.length -1) str += `and ${country.name} `
        else if (job?.countries?.indexOf(country) === 0) str += `${country.name}, `
        else if (job?.countries?.indexOf(country) === job?.countries?.length - 2) str += ` ${country.name} `
        else str += ` ${country.name}, `
      })

      return (
        <SuiTypography
          variant="button"
          ml={.5}
          color={job?.excludedCountries?.length > 16 && !job?.countries?.length ? "error" : "auto"}
          display="flex"
          alignItems="center"
        >
          {str}
        </SuiTypography>
      )
    }

    // Si tiene todos los países, mostrar All countries in LATAM and United States
    if (!job?.excludedCountries?.length) return (
      <SuiTypography
        variant="button"
        ml={.5}
      >
        All countries in LATAM and United States
      </SuiTypography>
    )
    
    // Si tiene todos los países menos EEUU, mostrar “All countries in LATAM”
    if (job?.excludedCountries?.length === 1 && job?.excludedCountries[0]?.name === 'United States') return (
      <SuiTypography
        variant="button"
        ml={.5}
        display="flex"
        alignItems="center"
      >
        All countries in LATAM
      </SuiTypography>
    )

    // Si tiene todos los países menos uno, mostrar “All countries in LATAM except for Country1”
    if (job?.excludedCountries?.length === 1) return (
      <SuiTypography
        variant="button"
        ml={.5}
        color={job?.excludedCountries?.length > 16 && !job?.countries?.length ? "error" : "auto"}
        display="flex"
        alignItems="center"
      >
        All countries in LATAM except for {job?.excludedCountries[0].name}
      </SuiTypography>
    )

    // Si tiene todos los países menos [2 - 10] , mostrar “All countries in LATAM except for Country1 , Country2, and CountryX”
    if (job?.excludedCountries?.length > 1 && job?.excludedCountries?.length < 11) {

      let str = ''

      job?.excludedCountries?.forEach(country => {
        if (job?.excludedCountries?.indexOf(country) === job?.excludedCountries?.length -1) str += `and ${country.name} `
        else if (job?.excludedCountries?.indexOf(country) === 0) str += `${country.name}, `
        else if (job?.excludedCountries?.indexOf(country) === job?.excludedCountries?.length - 2) str += ` ${country.name} `
        else str += ` ${country.name}, `
      })

      return (
        <SuiTypography
          variant="button"
          ml={.5}
          color={job?.excludedCountries?.length > 16 && !job?.countries?.length ? "error" : "auto"}
          display="flex"
          alignItems="center"
        >
          All countries in LATAM except for {str}
        </SuiTypography>
      )
    }

    if (job?.excludedCountries?.length > 16 && !job?.countries?.length) return "No countries assigned"

    return null
  }
  
  const renderOptionalSkills = optSkills => {
    let str = ''

    optSkills.forEach(skill => {
      if (optSkills.length === 1) str += `${skill.skill.name}`
      else if (optSkills.indexOf(skill) === optSkills.length -1) str += ` and ${skill.skill.name} `
      else if (optSkills.indexOf(skill) === 0) str += `${skill.skill.name}`
      else str += `, ${skill.skill.name}`
    })

    return (
      <SuiTypography
        variant="button"
        ml={1}
        display="flex"
        alignItems="center"
      >
        {str}
      </SuiTypography>
    )
  }

  const getActivityColor = () => {
    if (job.status === "active") return "#00FF7F"
    if (job.status === "closed") return "red"
    return "gray"
  }

  const [showJobFeedbackModal, setShowJobFeedbackModal] = useState(false)

  const returnHMTooltipContent = () => {
    if (job?.hiringMethod === "dh") return "This is an opportunity to be hired directly by a U.S./Canada company as a contractor."
    if (job?.hiringMethod === "sa") return "This is an opportunity to be hired by TECLA to work with a U.S/Canada company."
    if (job?.hiringMethod === "dhf") return "This is a remote opportunity to be hired directly by a U.S./Canada company as a contractor."
    if (job?.hiringMethod === "saf") return "This is a remote opportunity to be hired by TECLA to work for a U.S./Canada company."
    return "This is an opportunity to be hired directly by a U.S./Canada company as an employee in a LatAm entity, with payroll benefits."    
  }

  return (
    <Card>
      {/* JOB HEADER
        - Info and action btns to the left
        - Industry image to the right
      */}
      <SuiBox display="flex" alignItems="flex-start" justifyContent="space-between" px={!isMobile ? 3.5 : 2} mt={4} mb={2}>
        {/* LEFT SIDE */}
        {/* JOB INFO */}
        <SuiBox display="flex" flexDirection="column">
          
          {/* JOB TITLE */}
          <SuiBox display="flex" alignItems="flex-start" flexDirection={isMobile ? 'column' : 'row'} >

            <SuiBox
              display="flex"
            >
              {/* ACTIVITY INDICATOR - Only for recruiters */}
              {isRecruiter && (
                <SuiBox
                  sx={{
                    width: "1rem",
                    height: "1rem",
                    borderRadius: "50%",
                    mt: 1.5,
                    mr: 1,
                    backgroundColor: getActivityColor()
                  }}
                />
              )}

              {/* TITLE, SUBTITLE AND COMPANY */}
              <SuiBox mb={1} display="flex" flexDirection="column">
                <SuiTypography variant="h3" fontWeight="medium">{title}</SuiTypography>
              
                {/* JOB SUBTITLE */}
                {job?.subtitle && <SuiTypography component="a" href="#" variant="h6" fontWeight="light">{job.subtitle}</SuiTypography>}
                
                {/* JOB COMPANY NAME - Only show for recruiters */}
                {isRecruiter && (
                  <SuiTypography variant="button" component={Link} to={`/r/company/${job?.company?.id}`} target="_blank">
                    {job?.company?.name}
                  </SuiTypography>
                )}
              </SuiBox>
            </SuiBox>
              
            {/* BADGES - Only for recruiters */}
            {isRecruiter && (
              <SuiBox display="flex" alignItems="center" ml={2}>
                {job.isHot && (
                  <SuiBadge
                    badgeContent="Hot"
                    variant="contained"
                    circular="true"
                    size="sm"
                    color="error"
                    container sx={{ height: 30, my: 1, mx: 1 }}
                  />
                )}

                {job.tags.length > 0 && (
                  <SuiBox
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="flex-end"
                  >
                    {
                      job.tags.map(tag =>
                        <SuiBadge
                          badgeContent={tag.name}
                          variant="contained"
                          circular="true"
                          color={tag.name === "HIGH PRIORITY" ? "purple" : "secondary"}
                          size="sm"
                          container sx={{ height: 30, my: 1, mx: .5 }}
                        />  
                      )
                    }
                  </SuiBox>
                )}
              </SuiBox>
            )}
          </SuiBox>

          {/* INFO ICONS */}
          <SuiBox
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems='flex-start'
            justifyContent="flex-start"
            mb={1.5}
          >
            {/* LEFT COLUMN */}
            <SuiBox
              display="flex"
              flexDirection="column"
              mt={1}
              mr={isMobile ? 'auto' : 3}
            >
              {/* INDUSTRY */}
              {job?.company?.industries[0]?.name && (
                <SuiBox display="flex" alignItems="center" mt={1}>
                  <PhoneAndroidIcon />

                  <SuiTypography variant="button" fontWeight="regular" mx={1}>
                    Industry:
                  </SuiTypography>

                  <SuiTypography variant="button" >
                    {job?.company?.industries[0]?.name}
                  </SuiTypography>
                </SuiBox>
              )}

              {/* MONTHLY SALARY */}
              {/* Visto desde company, el salario no debe verse si es SA o SAF */}
              {isCompany && (job?.hiringMethod === "sa" || job?.hiringMethod === "saf") ? null :
                <>
                  <SuiBox display="flex" alignItems="center" mt={1}>
                    <AttachMoneyIcon />
                    <SuiTypography variant="button" fontWeight="regular" mx={1}>
                      {job?.commitment?.name === 'Hourly' ? 'Hourly Rate:' : 'Monthly salary:'}
                    </SuiTypography>

                    {/* eslint-disable-next-line */}
                    {job?.fixedSalary ?
                      <SuiTypography variant="button" >
                        ${numberFormat(salaryMin)}
                      </SuiTypography>
                    : null }
                    {/* eslint-disable-next-line */}
                    {job?.salaryBasedOnExperience ?
                      <SuiTypography variant="button" >
                        Salary based on experience
                      </SuiTypography>
                    :
                    !job?.includeMaxSalary ?
                      <SuiTypography variant="button" >
                        ${numberFormat(salaryMin)}+
                      </SuiTypography>
                      : null
                    }
                    {!job?.fixedSalary && !job?.salaryBasedOnExperience && job?.includeMaxSalary ? 
                      <SuiTypography variant="button" >
                        ${numberFormat(salaryMin)} - ${numberFormat(salaryMax)}
                      </SuiTypography>
                      : null 
                    }
                  </SuiBox>
                </>
              }

              {/* LOCATION */}
              <SuiBox display="flex" alignItems="center" mt={1}>
                <LocationOnIcon />

                <SuiTypography variant="button" fontWeight="regular" mx={1}>
                  Location:
                </SuiTypography>

                <SuiTypography variant="button" >
                  {formatLocation(job?.location)}
                </SuiTypography>
              </SuiBox>
            </SuiBox>

            {/* RIGHT COLUMN */}
            <SuiBox display="flex" flexDirection="column" mt={1}>
              {/* COMMITMENT */}
              {job?.commitment && (
                <SuiBox display="flex" alignItems="center" mt={1}>
                  <AccessTimeIcon />

                  <SuiTypography variant="button" fontWeight="regular" mx={1}>
                    Commitment:
                  </SuiTypography>

                  <SuiTypography variant="button" >
                    {job?.commitment?.name}
                  </SuiTypography>
                </SuiBox>
              )}

              {/* COMPANY SIZE */}
              {job?.company?.size && (
                <SuiBox display="flex" alignItems="center" mt={1}>
                  <GroupsIcon />

                  <SuiTypography variant="button" fontWeight="regular" mx={1}>
                    Company size:
                  </SuiTypography>

                  <SuiTypography variant="button" >
                    {formatCompanySize(job?.company?.size)}
                  </SuiTypography>
                </SuiBox>
              )}

              {/* JOB TYPE */}
              <SuiBox display="flex" alignItems="center" mt={1}>
                <HowToRegIcon />

                <SuiTypography variant="button" fontWeight="regular" mx={1}>
                  Contract type:
                </SuiTypography>

                <SuiTypography variant="button" >
                  {formatHiringMethod(job?.hiringMethod)}
                </SuiTypography>

                <Tooltip title={returnHMTooltipContent()} placement="right">
                  <ErrorIcon style={{ marginLeft: 5, marginBottom: 3, cursor: "pointer" }} />
                </Tooltip>
              </SuiBox>
            </SuiBox>
          </SuiBox>

          {/* APPLY, SHARE AND MAKE ACTIVE BTNS */}
          {!isCompany && (
            <>
              {!isGuest ? (
                <SuiBox
                  display="flex"
                  mt={1}
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: { xs: "center", sm: "flex-start" },
                    alignItems: { xs: "center", sm: "flex-start" },
                    width: { xs: "100%", sm: "auto" }
                  }}
                >
                  {/* APPLY BTN */}
                  {!isRecruiter && (
                    <SuiBox
                      mr={isMobile ? 'auto' : 1}
                      my={isMobile ? 1 : 'auto'}
                      width={isMobile ? "100%" : 'auto'}
                    >
                      {!job.applied ? (
                        <SuiButton
                          color="info"
                          variant="gradient"
                          size="large"
                          component={Link}
                          to={applyUrl}
                          fullWidth={isMobile}
                        >
                          Apply for this job
                        </SuiButton>
                      ) :
                        <SuiButton
                          color="success"
                          variant="gradient"
                          size="large"
                          onClick={appliedAlert}
                          fullWidth={isMobile}
                        >
                          Applied!
                        </SuiButton>
                      }
                    </SuiBox>
                  )}

                  {/* SAVE BTN */}
                  {isInFavorites() ?
                    <SuiBox
                      mr={isMobile ? 'auto' : 1}
                      my={isMobile ? 1 : 'auto'}
                      width={isMobile ? "100%" : 'auto'}
                    >
                      <SuiButton
                        size="large"
                        color="teclaBlue"
                        fullWidth={isMobile}
                        variant="outlined"
                        className="saveJob saved"
                      >
                        Remove
                      </SuiButton>
                    </SuiBox>
                  :
                    <SuiBox
                      mr={isMobile ? 'auto' : 1}
                      my={isMobile ? 1 : 'auto'}
                      width={isMobile ? "100%" : 'auto'}
                    >
                      <SuiButton
                        size="large"
                        color="teclaBlue"
                        fullWidth={isMobile}
                        variant="outlined"
                        className="saveJob"
                      >
                        Save
                      </SuiButton>
                    </SuiBox>
                  }
                  
                  {/* SHARE BTN */}
                  {job.hasCommission ? (
                    <>
                      <SuiBox
                        mr={isMobile ? 'auto' : 1}
                        my={isMobile ? 1 : 'auto'}
                        width={isMobile ? '100%' : 'auto'}
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        alignItems="center"
                      >
                        <SuiButton size="large" color="teclaBlue" fullWidth={isMobile} variant="outlined" onClick={copyTextClick} sx={{ height: "2.6rem" }}>
                          <ShareIcon sx={{ mr: 1 }} />
                          Share
                        </SuiButton>

                      {isRecruiter ?
                        <SuiTypography ml={1} variant="button" fontWeight="regular" textAlign="center">
                          This job has a ${job.commissionAmount} referral comission
                        </SuiTypography>
                      :
                        <SuiTypography ml={1} variant="button" fontWeight="regular" textAlign="center">
                          This job will earn you ${job.commissionAmount} if your referral gets hired!
                        </SuiTypography>
                      }
                      </SuiBox>
                    </>
                  ) :
                    <SuiBox mr={2} mt={1}>
                      <SuiButton color="teclaBlue" variant="outlined" fullWidth={isMobile} onClick={copyTextClick} sx={{ height: "2.6rem" }}>
                        <ShareIcon sx={{ mr: 1 }} />
                        Share
                      </SuiButton>
                    </SuiBox>
                  }
                </SuiBox>
              ) :
                <SuiBox display="flex" mt={3} alignItems="center">
                  <SuiBox mr={2} mt={1}>
                    <WithAttachedFunction jobId={job.id} type="0" text="Apply" candidateCode={candidateCode} />
                  </SuiBox>
                  <SuiBox display="flex" mt={1}>
                    <SuiButton color="teclaBlue" variant="outlined" fullWidth={isMobile} onClick={copyTextClick} sx={{ height: "2.6rem" }}>
                      <ShareIcon sx={{ mr: 1 }} />
                      Share
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              }
            </>
          )}

          {/* COUNTRIES LIST */}
          <SuiBox display="flex" alignItems="center" mt={2}>
            <PublicIcon sx={{ mb: .2 }} />
            {renderCountries() === 'No countries assigned'
              ? <SuiTypography variant="button" fontWeight="regular" ml={1}>
                  No countries assigned
                </SuiTypography>
              : <SuiTypography variant="button" fontWeight="regular" ml={1}>
                  Open to:
                  {renderCountries()}
                </SuiTypography>
            }
          </SuiBox>
        </SuiBox>

        {/* RIGHT SIDE */}
        {/* INDUSTRY IMAGE */}
        {!isMobile && (
          <SuiBox
            component="img"
            src={getIndustryImage(job?.company?.industries[0]?.name)}
            alt="Industry"
            sx={{ width:"25rem", my: -2 }}
            
          />
        )}
      </SuiBox>
      
      {/* COMPANY ABOUT */}
      {stripHtml(companyDesc) && (
        <SuiBox
          sx={{
            py: 3,
            px: 5,
            my: 1,
            mx: 1,
          }}
        > 
          <SuiBox mb={2}>
            <SuiTypography variant="h5" mb={1} ml={1}>
              About the company
            </SuiTypography>
            <SuiTypography variant="body2" color="text" ml={1}>
              <div dangerouslySetInnerHTML={{ __html: companyDesc }} />
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      )}
      
      {/* JOB DETAILS, QUESTIONS AND BENEFITS */}
      <SuiBox
        sx={{
          py: 3,
          px: 5,
          my: 1,
          mx: 3,
          borderRadius: '.5rem',
          backgroundColor: "#f5f5f5",
        }}
      >
        {/* JOB DETAILS */}
        <SuiBox>
          <SuiTypography variant="h5">
            Job details
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" ml={1}>
            <div dangerouslySetInnerHTML={{ __html: desc }} />
          </SuiTypography>
        </SuiBox>
        
        {/* BENEFITS */}
        {job?.benefits?.length > 0 && (
          <SuiBox mt={1} mb={1}>
            <SuiTypography variant="button">Benefits</SuiTypography>
              <ul>
                {job?.benefits?.map(b => (
                  <li key={b?.id} style={{ fontSize: '.8rem' }}>
                    <SuiBox sx={{ display: "flex", alignItems: "center" }}>
                      <SuiTypography variant="button" fontWeight="regular" mr={1}>{b?.name}</SuiTypography>

                      <SuiBox
                        component="img"
                        width="1rem"
                        src={getBenefitIcon(b?.name)}
                        alt="skill"
                      />
                    </SuiBox>
                  </li>
                ))}
              </ul>
          </SuiBox>
        )}

        {/* PRE-SCREENING QUESTIONS */}
        {isRecruiter && job?.questions?.filter(q => !q.isJobQuestion).length > 0 && (
          <SuiBox mt={1} mb={1}>
            <SuiTypography variant="button">Pre-screening questions</SuiTypography>
              <ul>
                {job?.questions?.filter(q => !q.isJobQuestion).map(q => (
                  <li style={{ fontSize: '.8rem' }} key={q.id}>
                    <SuiTypography variant="button" fontWeight="regular">{q.title}</SuiTypography>
                  </li>
                ))}
              </ul>
          </SuiBox>
        )}
      </SuiBox>

      {/* SKILLS LIST */}
      <SuiBox px={3}>
        {/* REQUIRED SKILLS */}
        <SuiBox mt={1} ml={1} mb={1} display="flex" alignItems="center">
          <SuiBox display="flex" alignItems="center">
            <CodeIcon />
            <SuiTypography variant="button" fontWeight="regular" mx={1}>Required Skills:</SuiTypography>
          </SuiBox>

          <SuiBox ml={1} display="flex" alignItems="center" flexWrap="wrap" maxWidth="80%">         
            {job?.requiredSkills?.map(requiredSkill => 
              <SuiBox
                key={requiredSkill?.id}
                mr={1}
                sx={{
                  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  px: 1.5,
                  py: 1,
                  m: .5
                }}
              >
                <SuiBox
                  component="img"
                  width="1rem"
                  src={getSkillIcon(requiredSkill?.skill?.name)}
                  alt="skill"
                />

                <SuiTypography variant="button" color="text" ml={1} fontSize=".8rem">
                  {requiredSkill?.skill?.name}
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>
        </SuiBox>
        
        {/* OPTIONAL SKILLS */}
        {job?.optionalSkills && job?.optionalSkills?.length > 0 && (
          <SuiBox mt={1.5} ml={1} mb={1} display="flex" alignItems="center">
            <SuiBox display="flex" alignItems="center">
              <GroupAddIcon />
              <SuiTypography variant="button" fontWeight="regular" mx={1}>Optional Skills:</SuiTypography>
            </SuiBox>
            
            <SuiBox ml={1} display="flex" alignItems="center" flexWrap="wrap" maxWidth="80%">

              {renderOptionalSkills(job?.optionalSkills)}
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
      
      <SuiBox p={3}>
        {/* ACTION BTNS */}
        {/* RECRUITER ACTION BTNS */}
        {isRecruiter && (
          <SuiBox mt={1} display="flex" alignItems="center" flexDirection={isMobile ? "column" : "row"}>
            {/* STATS BTN - Only show if job status is closed */}
            {job.status === "closed" && (
              <SuiButton
                variant="outlined"
                color="teclaBlue"
                size="large"
                onClick={() => setSuccessReport(true)}
                fullWidth={isMobile}
                style={{
                  marginRight: !isMobile ? "10px" : "auto",
                  marginTop: isMobile ? 5 : "auto",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                  SEE STATS
              </SuiButton>
            )}

            {/* MAKE ACTIVE BTN */}
            {isAdmin && job.status === "in_review" && (
              <SuiButton
                color="success"
                size="large"
                onClick={makeActive}
                fullWidth={isMobile}
                style={{
                  marginRight: !isMobile ? "10px" : "auto",
                  marginTop: isMobile ? 5 : "auto",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                Make active
              </SuiButton>
            )}

            {/* VIEW FEEDBACK BTN */}
            <Badge
              color="secondary"
              badgeContent={feedbackCount}
              max={999}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{ width: isMobile ? '100%' : 'auto' }}
            >
              <SuiButton
                variant="outlined"
                color="teclaBlue"
                size="large"
                component={Link}
                to={`/r/feedback/${jobId}`}
                fullWidth={isMobile}
                style={{
                  marginRight: !isMobile ? "10px" : "auto",
                  marginTop: isMobile ? 5 : "auto",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                View feedback
              </SuiButton>
            </Badge>

            {/* EDIT JOB BTN */}
            {(user && (user.type === "tecla_admin" || user.type === "tecla_manager")) && (
              <SuiButton
                color="teclaBlue"
                size="large"
                component={Link}
                to={editJobUrl}
                fullWidth={isMobile}
                style={{
                  marginRight: !isMobile ? "10px" : "auto",
                  marginTop: isMobile ? 5 : "auto",
                  height: "3.1rem",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                &nbsp; Edit Job
              </SuiButton>
            )}

            {/* MARK AS HOT BTN - admins and managers can edit everything. Only active jobs can be set as hot */}
            {(user.type === "tecla_admin" || user.type === "tecla_manager") && (
              <SuiButton
                color="teclaBlue"
                size="large"
                py={25}
                fullWidth={isMobile}
                sx={{
                  marginRight: !isMobile ? "10px" : "auto",
                  marginTop: isMobile ? 5 : "auto",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                component={Link}
                to={`/r/manage?id=${job?.id}`}
                target="_blank"
              >
                Applicants
              </SuiButton>
            )}

            {/* ADD HOT BTN - admins and managers can edit everything. Only hot jobs can be set as hot */}
            {((user.type === "tecla_admin" || user.type === "tecla_manager") && !job.isHot) && (
              <SuiButton
                color="teclaBlue"
                size="large"
                py={25}
                fullWidth={isMobile}
                sx={{
                  marginRight: !isMobile ? "10px" : "auto",
                  marginTop: isMobile ? 5 : "auto",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                onClick={() => setShowSetHotJobModal(true)}
              >
                &nbsp;Mark as Hot Job
              </SuiButton>
            )}

            {/* REMOVE HOT BTN - admins and managers can edit everything. Only hot jobs can be set as hot */}
            {((user.type === "tecla_admin" || user.type === "tecla_manager") && job.isHot) && (
              <SuiButton
                color="error"
                variant="outlined"
                size="large"
                py={25}
                fullWidth={isMobile}
                sx={{
                  marginRight: !isMobile ? "10px" : "auto",
                  marginTop: isMobile ? 5 : "auto",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                onClick={() => SetShowRemoveHotJobModal(true)}
              >
                &nbsp;Remove Hot
              </SuiButton>
            )}

            {/* JOB NOTES BTN */}
            <SuiButton
              color="teclaBlue"
              size="large"
              py={25}
              onClick={handleOpen}
              fullWidth={isMobile}
              sx={{
                marginRight: !isMobile ? "10px" : "auto",
                marginTop: isMobile ? 5 : "auto",
                height: "3.1rem",
                paddingLeft: "10px",
                  paddingRight: "10px",
              }}
            >
              &nbsp;Job Notes
            </SuiButton>

            {/* CONPANY BTN */}
            <SuiButton
              color="teclaBlue"
              size="large"
              py={25}
              onClick={() => setOpenNotes(true)}
              fullWidth={isMobile}
              sx={{
                marginRight: !isMobile ? "10px" : "auto",
                marginTop: isMobile ? 5 : "auto",
                height: "3.1rem",
                paddingLeft: "10px",
                  paddingRight: "10px",
              }}
            >
              &nbsp;Company Notes
            </SuiButton>
            <Notes openNotes={openNotes} setOpenNotes={setOpenNotes} company={company} setCompany={setCompany} loading={loading} setLoading={setLoading} />

            {/* Screening Questions NOTES BTN */}
            <SuiButton
              color="teclaBlue"
              size="large"
              py={25}
              onClick={handleOpenScreening}
              fullWidth={isMobile}
              sx={{
                marginRight: !isMobile ? "10px" : "auto",
                marginTop: isMobile ? 5 : "auto",
                height: "3.1rem",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              &nbsp;PRE-SCREEN
            </SuiButton>

            {/* Decline Comments */}
            <SuiButton
              color="teclaBlue"
              size="large"
              py={25}
              onClick={handleOpenDecline}
              fullWidth={isMobile}
              sx={{
                marginRight: !isMobile ? "10px" : "auto",
                marginTop: isMobile ? 5 : "auto",
                height: "3.1rem",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              &nbsp;Decline Comments
            </SuiButton>
          </SuiBox>
        )}

        {/* CANDIDATE ACTION BTNS */}
        {!isCompany && !isRecruiter && (
          <> 
            <SuiBox
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent={isMobile ? "center" : "flex-start"}
              alignItems={isMobile ? "center" : "flex-start"}
              width={isMobile ? "100%" : "auto"}
            >
              {/* APPLY BTN */}
              {!job.applied && (
                <>
                  {isGuest ? (
                    <SuiBox
                      ml={isMobile ? 'auto' : 1}
                      my={isMobile ? 1 : 'auto'}
                      width='100%'
                      maxWidth={isMobile ? '100%' : '30rem'}
                    >
                      <WithAttachedFunction
                        size="large"
                        fullWidth
                        jobId={job.id}
                        text={isMobile ? "Apply" : "Apply for this job"}
                        type="0"
                        candidateCode={candidateCode}
                      />
                    </SuiBox>
                  ) : (
                    <SuiBox
                      ml={isMobile ? 'auto' : 1}
                      my={isMobile ? 1 : 'auto'}
                      width='100%'
                      maxWidth={isMobile ? '100%' : '30rem'}
                    >
                      <SuiButton
                        variant="gradient"
                        color="info"
                        size="large"
                        component={Link}
                        to={applyUrl}
                        fullWidth
                      >
                        {isMobile ? 'Apply' : `Apply as ${sliceString(job?.seniorityName, 30)}`}
                      </SuiButton>
                  </SuiBox>
                  )}
                </>
              )}

              {/* FEEDBACK BTN */}
              <SuiBox
                ml={isMobile ? 'auto' : 1}
                my={isMobile ? 1 : 'auto'}
                width='100%'
                maxWidth={isMobile ? '100%' : '30rem'}
              >
                <SuiButton
                  variant="outlined"
                  color="teclaBlue"
                  fullWidth
                  size="large"
                  onClick={() => setShowJobFeedbackModal(true)}
                  sx={{ height: isGuest ? "2.8rem" : "2.3" }}
                >
                  {isMobile ? "Feedback" : "Leave your feedback"}
                </SuiButton>
              </SuiBox>
            </SuiBox>

          </>
        )}

        {/* HOTJOB MODALS */}
        {showSetHotJobModal && (
          <SetHotJobModal
            showSetHotJobModal={showSetHotJobModal}
            setShowSetHotJobModal={setShowSetHotJobModal}
            jobId={job.id}
          />
        )}

        {ShowRemoveHotJobModal && (
          <RemoveHotJobModal
            ShowRemoveHotJobModal={ShowRemoveHotJobModal}
            SetShowRemoveHotJobModal={SetShowRemoveHotJobModal}
            jobId={job.id}
          />
        )}

        {/* NOTES MODAL */}
        {openNotesModal && (
          <Modal
            open={openNotesModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backfaceVisibility: "hidden" }}
          >
            <SuiBox sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: "100vw", md: "80vw", xl: "60vw" },
              height: "auto",
              maxHeight: "80%",
              overflowY: "scroll",
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: { xs: 2, md: 4 },
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap">
                  <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Notes about&nbsp;</SuiTypography>
                  <SuiTypography variant="h6" fontWeight="bold">{job?.company?.name} - </SuiTypography>
                  <SuiTypography variant="h6" fontWeight="bold">{title}</SuiTypography>
                </SuiBox>
                <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
              </SuiBox>

              {showNotesLoader ?
                <SuiBox display="flex" justifyContent="center" alignItems="center" my={15}>
                  <CircularProgress size={40} />
                </SuiBox >
              :
                <>
                  <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="100%" my={2} sx={{ display: "table" }}>
                    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                      <Table aria-label="simple table">
                        <TableBody style={{ display: "table", width: "100%" }}>

                          {notesToDisplay.map(row => (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                '&:last-child th': { width: "4rem" },
                                '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" },
                              }}
                            >
                              <TableCell component="th" scope="row" size="small">
                                <SuiTypography variant="body2" color="secondary" sx={{ width: "130px" }}>
                                  {row.name}
                                </SuiTypography>

                                <SuiTypography variant="body2" color="secondary" sx={{ width: "140px" }}>
                                  {row.date}
                                </SuiTypography>
                              </TableCell>

                              <TableCell
                                align="left"
                                size="small"
                                className="tableEditCell"  
                              >
                                <SuiTypography variant="body2" color="secondary">
                                  <div className="noteContent" dangerouslySetInnerHTML={{ __html: row.message }} style={{ paddingTop: "5px" }} />
                                </SuiTypography>
                              </TableCell>

                              <TableCell align="left" size="small" className="tableEditCell">
                                {canEditAndDeleteNote(row.authorId, row.companyId) && (
                                  <>
                                    <EditIcon size="large" color="secondary" onClick={editNote} data-html={row.message} data-extra={row.job} data-id={row.id} sx={{ mr: 5 }} />

                                    <DeleteIcon fontSize="medium" color="secondary" cursor="pointer" onClick={() => setNoteIdToDelete(row.id)} />
                                    
                                    {showDeleteJobNoteModal && (
                                      <DeleteJobNoteModal
                                        showDeleteJobNoteModal={showDeleteJobNoteModal}
                                        setShowDeleteJobNoteModal={setShowDeleteJobNoteModal}
                                        noteIdToDelete={noteIdToDelete}
                                        setNoteIdToDelete={setNoteIdToDelete}
                                        notesToDisplay={notesToDisplay}
                                        setNotesToDisplay={setNotesToDisplay}
                                      />
                                    )}
                                  </>
                                )}
                              </TableCell>
                              
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </SuiBox>

                  <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="auto" my={2}>
                    <SuiEditor height="100%" name="noteHtml" defaultValue={noteHtml} onChange={setNoteHtml} />
                  </SuiBox>

                  <SuiBox display="inherit">
                    <SuiButton color="info" variant="gradient" sx={{ marginLeft: 1 }} onClick={createNote}>Send</SuiButton>
                  </SuiBox>
                </>
              }

              
            </SuiBox>
          </Modal>
        )}

        {/* Screening Questions MODAL */}
        {openScreeningModal && (
          <Modal
            open={openScreeningModal}
            onClose={handleCloseScreening}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backfaceVisibility: "hidden" }}
          >
            <SuiBox sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: "100vw", md: "80vw", xl: "60vw" },
              height: "auto",
              maxHeight: "80%",
              overflowY: "scroll",
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: { xs: 2, md: 4 },
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap">
                  <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Screening Questions for&nbsp;</SuiTypography>
                  <SuiTypography variant="h6" fontWeight="bold">{job?.company?.name} - </SuiTypography>
                  <SuiTypography variant="h6" fontWeight="bold">{title}</SuiTypography>
                </SuiBox>
                <Icon onClick={handleCloseScreening} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
              </SuiBox>

              {showNotesLoader ?
                <SuiBox display="flex" justifyContent="center" alignItems="center" my={15}>
                  <CircularProgress size={40} />
                </SuiBox >
              :
                <>
                  <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="100%" my={2} sx={{ display: "table" }}>
                    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                      <Table aria-label="simple table">
                        <TableBody style={{ display: "table", width: "100%" }}>

                          {screeningQuestions.map(row => (
                            <TableRow
                              key={row.pk}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                '&:last-child th': { width: "4rem" },
                                '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" },
                              }}
                            >
                              <TableCell
                                align="left"
                                size="small"
                                className="tableEditCell"  
                              >
                                <SuiTypography variant="body2" color="secondary">
                                  <div className="noteContent" dangerouslySetInnerHTML={{ __html: row.content }} style={{ paddingTop: "5px" }} />
                                </SuiTypography>
                              </TableCell>

                              <TableCell align="left" size="small" className="tableEditCell">
                                {canScreeningQuestions() && (
                                  <>
                                    <EditIcon size="large" color="secondary" onClick={editSQ} data-html={row.content} data-id={row.pk} sx={{ mr: 5 }} />

                                    <DeleteIcon fontSize="medium" color="secondary" cursor="pointer" onClick={() => setSQToDelete(row.pk)} />
                                    
                                    {showDeleteSQModal && (
                                      <DeleteSQModal
                                        showDeleteSQModal={showDeleteSQModal}
                                        setShowDeleteSQModal={setShowDeleteSQModal}
                                        sQToDelete={sQToDelete}
                                        setSQToDelete={setSQToDelete}
                                        screeningQuestions={screeningQuestions}
                                        setScreeningQuestions={setScreeningQuestions}
                                        fetchScreeningQuestions={fetchScreeningQuestions}
                                      />
                                    )}
                                  </>
                                )}
                              </TableCell>
                              
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </SuiBox>

                  <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="auto" my={2}>
                    <SuiEditor height="100%" name="noteHtml" defaultValue={sQHtml} onChange={setSQHtml} />
                  </SuiBox>

                  <SuiBox display="inherit">
                    <SuiButton color="info" variant="gradient" sx={{ marginLeft: 1 }} onClick={createScreeningQuestion}>Send</SuiButton>
                  </SuiBox>
                </>
              }

              
            </SuiBox>
          </Modal>
        )}

        {/* Decline Comments */}
        {openDeclineModal && (
          <Modal
            open={openDeclineModal}
            onClose={handleCloseDecline}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backfaceVisibility: "hidden" }}
          >
            <SuiBox sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: "100vw", md: "80vw", xl: "60vw" },
              height: "auto",
              maxHeight: "80%",
              overflowY: "scroll",
              bgcolor: 'background.paper',
              border: 'none',
              boxShadow: 24,
              borderRadius: { xs: 2, md: 4 },
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap">
                  <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Decline Comments for&nbsp;</SuiTypography>
                  <SuiTypography variant="h6" fontWeight="bold">{job?.company?.name} - </SuiTypography>
                  <SuiTypography variant="h6" fontWeight="bold">{title}</SuiTypography>
                </SuiBox>
                <Icon onClick={handleCloseDecline} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
              </SuiBox>

              <SuiBox id="modal-modal-description" bgColor="#f8f9fa" borderRadius="16px" height="100%" my={2} sx={{ display: "table" }}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table aria-label="simple table">
                    <TableBody style={{ display: "table", width: "100%" }}>
                      <TableRow
                        key="declinedBy"
                      >
                        <TableCell
                          align="left"
                          size="small"
                          className="tableEditCell"
                          sx={{ width: "140px" }}
                        >
                          <SuiTypography variant="body2" color="text" fontWeight="bold">
                            Declined By
                          </SuiTypography>
                        </TableCell>

                        <TableCell
                          align="left"
                          size="small"
                          className="tableEditCell"
                          sx={{ width: "300px" }}
                        >
                          <SuiTypography variant="body2" color="text" fontWeight="bold">
                            Decline Reason
                          </SuiTypography>
                        </TableCell>

                        <TableCell
                          align="left"
                          size="small"
                          className="tableEditCell"  
                        >
                          <SuiTypography variant="body2" color="text" fontWeight="bold">
                            Decline Comment
                          </SuiTypography>
                        </TableCell>
                        
                      </TableRow>
                      {job?.declineComments && job?.declineComments.map(row => (
                        <TableRow
                          key={row.pk}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:last-child th': { width: "4rem" },
                            '&:nth-of-type(odd)': { backgroundColor: "#f8f9fa" },
                          }}
                        >
                          <TableCell
                            align="left"
                            size="small"
                            className="tableEditCell"
                          >
                            <SuiTypography variant="body2" color="secondary">
                              {formatDeclineType(row.declinedBy__type)}
                            </SuiTypography>
                          </TableCell>

                          <TableCell
                            align="left"
                            size="small"
                            className="tableEditCell"
                            sx={{ width: "300px" }}
                          >
                            <SuiTypography variant="body2" color="secondary">
                              {formatDeclineReason(row.declinedReason)}
                            </SuiTypography>
                          </TableCell>

                          <TableCell
                            align="left"
                            size="small"
                            className="tableEditCell"  
                          >
                            <SuiTypography variant="body2" color="secondary">
                              {row.declinedComment}
                            </SuiTypography>
                          </TableCell>

                          <TableCell align="left" size="small" className="tableEditCell">
                            <Tooltip title="View application" placement="top">
                              <EyeIcon size="large" color="secondary" onClick={() => openAppLink(row.pk)} sx={{ mr: 1, marginTop: "4px" }} />
                            </Tooltip>
                          </TableCell>
                          
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </SuiBox>
            </SuiBox>
          </Modal>
        )}

        {/* FEEDBACK MODAL */}
        {showJobFeedbackModal && (
          <JobFeedbackModal
            showJobFeedbackModal={showJobFeedbackModal}
            setShowJobFeedbackModal={setShowJobFeedbackModal}
            jobId={jobId}
          />
        )}
      </SuiBox>
    </Card >
  );
  }

Job.defaultProps = {
  setJob: null,
  isGuest: false,
  code: "",
  token: "",
  notes: [],
  setNotes: null,
  screeningQuestions: [],
  setScreeningQuestions: null,
  fetchScreeningQuestions: null,
  feedbackCount: null,
  setSuccessReport: null,
}

Job.propTypes = {
  job: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setJob: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  candidate: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  isGuest: PropTypes.bool,
  code: PropTypes.string,
  isCompany: PropTypes.bool.isRequired,
  updatesCount: PropTypes.number.isRequired,
  notes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setNotes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  screeningQuestions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setScreeningQuestions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fetchScreeningQuestions: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  token: PropTypes.string,
  feedbackCount: PropTypes.number,
  setSuccessReport: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default Job;
