/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types'
import { useState } from 'react'
import { ReactSession } from 'react-client-session'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiSelect from 'components/SuiSelect'
import SuiAlert from 'components/SuiAlert'
// MUI components
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'

function ScreeningProcessModal({ showScreeningProcessModal, setShowScreeningProcessModal, application }) {

  ReactSession.setStoreType('localStorage')
  const token = ReactSession.get('token')

  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)

  const [selectedInterviewState, setSelectedInterviewState] = useState(application?.screeningInterviewState)
  const [selectedCvState, setSelectedCvState] = useState(application?.screeningCVState)

  const tomorrowDate = new Date()
  tomorrowDate.setDate(tomorrowDate.getDate() + 1)
  
  const interviewStates = [
    { value: 0, label: 'Pending schedule' },
    { value: 1, label: 'Scheduled' },
    { value: 2, label: 'Completed' }
  ]

  const cvStates = [
    { value: 0, label: 'Pending review' },
    { value: 1, label: 'Editing' },
    { value: 2, label: 'Sourcer approved' }
  ]

  // Function to format date to UTC with zero UTC offset, like this: "YYYY-MM-DDTHH:mm:ss.sssZ"
  const formatToUTC = date => new Date(date).toISOString()

  // Fuction to format date from UTC with zero UTC offset, to local timezone with this format 'yyyy-MM-ddThh:mm'
  const convertToLocalTime = utcDate => {
    const utcDateTime = new Date(`${utcDate  }Z`);

    // Get the user's local date components
    const year = utcDateTime.getFullYear();
    const month = (utcDateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = utcDateTime.getDate().toString().padStart(2, '0');
    const hours = utcDateTime.getHours().toString().padStart(2, '0');
    const minutes = utcDateTime.getMinutes().toString().padStart(2, '0');

    // Construct the local date string
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const setScreening = async () => {
    const selectedInterviewDate = $('#interviewDateInput').val()

    if (selectedInterviewState !== application?.screeningInterviewState ||
        (formatToUTC(selectedInterviewDate) || selectedInterviewState === 0) !== application?.screeningInterviewDate ||
        selectedCvState !== application?.screeningCVState
      ) {
          setShowSpinner(true)

          const requestBody = {}
          if (selectedInterviewState !== application?.screeningInterviewState) { requestBody.interviewState = selectedInterviewState }
          if (selectedInterviewState >= 1 && formatToUTC(selectedInterviewDate) !== application?.screeningInterviewDate) { requestBody.interviewDate = formatToUTC(selectedInterviewDate) }
          if (selectedCvState !== application?.screeningCVState) { requestBody.CVState = selectedCvState }

          try {
            const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${application?.id}/setScreening/`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`
              },
              body: JSON.stringify(requestBody)
          })
            const data = await res.json()
            setShowSpinner(false)

            if (data.success) {
              setShowSuccessMsg(true)
              setShowDoneIcon(true)
              setTimeout(() => {
                setShowScreeningProcessModal(false)
                window.location.reload()
              }, 2500)
            }
            
            else {
              setShowErrorMsg(true)
              setTimeout(() => { setShowErrorMsg(false) }, 2500)
            } 
        } catch (error) {
            console.error('There was an error!', error)
            setShowErrorMsg(true)
            setTimeout(() => { setShowErrorMsg(false) }, 2500)
        }
      }

      else setShowScreeningProcessModal(false)
    }
                          
  return (
    <Modal open={showScreeningProcessModal} onClose={() => setShowScreeningProcessModal(false)}>
      <SuiBox
        sx={{
          width: '850px',
          height: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: 'none',
          boxShadow: 24,
          borderRadius: { xs: 0, md: 4 },
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {showErrorMsg && <SuiAlert color='error'>An error has ocurred, please try again later.</SuiAlert>}
        {showSuccessMsg && <SuiAlert color='success'>Screening process updated successfully!</SuiAlert>}

        <SuiTypography sx={{ my: 2.5 }} variant='h6'>Edit screening process</SuiTypography>

        <SuiBox my={1}>
          <SuiTypography sx={{ my: 2.5 }} variant='button'>Interview state</SuiTypography>
          <SuiSelect
            placeholder='Interview state'
            options={interviewStates}
            name='interviewState'
            onChange={e => setSelectedInterviewState(e.value)}
            defaultValue={application?.screeningInterviewState === -1 ? '' : interviewStates.filter(state => state.value === application?.screeningInterviewState)}
            value={interviewStates.filter(state => state.value === selectedInterviewState)}
          />
        </SuiBox>

        { selectedInterviewState >= 1 ? (
          <SuiBox my={1}>
            <SuiTypography sx={{ my: 1 }} variant='button' display='block'>Interview date and hour</SuiTypography>
            <TextField
              type='datetime-local'
              id='interviewDateInput'
              defaultValue={application?.screeningInterviewDate ? convertToLocalTime(application?.screeningInterviewDate) : convertToLocalTime(tomorrowDate)}
              sx={{ width: '100%' }}
            />
          </SuiBox>
        ) : null }

        <SuiBox my={1}>
          <SuiTypography sx={{ my: 2.5 }} variant='button'>CV state</SuiTypography>
          <SuiSelect
            placeholder='CV state'
            options={cvStates}
            name='CVState'
            onChange={e => setSelectedCvState(e.value)}
            defaultValue={cvStates.filter(state => state.value === application?.screeningCVState)}
            value={cvStates.filter(state => state.value === selectedCvState)}
          />
        </SuiBox>

        <SuiBox mt={5} mb={5} width='80%' mx='auto' display='flex'>
          <SuiButton
            sx={{ mx: 1 }}
            fullWidth
            color='info'
            variant='outlined'
            disabled={showSpinner || showDoneIcon}
            onClick={() => setShowScreeningProcessModal(false)}
          >
            Cancel
          </SuiButton>

          {/* eslint-disable-next-line */}
          {showSpinner ?
            <SuiBox minWidth='15rem' mx={5} display='flex' justifyContent='center' alignItems='center'><CircularProgress size={20} /></SuiBox>
            :
            showDoneIcon ?
                <SuiBox minWidth='15rem' mx={5} display='flex' justifyContent='center' alignItems='center'><DoneIcon size={20} /></SuiBox>
                :
                <SuiButton
                  sx={{ mx: 1 }}
                  fullWidth
                  color='info'
                  variant='gradient'
                  disabled={showSpinner}
                  onClick={() => setScreening()}
                >
                  Confirm
                </SuiButton>
          }
        </SuiBox>
      </SuiBox>
    </Modal>
  )
}

export default ScreeningProcessModal

// typechecking props
ScreeningProcessModal.propTypes = {
  showScreeningProcessModal: PropTypes.bool.isRequired,
  setShowScreeningProcessModal: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired // eslint-disable-line
}