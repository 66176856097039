/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { Formik, Form } from "formik";
// SUI components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// MUI components
import { Icon, Modal } from "@mui/material";
// Layoutes
import SignIn from "layouts/pages/users/new-user/components/SignIn";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// Assets
import backgroundSignin from "assets/images/contryBg.png";


function getContent(formData) {
    return <SignIn formData={formData} />;
}
function SignInModal({ text, size, fullWidth }) {

    // This state controls what modal content is shown
    // By default, first we show showApplyAsGuestModal, and after user action we redirect:
    // Apply as guest continua a la aplicación
    // Log In abre el modal anterior
    // Sign Up te lleva a sign up de candidato
    const [showApplyAsGuestModal, setShowApplyAsGuestModal] = useState(true);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setShowApplyAsGuestModal(true)
    };

    const searchParams = new URLSearchParams(window.location.search);
    const jobId = searchParams.get('id');
    const code = searchParams.get('code');
    let urlRedirect = `/job/apply?id=${jobId}`;
    if (code) {
        urlRedirect = `${urlRedirect}&code=${code}`
    }

    // Validation
    const [activeStep, setActiveStep] = useState(0);
    const { formId, formField } = form;
    const currentValidation = validations[activeStep];
    const isLastStep = false;
    let timerid = "";

    ReactSession.setStoreType("localStorage");

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const submitForm = async (values, actions) => {
        await sleep(1000);

        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values, null, 2));

        // actions.setSubmitting(false);
        actions.resetForm();

        setActiveStep(0);
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            // setActiveStep(activeStep + 1);
            actions.setTouched({});
            // actions.setSubmitting(false);
        }
    };


    function getCandidate(token) {
        const headers = { "Content-Type": "application/json" };
        if (token) {
            headers.Authorization = `Token ${token}`;
        }
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`, { headers, })
            .then(async response => {
                const data = await response.json();
                // console.log(data.data.countries)

                ReactSession.set("candidate", data.data);
                ReactSession.set("company", null);
                ReactSession.set("recruiter", null);
                if (!data.data.cv) {
                  window.location.replace("/t/wizard");
                } else {
                  window.location.replace(urlRedirect);
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    function manageLogin(response) {
        $(".suiAlertMsg").remove();
        if (response.success) {
            ReactSession.set("user", response.data.user);
            ReactSession.set("token", response.data.access_token);
            // window.location.replace("/t/dashboard");
            getCandidate(response.data.access_token);
        } else {
            $(".alertErrorBox div div").text(response.data.error);
            $(".alertErrorBox").show();

            if (response.data.id) {
                if (timerid) {
                    clearTimeout(timerid);
                }
                timerid = setTimeout(() => {
                    window.location.replace(`/activate?id=${response.data.id}`);
                }, 3000);
            }
        }
    }

    const signInClick = async () => {
        await sleep(100);
        if ($(".MuiTypography-caption:visible").text().length === 0) {
            const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/login/`;
            const postBody = {
                'email': $("input[name=email]").val(),
                'password': $("input[name=password]").val()
            };

            const requestMetadata = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: new URLSearchParams(postBody)
            };

            fetch(recipeUrl, requestMetadata)
                .then(res => res.json())
                .then(response => {
                    manageLogin(response);
                });
        }
    }

    return (
        <>
            <SuiButton onClick={handleOpen} variant="gradient" color="info" size={size} fullWidth={fullWidth} sx={{ height: "2.8rem" }}>
                {text}
            </SuiButton>

            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >   
                {showApplyAsGuestModal ?

                    // <SuiBox
                    //     sx={{
                    //         display: "flex",
                    //         position: 'relative',
                    //         flexDirection: "column",
                    //         justifyContent: "start",
                    //         top: '50%',
                    //         left: '50%',
                    //         transform: 'translate(-50%, -50%)',
                    //         width: { xs: "100vw", md: "52rem", lg: "53rem", xl: "59rem" },
                    //         height: { xs: "100vh", md: "67vh" },
                    //         background: "white",
                    //         border: 'none',
                    //         boxShadow: 16,
                    //         borderRadius: { xs: 0, md: 4 },
                    //         p: 3,
                    //     }}
                    // >
                    //     <SuiBox display="flex" height="100%">
                    //         <SuiBox>
                    //         <SuiBox display="flex" justifyContent="flex-end">
                    //             <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                    //         </SuiBox>

                    //         <SuiTypography sx={{ my: 2.5 }} variant="h4" textAlign="center">How do you want to apply for this position?</SuiTypography>
                                
                    //         <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex" flexDirection="column" justifyContent="center" alignItems="cener">
                    //             <SuiButton
                    //                 fullWidth
                    //                 component={Link}
                    //                 to={urlRedirect} 
                    //                 color="info"
                    //                 variant="outlined"
                    //                 sx={{ my: 2 }}
                    //             >
                    //                 Apply as guest
                    //             </SuiButton>
                                
                    //             <SuiButton
                    //                 fullWidth
                    //                 onClick={() => setShowApplyAsGuestModal(false)}
                    //                 sx={{ my: 2, backgroundColor: "#feec1e" }}
                    //             >
                    //                 <SuiTypography variant="button" color="black">
                    //                     Log in
                    //                 </SuiTypography>
                    //             </SuiButton>

                    //             <SuiButton
                    //                 fullWidth
                    //                 sx={{ my: 2, backgroundColor: "#feec1e" }}
                    //                 component={Link}
                    //                 to="/t/login"
                    //             >
                    //                 <SuiTypography variant="button" color="black">
                    //                     Sign Up
                    //                 </SuiTypography>
                    //             </SuiButton>
                                
                    //         </SuiBox>

                    //         <SuiTypography sx={{ my: 2.5 }} variant="body2">Note: You can always apply as a guest, but by creating a TECLA profile your information will be saved and the next application will be a breeze!</SuiTypography>

                    //         </SuiBox>
                            
                    //         <SuiBox component="img" src={backgroundSignin} alt="coutries" width="auto" height="100%" top="0" right="5%" sx={{ display: { xs: "none", md: "initial", xl: "initial" } }} />
                    //     </SuiBox>
                    // </SuiBox>

                    <SuiBox
                        sx={{
                            display: "flex",
                            position: 'relative',
                            flexDirection: "column",
                            justifyContent: "start",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: "100vw", md: "52rem", lg: "53rem", xl: "59rem" },
                            height: { xs: "100vh", md: "58vh" },
                            background: "white",
                            border: 'none',
                            boxShadow: 16,
                            borderRadius: { xs: 0, md: 4 },
                            p: 3,
                        }}
                    >
                        <SuiBox display="flex" justifyContent="flex-end" alignItems="center">
                            <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                        </SuiBox>

                        <SuiBox display="flex" height="100%">
                            <SuiBox sx={{ width: { xs: "100%", lg: "55%" }, height: "100%" }} display="flex" flexDirection="column">
                                <SuiTypography sx={{ my: 2.5 }} variant="h4" textAlign="center">How do you want to apply for this position?</SuiTypography>
                                
                                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex" flexDirection="column" justifyContent="center" alignItems="cener">
                                    <SuiButton
                                        fullWidth
                                        component={Link}
                                        to={urlRedirect} 
                                        color="info"
                                        variant="outlined"
                                        sx={{ my: 2 }}
                                    >
                                        Apply as guest
                                    </SuiButton>
                                    
                                    <SuiButton
                                        fullWidth
                                        onClick={() => setShowApplyAsGuestModal(false)}
                                        sx={{ my: 2, backgroundColor: "#feec1e" }}
                                    >
                                        <SuiTypography variant="button" color="black">
                                            Log In
                                        </SuiTypography>
                                    </SuiButton>

                                    <SuiButton
                                        fullWidth
                                        sx={{ my: 2, backgroundColor: "#feec1e" }}
                                        component={Link}
                                        to="/t/signup"
                                    >
                                        <SuiTypography variant="button" color="black">
                                            Sign Up
                                        </SuiTypography>
                                    </SuiButton>
                                    
                                </SuiBox>

                                <SuiTypography sx={{ my: 2.5, ml: 6 }} variant="body2" align="justify">Sign Up to save your info for future applications</SuiTypography>
                            </SuiBox>

                            <SuiBox position="absolute" component="img" src={backgroundSignin} alt="coutries" width="auto" height="100%" top="0" right="5%" sx={{ display: { xs: "none", md: "initial", xl: "initial" } }} />
                        </SuiBox>
                    </SuiBox>
                :
                    <SuiBox
                        sx={{
                            display: "flex",
                            position: 'relative',
                            flexDirection: "column",
                            justifyContent: "start",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: "100vw", md: "52rem", lg: "53rem", xl: "59rem" },
                            height: { xs: "100vh", md: "67vh" },
                            background: "white",
                            border: 'none',
                            boxShadow: 16,
                            borderRadius: { xs: 0, md: 4 },
                            p: 3,
                        }}
                    >
                        <SuiBox mb={5} mt={1}>
                            <SuiBox className="alertErrorBox" display="none" style={{ maxWidth: "450px" }}>
                                <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
                            </SuiBox>

                            <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <SuiTypography id="child-modal-title" variant="overline" opacity={0.75} sx={{ lineHeight: "1rem" }} mb={3}>
                                    <SuiTypography fontWeight="bold" variant="overline">Already have an account?</SuiTypography> <br /> Apply to our jobs in less than a minute!
                                </SuiTypography>
                                <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                            </SuiBox>

                            <SuiBox display="flex">
                                <SuiTypography id="modal-modal-description" variant="h2" fontWeight="bold">
                                    Sign in to TECLA
                                </SuiTypography>
                            </SuiBox>

                            <SuiTypography variant="button" color="text" fontWeight="regular" opacity={0.75}>
                                Don&apos;t have an account?&nbsp;
                                <SuiTypography
                                    component={Link}
                                    to="/t/signup"
                                    variant="button"
                                    color="info"
                                    fontWeight="bold"
                                >
                                    Sign up
                                </SuiTypography>
                            </SuiTypography>
                        </SuiBox>

                        <SuiBox display="flex" height="100%">
                            <SuiBox sx={{ width: { xs: "100%", lg: "55%" }, height: "100%" }} mr={2} display="inherit">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={currentValidation}
                                    onSubmit={handleSubmit}
                                >
                                    {({ values, errors, touched, issubmitting }) => (
                                        <Form id={formId} autoComplete="off" type="POST" actions="#">
                                            <SuiBox display="flex" flexDirection="column" justifyContent="space-evenly" height="100%">

                                                <SuiBox>
                                                    {getContent({
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                    })}
                                                </SuiBox>

                                                <SuiBox>
                                                    <SuiBox display="flex" alignItems="center" flexWrap="wrap" mt={1} mb={1} width="100%" sx={{ justifyContent: { xs: "space-evenly", md: "right" } }}>

                                                        <SuiButton component={Link} to={urlRedirect} variant="outlined" color="info" size="small" gradient sx={{ width: { xs: "100%", sm: "15rem", md: "initial" }, height: { xs: "44px", sm: "initial" }, marginBottom: { xs: 1, sm: 0 }, marginRight: { xs: 0, sm: 1 } }}>
                                                            <SuiTypography color="info" variant="button">
                                                                CONTINUE AS A GUEST
                                                            </SuiTypography>
                                                        </SuiButton>

                                                        <SuiButton type="submit" size="small" issubmitting={issubmitting} onClick={signInClick} mr={2} sx={{ width: { xs: "100%", sm: "15rem", md: "initial" }, height: { xs: "44px", sm: "initial" }, marginBottom: { xs: 1, sm: 0 }, backgroundColor: "#feec1e" }}>
                                                            <SuiTypography variant="button" color="black">
                                                                Sign In
                                                            </SuiTypography>
                                                        </SuiButton>
                                                    </SuiBox>
                                                    <SuiBox width="100%" display="flex" justifyContent="right" mt={2}>
                                                        <SuiTypography
                                                            variant="button"
                                                            fontWeight="bold"
                                                            sx={{ color: "#097df7", fontSize: "0.85rem", textAlign: "right" }}
                                                            component={Link}
                                                            to="/reset-password"
                                                        >
                                                            Lost password?
                                                        </SuiTypography>
                                                    </SuiBox>
                                                </SuiBox>

                                            </SuiBox>
                                        </Form>
                                    )}
                                </Formik>
                            </SuiBox>
                            <SuiBox position="absolute" component="img" src={backgroundSignin} alt="coutries" width="auto" height="100%" top="0" right="5%" sx={{ display: { xs: "none", md: "initial", xl: "initial" } }} />
                        </SuiBox>
                    </SuiBox>
                }
            </Modal>
        </>
    );
}

SignInModal.defaultProps = {
  text: "",
  size: "small",
  fullWidth: false
}

SignInModal.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default SignInModal;
