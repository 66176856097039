/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react"
// prop-type is a library for typechecking of props
import PropTypes from "prop-types" // eslint-disable-line
// @mui material components
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiSelect from "components/SuiSelect"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import { ReactSession } from 'react-client-session'
import PdfViewer from 'layouts/pdfViewer/PdfViewer'

function Download({ cvUrl }) {

  const token = ReactSession.get("token")

  const [cvLinkedToUserProfile, setCvLinkedToUserProfile] = useState(false)
  const [cvLinkedToApp, setCvLinkedToApp] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showAppSpinner, setShowAppSpinner] = useState(false)
  const [applications, setApplications] = useState([])
  const [applicatioId, setApplicatioId] = useState(0)
  
  
  const linkCVtoUserProfile = async () => {
    setShowSpinner(true)

    const currentUrl = window.location.href
    const candidateId = currentUrl.substr(currentUrl.lastIndexOf("/") + 1, currentUrl.length);
    const postBody = {
      'userId': candidateId,
    };
    await fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/setCVCreated/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Token ${token}`
      },
      body: new URLSearchParams(postBody)
    })
    .then(async response => {
      const data = await response.json()

      if (data.success) {
        setShowSpinner(false)
        setCvLinkedToUserProfile(true)
      } else {
        throw new Error('Error linking CV to profile')
      }
    })
    .catch(error => console.error('There was an error!', error))
  }

  const setCVCreatedToApplication = async () => {
    if (applicatioId) {
      setShowAppSpinner(true)
      const postBody = {
        'applicationId': applicatioId,
      };
      await fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/setCVCreatedToApplication/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Token ${token}`
        },
        body: new URLSearchParams(postBody)
      })
      .then(async response => {
        const data = await response.json()

        if (data.success) {
          setShowAppSpinner(false)
          setCvLinkedToApp(true)
        } else {
          throw new Error('Error linking CV to profile')
        }
      })
      .catch(error => console.error('There was an error!', error))
    }
  }

  function getAppName(tempApp) {
    const companyName = tempApp?.job?.company?.name;
    const seniority = tempApp?.job?.seniorityName;
    const jobName = tempApp?.job?.name;
    return `${companyName} - ${seniority} ${jobName}`
  }

  useEffect(() => {
    const fetchHeaders = token ?
      { 'Authorization': `Token ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' }
      :
      { 'Content-Type': 'application/x-www-form-urlencoded' }

    const currentUrl = window.location.href
    const candidateId = currentUrl.substr(currentUrl.lastIndexOf("/") + 1, currentUrl.length);
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/applications/${candidateId}/teclaCandidateApplications/`, {
      method: 'GET',
      headers: fetchHeaders
    })
      .then(async response => {
        const data = await response.json()
        const result = []

        if (data.success) {
          Object.values(data?.data?.applications).forEach(val => result.push({ value: val.id, label: getAppName(val) }))
        } else {
          throw new Error('Error fetching countries')
        }

        setApplications(result)
      })
      .catch(error => console.error('There was an error!', error))
  }, [])

  const isSmallScreen = useMediaQuery('(max-width: 1201px)')

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Download CV</SuiTypography>
      </SuiBox>
      
      <a href={cvUrl} target="_blank" rel="noreferrer">
        <SuiButton color="info" fullWidth sx={{marginTop: 2, marginBottom: 2}}>Download your CV</SuiButton>
      </a>

      {/* linkCVtoUserProfile option only shows to logged in users */}
      {token ? (
        <Grid item xs={12} sx={{marginTop: 2, marginBottom: 2}}>
          <Grid container xs={12} md={12} spacing={1}>
            <Grid item xs={6}>
              <SuiSelect
                placeholder="Application"
                label="Link CV to application"
                smallFont={isSmallScreen}
                size={isSmallScreen ? "small" : "medium"}
                options={applications}
                name="application"
                onChange={e => {
                    setApplicatioId(e.value)
                }}
              />
            </Grid>
            <Grid item xs={6} >
              { cvLinkedToApp ? (
                <SuiButton disabled color="info" fullWidth><DoneIcon fontSize="large"/></SuiButton>
              ) : (<>
                { showAppSpinner ? (
                  <SuiBox display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </SuiBox>
                ) : (
                  <SuiButton color="info" onClick={setCVCreatedToApplication} fullWidth>Link CV to application</SuiButton>
                ) }
              </>) }
            </Grid>
          </Grid>
        </Grid>
      ) : null }
      {token ?
        <>
          {showSpinner ?  // eslint-disable-line
            <SuiBox mt={3} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </SuiBox>
          :
            !cvLinkedToUserProfile ?
              <SuiButton
                color="info"
                fullWidth
                sx={{marginTop: 2, marginBottom: 5}}
                onClick={() => linkCVtoUserProfile()}
              >Link CV to candidate profile</SuiButton>
            :
            <SuiBox mt={3} mb={5} display="flex" justifyContent="center" alignItems="center">
              <DoneIcon fontSize="large"/>
            </SuiBox >
          }
        </>
        :
        null
      }

    {cvUrl &&
      <Grid item xs={15} lg={15} className="pdfBackground">
        <Card sx={{ overflowY: "auto", backgroundColor: 'white' }}>
            <PdfViewer cvUrl={cvUrl} />
        </Card>
      </Grid>
    }

    </SuiBox >
  )
}

// typechecking props for UserSignUp
Download.propTypes = {
  cvUrl: PropTypes.string.isRequired,
}

export default Download
