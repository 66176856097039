/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import { ReactSession } from 'react-client-session';
// Soft UI Dashboard PRO React layouts
import RecruiterDashboard from "layouts/dashboards/recruiter";
import RecruiterFolders from "layouts/recruiterFolders/recruiterFolders";
import JobListRecruiter from "layouts/pages/account/joblistRecruiter";
import TotalApplications from "layouts/pages/total-applications";
import Error404 from "layouts/authentication/error/404";
import RecruiterLogout from 'layouts/pages/recruiterLogout/RecruiterLogout';
import ProfileRecruiter from "layouts/pages/profile/profile-recruiter";
import ManagerInvoices from 'layouts/pages/managerInvoices/ManagerInvoices';
// MUI
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import FolderIcon from '@mui/icons-material/Folder';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PersonIcon from '@mui/icons-material/Person';
import AssessmentIcon from '@mui/icons-material/Assessment';
// Soft UI Dashboard PRO React icons
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import RecruiterSuccessRate from 'layouts/pages/recruiterSucessRate/recruiterSuccessRate';

ReactSession.setStoreType("localStorage");
const user = ReactSession.get("user");
const recruiter = ReactSession.get("recruiter");
const token = ReactSession.get("token");

let notificationsCount = ReactSession.get("notificationsCount") ? ReactSession.get("notificationsCount") : 0;
let notificationActive = "";
if (notificationsCount) notificationActive = "active";

const invoicesNotificationsCount = ReactSession.get("invoicesNotificationsCount") ? ReactSession.get("invoicesNotificationsCount") : 0;
let invoicesNotificationActive = "";
if (invoicesNotificationsCount) invoicesNotificationActive = "active";


const fetchNotifications = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/users/getNotificationsCount/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    })
    const data = await res.json()

    if (data.success) {
      ReactSession.set("notificationsCount", data.data)

      // Get span element with class count and parent with id #notificationsMenuBtn
      const notificationsCountSpan = document.querySelector("#notificationsMenuBtn .count")
      // If span element exists, update its innerHTML
      if (notificationsCountSpan && notificationsCountSpan !== 0) notificationsCountSpan.innerHTML = data.data
      
      notificationsCount = data.data
      notificationActive = "active"
    }
      
  } catch (error) {
      console.error('There was an error!', error)
  }
}
fetchNotifications()

const firstName = user && user.first_name ? user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1) : null
const lastName = user && user.last_name ? user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1) : null

const accountType = () => {
  if (user) {
    if (user.type === "tecla_admin") return "Administrator"
    if (user.type === "tecla_manager") return "Manager"
    return "Recruiter"
  }
  return null
}

let routes = [ // eslint-disable-line
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  {
    type: "collapse",
    name: "Profile",
    key: "/r/recruiter",
    route: "/r/recruiter",
    href: "/r/recruiter",
    component: ProfileRecruiter,
    icon: <PersonIcon size="12px" />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "/r/dashboard",
    route: "/r/dashboard",
    href: "/r/dashboard",
    component: RecruiterDashboard,
    icon: <SpaceShip size="12px" />,
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Talent",
    key: "/r/talent",
    route: "/r/talent",
    href: "/r/talent",
    component: Error404,
    icon: <PeopleIcon size="12px" />,
  },
  {
    type: "collapse",
    name: "Companies",
    key: "/r/companies",
    route: "/r/companies",
    href: "/r/companies",
    component: Error404,
    icon: <Office size="12px" />,
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Jobs",
    key: "/r/jobs",
    route: "/r/jobs",
    href: "/r/jobs",
    component: JobListRecruiter,
    icon: <Document size="12px" />,
  },
  {
    type: "collapse",
    name: "Applications",
    key: "/r/applications",
    route: "/r/applications",
    href: "/r/applications",
    component: TotalApplications,
    icon: <CustomerSupport size="12px" />,
  },
  {
    type: "collapse",
    name: "Folders",
    key: "/r/folders",
    route: "/r/folders",
    href: "/r/folders",
    icon: <FolderIcon size="10px" />,
  },
  {
    type: "collapse",
    name: "Client folders",
    key: "/r/client-folders",
    route: "/r/client-folders",
    href: "/r/client-folders",
    icon: <FolderIcon size="10px" />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "/r/notifications",
    component: RecruiterFolders,
    icon: <div id="notificationsMenuBtn" className={`notificationsBox ${notificationActive}`}><span className="count">{notificationsCount}</span><PriorityHighIcon /></div>,
  },
  {
    type: "collapse",
    name: "Invoices",
    key: "/r/invoices",
    route: "/r/invoices",
    href: "/r/invoices",
    component: ManagerInvoices,
    icon: <div className={`notificationsBox ${invoicesNotificationActive}`}><span className="count">{invoicesNotificationsCount}</span><CustomerSupport /></div>,
  },
  {
    type: "collapse",
    name: "Success rate",
    key: "/r/successRate",
    route: "/r/successRate",
    href: "/r/successRate",
    component: RecruiterSuccessRate,
    icon: <AssessmentIcon />,
  },
  { type: "divider", key: "divider" },
  {
    type: "collapse",
    name: "Add Skills",
    key: "addSkills",
    icon: <div id="addSkillFromMenu" className="addSkillFromMenu"><SpaceShip /></div>,
  },
  {
    type: "collapse",
    name: "Add Industries",
    key: "addIndustries",
    icon: <div id="addIndustryFromMenu" className="addIndustryFromMenu"><SpaceShip /></div>,
  },
  {
    type: "collapse",
    name: "Log out",
    title: "test title",
    key: "/r/logout",
    route: "/r/logout",
    href: "/r/logout",
    component: RecruiterLogout,
    icon: <LogoutIcon fontSize="small" />,
  },
  {
    type: "recruiterAccountDetail",
    name: "Recruiter",
    title: `${firstName} ${lastName} - ${accountType()}`,
    key: "recruiter",
  },
];

// Only recruiters that canManageTeams can access /r/invoices
if (!recruiter?.canManageTeams) routes = routes.filter(route => route.key !== "/r/invoices")
// Only recruiters that can createClientFolders can access /r/client-folders
if (!recruiter?.createClientFolders) routes = routes.filter(route => route.key !== "/r/client-folders")
// Only recruiters that are admin can access /r/successRate
if (!recruiter?.user?.type === "tecla_admin") routes = routes.filter(route => route.key !== "/r/successRate")

export default routes;