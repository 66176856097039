/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import { useState }from "react"
import PropTypes from "prop-types"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
// Component for each individual experience
import Experience from "./experience/Experience"

function ProfessionalExperience({ formData }) {

  /* Props:
    - formFields contain information about field in the form and are defined in layouts/cvBuilder/formSchemas/form
    - values, errors and touched are predefined by Formik
  */  
  // eslint-disable-next-line
  const { formField, values, errors, touched, countries, candidateJobExperiences, setCandidateJobExperiences, 
          candidateSkillsIds, candidateSkills, monthOptions, yearOptions, skillOptions } = formData

  const [firstEnter, setFirstEnter] = useState(true)
  const defaultExpSkills = [];
  if (candidateSkills && candidateSkills.length && candidateSkillsIds && candidateSkillsIds.length && candidateSkills.length === candidateSkillsIds.length) {
    for (let i = 0; i < candidateSkills?.length; i++) { // eslint-disable-line
      defaultExpSkills.push({ id: candidateSkillsIds[i], name: candidateSkills[i] })
    }
  }

  const addAnotherJobExperience = () => {
    let currentId = 0;
    if (candidateJobExperiences.length) {
      currentId = candidateJobExperiences[candidateJobExperiences.length - 1].id || 0;
    }
    setCandidateJobExperiences([...candidateJobExperiences, {
      id: currentId + 1,
      company: "",
      position: "",
      startDate: "",
      endDate: "",
      country: "",
      state: "",
      tasks: [],
      achievements: [],
      skills: defaultExpSkills,
    }])
  }

  // const verifyProfessionalExperienceInputs = () => {

  //   let errorsDetected = false
    
  //   candidateJobExperiences.forEach(exp => { // eslint-disable-line
  //     if (!exp.company || !exp.position || !exp.startDate || !exp.endDate || !exp.country || !exp.tasks.length) {
  //       errorsDetected = true
  //       return showError()
  //     }
  //   })

  //   if (!errorsDetected) setActiveStep(activeStep+1)
  // }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Job experience</SuiTypography>
        <SuiTypography variant="button" ml={1} mb={1}>For better results it is highly suggested to add a max of 3 jobs or the last 5 years of experience.</SuiTypography>
      </SuiBox>

      {skillOptions && skillOptions.length && candidateJobExperiences.map(experience => <Experience
          key={experience.id}
          experienceId={experience.id}
          experience={experience}
          candidateJobExperiences={candidateJobExperiences}
          setCandidateJobExperiences={setCandidateJobExperiences}
          countries={countries}
          monthOptions={monthOptions}
          yearOptions={yearOptions}
          firstEnter={firstEnter}
          setFirstEnter={setFirstEnter}
          skillOptions={skillOptions}
        />
      )}

      <SuiButton
        color="info"
        onClick={() => addAnotherJobExperience()}
        fullWidth
        sx={{marginTop: 5, marginBottom: 5}}
      >Add another experience</SuiButton>
  

    </SuiBox >
  )
}

// typechecking props for ProfessionalExperience
ProfessionalExperience.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default ProfessionalExperience
