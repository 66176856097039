/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import { useState }from "react"
import PropTypes from "prop-types"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
// Component for each individual experience
import EducationRecord from "./educationRecord/EducationRecord"

function Education({ formData }) {

  /* Props:
    - formFields contain information about field in the form and are defined in layouts/cvBuilder/formSchemas/form
    - values, errors and touched are predefined by Formik
  */
  // eslint-disable-next-line
  const { formField, values, errors, touched, countries, candidateEducation, setCandidateEducation,
    monthOptions, yearOptions, postCV } = formData

  const [firstEnter, setFirstEnter] = useState(true)
  const addAnotherEducationRecord = () => {
    let currentId = 0;
    if (candidateEducation.length) {
      currentId = candidateEducation[candidateEducation.length - 1].id || 0;
    }
    setCandidateEducation([...candidateEducation, {
      id: currentId + 1,
      institute: "",
      country: "",
      state: "",
      year: "",
      title: ""
    }])
  }

  const verifyEducationInputs = () => {
    // let errorsDetected = false

    // candidateEducation.forEach(record => { // eslint-disable-line
    //   if (!record.institute || !record.country || !record.endDate || !record.title) {
    //     errorsDetected = true
    //     return showError()
    //   }
    // })

    postCV(values)
  }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Education</SuiTypography>
      </SuiBox>

      {candidateEducation.map(record => <EducationRecord
        key={record.id}
        recordId={record.id}
        record={record}
        candidateEducation={candidateEducation}
        setCandidateEducation={setCandidateEducation}
        countries={countries}
        monthOptions={monthOptions}
        yearOptions={yearOptions}
        firstEnter={firstEnter}
        setFirstEnter={setFirstEnter}
      />
      )}

      <SuiButton
        color="info"
        onClick={() => addAnotherEducationRecord()}
        fullWidth
        sx={{ marginTop: 5, marginBottom: 5 }}
      >
        Add Another Academic Entry
      </SuiButton>

      <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
        <SuiButton variant="outlined" color="info" isSubmitting sx={{ visibility: "hidden" }}>Back</SuiButton>
        <SuiButton variant="gradient" color="info" onClick={() => verifyEducationInputs()} className="changeStep nextStep" isSubmitting>Submit</SuiButton>
      </SuiBox>

    </SuiBox >
  )
}

// typechecking props for UserSignUp
Education.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default Education
