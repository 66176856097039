/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as Yup from "yup"
import checkout from "layouts/cvBuilder/formSchemas/form"

const {
  formField: { firstName, lastName, email, phone, linkedin },
} = checkout

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg).matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Only alphabets are allowed for this field ").min(1, firstName.tooShort),
    [lastName.name]: Yup.string().required(lastName.errorMsg).matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Only alphabets are allowed for this field ").min(1, lastName.tooShort),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [phone.name]: Yup.string().min(8, phone.invalidMsg).max(15, phone.invalidMsg).matches(/^[0-9]*$/g, "Only numbers without spaces are allowed for this field."),
    [linkedin.name]: Yup.string(),
  }),
]
