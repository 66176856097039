/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { ReactSession } from 'react-client-session'
// MUI Components
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiEditor from "components/SuiEditor";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { useState } from "react";
// import SuiAlert from "components/SuiAlert";


function SalesModal({ openNotes, setOpenNotes, company, setCompany, loading, setLoading }) {

    const [note, setNote] = useState("");
    
    ReactSession.setStoreType("localStorage")
    const user = ReactSession.get("user")
    const token = ReactSession.get("token")

    const fullname = company.name;
    
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
      `;
  
    function uploadResume() {
        if (user) {
            setLoading(true);
            const postBody = {
              'companyId': company.id,
              'notes': note
            };
            const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/setCompanyNotes/`;
            const requestMetadata = {
              method: 'POST',
              headers: {
                "content-type": "application/x-www-form-urlencoded",
                "Authorization": `Token ${token}`,
              },
              body: new URLSearchParams(postBody)
            };

            fetch(recipeUrl, requestMetadata)
              .then(res => res.json())
              .then(response => {
                if (response.success) {
                  setLoading(false);
                  const tempCompany = {...company}
                  tempCompany.companyNotes = note;
                  setCompany(tempCompany)
                }
              });
        }
    }

    return (
        <Modal
            open={openNotes}
            onClose={() => setOpenNotes(false)}
        >
            <SuiBox 
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "100vw", md: "40vw", xl: "70vw" },
                    height: { xs: "101vh", md: "fit-content" },
                    maxHeight: "90%",
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >

                { loading ? (
                <SuiBox className="moonCover" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={0}>
                    <MoonLoader color="#17c1e8" loading={loading} css={override} size={70} />
                </SuiBox>
                ) : null }
                <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                <SuiBox display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                    <SuiTypography id="modal-modal-title" variant="h6" ml={1.5} color="dark">Notes for&nbsp;</SuiTypography>
                    <SuiBox sx={{ mx: { xs: '110', md: '0' } }}>
                    <SuiTypography variant="h6" fontWeight="bold">{fullname}</SuiTypography>
                    </SuiBox>
                </SuiBox>
                <Icon onClick={() => setOpenNotes(false)} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                </SuiBox>
                <SuiBox id="modal-modal-description" borderRadius="16px" height="100%" my={2} p={2} sx={{ overflow: "auto" }}>
                <Grid>
                    <SuiEditor
                        maxLength={2000}
                        defaultValue={company?.companyNotes ? company.companyNotes : ""}
                        name="companyNote"
                        onChange={setNote}
                        placeholder="Notes"
                    />
                </Grid>
                </SuiBox>
                <SuiBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                <SuiButton
                    color="info"
                    onClick={() => { uploadResume() }}
                >
                    Save
                </SuiButton>
                </SuiBox>
            </SuiBox>
        </Modal>
  )
}

SalesModal.propTypes = {
    openNotes: PropTypes.bool.isRequired,
    setOpenNotes: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired, // eslint-disable-line
    setCompany: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
}

export default SalesModal