/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-type is a library for typechecking of props
import PropTypes from 'prop-types'
import { useState } from 'react'
import { ReactSession } from 'react-client-session'
// @mui material components
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'

function ProfileHeaderMenuForRecruiters({
                                            candidate,
                                            setShowContractFilesModal,
                                            isRecruiter,
                                            isManager,
                                            magicLink,
                                            copyMagicLink,
                                            canShare,
                                            copyTextClick,
                                            canEditApplication,
                                            appId,
                                            canCreateApplication,
                                            setshowAddToRecruiterFolderModal,
                                            setShowSoftBan,
                                            canSource,
                                            setshowAddToRecruiterSourcing,
                                            // canForms,
                                            // handleOpenForm,
                                            canNote,
                                            application,
                                            validateDialog,
                                            declineDialog,
                                            setOpenActivityModal,
                                            setOpenNotesModal,
                                            setShowCandidateScorecardModal,
                                            canEditScorecard,
                                            setShowCandidateCVsModal,
                                            markAsInterviewed
                                        }) {

    ReactSession.setStoreType('localStorage')
    const recruiter = ReactSession.get('recruiter')

    const menuItems = []

    function editCandidateLink() {
        if (candidate && candidate.user) {
          const customCandidateSession = {...candidate};
          ReactSession.set(`CandidateForEdit${candidate.user.id}`, customCandidateSession);
          window.open(`/r/editCandidateWizard/${candidate.user.id}`);
        }
    }

    function editApplicationLink() {
        if (application) {
          const customApplicationSession = {...application};
          ReactSession.set(`ApplicationForEdit${appId}`, customApplicationSession);
        }
        window.open(`/r/applicationEdit/${appId}`, '_blank');
    }

    const isApplicationView = window.location.pathname.includes('/application')

    // Conditionally populate menu items
    const buildMenu = () => {
        if (isRecruiter && candidate && candidate.user) menuItems.push({ label: 'Create CV', action: () => window.open(`/r/cvbuilder/${candidate.user.id}`, '_blank') })
        // Only show if recruiter canManageTeams and candidate isn't inTeam
        if (recruiter?.canManageTeams && !candidate.inTeam) menuItems.push({ label: 'Add to Team', action: () => window.open(`/r/add-to-team/${candidate?.user?.id}`, '_blank') })
        // Only show if recruiter canManageTeams and candidate is inTeam
        if (recruiter?.canManageTeams && candidate.inTeam) menuItems.push({ label: 'Contract Files', action: () => setShowContractFilesModal(true) })

        if (isRecruiter && candidate && candidate.user) menuItems.push({ label: 'Similar Profiles', action: () => window.open(`/r/talent/${candidate.user.id}/similar`, '_blank') })

        if (magicLink) menuItems.push({ label: 'Magic Link', action: e => {
                copyMagicLink(e)
                $('.magicSuccessBox').show()
                let timerid = ""
                if (timerid) clearTimeout(timerid)
                timerid = setTimeout(() => {
                    $('.magicSuccessBox').hide()
                }, 2000)
            }
        })
        
        if (isRecruiter && candidate) menuItems.push({ label: 'Edit Profile', action: () => editCandidateLink() })
        
        if (canShare) menuItems.push({ label: 'Share Profile', action: e => {
                copyTextClick(e)
                $('.alertSuccessBox').show()
                let timerid = ""
                if (timerid) clearTimeout(timerid)
                timerid = setTimeout(() => {
                    $('.alertSuccessBox').hide()
                }, 2000)
            }
        })

        if (canEditApplication) menuItems.push({ label: 'Edit Application', action: () => editApplicationLink() })

        if (canCreateApplication) menuItems.push({ label: 'Create Application', action: () => window.open('/r/createApplication', '_blank') })

        if (!appId) menuItems.push({ label: 'Add to Folder', action: () => setshowAddToRecruiterFolderModal(true) })

        if (canSource) menuItems.push({ label: 'Source', action: () => setshowAddToRecruiterSourcing(true) })

        // if (canForms) menuItems.push({ label: `Forms ${isRecruiter ? `(${candidate?.formsCount})` : null}`, action: () => handleOpenForm() })

        if (isRecruiter && candidate) menuItems.push({ label: `Activity ${isRecruiter ? `(${candidate?.activityCount})` : null}`, action: () => setOpenActivityModal(true) })
    
        if (canNote) menuItems.push({ label: `Notes ${isRecruiter ? `(${candidate?.notesCount})` : null}`, action: () => setOpenNotesModal(true) })

        if (canEditApplication && application.status === 'in-review') {
            menuItems.push({ label: 'ACTIVATE', action: () => validateDialog() })
            menuItems.push({ label: 'DECLINE', action: () => declineDialog() })
        }

        if (isRecruiter) menuItems.push({ label: (isApplicationView && canEditScorecard()) ? 'Edit scorecard' : 'View scorecard', action: () => setShowCandidateScorecardModal(true) })

        if (isRecruiter) menuItems.push({ label: 'View all CVs', action: () => setShowCandidateCVsModal(true) })

        if (isRecruiter && application && application?.status === "in-review" && application.interviewed) {
            menuItems.push({ label: 'Mark as Not Screened', action: () => markAsInterviewed(false) })
        }

        if (isRecruiter && application && application?.status === "in-review" && !application.interviewed) {
            menuItems.push({ label: 'Mark as Screened', action: () => markAsInterviewed(true) })
        }

        if (!appId && isManager && !candidate.autoDeclineApplications) menuItems.push({ label: 'Ban User', action: () => setShowSoftBan(true) })
    }

    buildMenu()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const openMenu = (event) => setAnchorEl(event.currentTarget)
    const closeMenu = () => setAnchorEl(null)

    return (
        <SuiBox>
            <SuiButton
                color="info"
                sx={{ width: { xs: "100%", sm: "auto" }, minHeight: "3rem" }}
                onClick={openMenu}
            >
                Actions
            </SuiButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
            >   
                {menuItems.map(item => 
                    <MenuItem
                        key={item.label}
                        onClick={e => {
                            item.action(e)
                            closeMenu()
                        }}
                    >
                        {item.label}
                    </MenuItem>
                )}
            </Menu>
        </SuiBox >
    )
}

ProfileHeaderMenuForRecruiters.defaultProps = {

}

// typechecking props
ProfileHeaderMenuForRecruiters.propTypes = {
    candidate: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    setShowContractFilesModal: PropTypes.func.isRequired,
    isRecruiter: PropTypes.bool.isRequired,
    isManager: PropTypes.bool.isRequired,
    magicLink: PropTypes.object.isRequired, // eslint-disable-line
    copyMagicLink: PropTypes.func.isRequired,
    canShare: PropTypes.bool.isRequired,
    copyTextClick: PropTypes.func.isRequired,
    canEditApplication: PropTypes.bool.isRequired,
    appId: PropTypes.number.isRequired,
    canCreateApplication: PropTypes.bool.isRequired,
    setshowAddToRecruiterFolderModal: PropTypes.func.isRequired,
    setShowSoftBan: PropTypes.func.isRequired,
    canSource: PropTypes.bool.isRequired,
    setshowAddToRecruiterSourcing: PropTypes.func.isRequired,
    // canForms: PropTypes.bool.isRequired,
    // handleOpenForm: PropTypes.func.isRequired,
    canNote: PropTypes.bool.isRequired,
    setOpenNotesModal: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired, // eslint-disable-line
    validateDialog: PropTypes.func.isRequired,
    declineDialog: PropTypes.func.isRequired,
    setOpenActivityModal: PropTypes.func.isRequired,
    setShowCandidateScorecardModal: PropTypes.func.isRequired,
    canEditScorecard: PropTypes.func.isRequired,
    setShowCandidateCVsModal: PropTypes.func.isRequired,
    markAsInterviewed: PropTypes.func.isRequired,
}

export default ProfileHeaderMenuForRecruiters