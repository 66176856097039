/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ReactSession } from 'react-client-session'
// @mui material components
import Grid from "@mui/material/Grid"
// prop-type is a library for typechecking of props
import PropTypes from "prop-types"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiSelect from "components/SuiSelect"
// import SuiButton from "components/SuiButton"
import SuiTypography from "components/SuiTypography"
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField"

function BasicInfo({ formData }) {
  /* We use these session variables to obtain information about the user?. */
  ReactSession.setStoreType("localStorage")
  const user = ReactSession.get("user")
  const candidate = ReactSession.get("candidate")

  /* Props:
    - formFields contain information about field in the form and are defined in layouts/cvBuilder/formSchemas/form
    - values, errors and touched are predefined by Formik
  */
  const { formField, values, errors, touched, countries, country, setCountry } = formData
  const { firstName, lastName, email, phone, linkedin, bio } = formField

  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    phone: phoneV,
    linkedin: linkedinV,
    bio: bioV
  } = values

  // const verifyBasicInfoInputs = () => {
  //   if (firstNameV.length > 0 && !errors.firstName &&
  //     lastNameV.length > 0 && !errors.lastName &&
  //     emailV.length > 0 && !errors.email &&
  //     phoneV.length > 0 && !errors.phone &&
  //     bioV.length > 0 && !errors.bio) {
  //     setActiveStep(activeStep + 1)
  //   }
  //   else showError()
  // }

  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mt={3} mb={3}>
        <SuiTypography variant="h5" fontWeight="regular">Basic details and contact information</SuiTypography>
      </SuiBox>

      <SuiBox>
        <Grid container rowSpacing={0} columnSpacing={3}>

          <Grid item xs={6}>
            <SuiTypography variant="button" ml={1} mb={1}>First name</SuiTypography>

            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              defaultValue={user?.first_name || ""}
              placeholder={firstName.placeholder}
              value={firstNameV}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>

          <Grid item xs={6}>
            <SuiTypography variant="button" ml={1} mb={1}>Last name</SuiTypography>

            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              defaultValue={user?.last_name || ""}
              placeholder={lastName.placeholder}
              value={lastNameV}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>

          <Grid item xs={12}>
            <SuiTypography variant="button" ml={1} mb={1}>Email address</SuiTypography>

            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>

          <Grid item xs={12}>
            <SuiTypography variant="button" ml={1} mb={1}>Phone number</SuiTypography>

            <FormField
              type={phone.type}
              label={phone.label}
              name={phone.name}
              defaultValue={candidate?.phone || ""}
              placeholder="Phone Number"
              value={phoneV}
              error={errors.phone && touched.phone}
              success={phoneV.length > 0 && !errors.phone}
              icon={{
                component: "add",
                direction: "left",
              }}
              // event on paste taste
              onPaste={e => {
                e.preventDefault();
                // remove all characters except numbers
                const paste = e.clipboardData.getData("text/plain").replace(/\D/g, "");
                // insert pasted text
                document.execCommand("insertText", false, paste);
              }}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <SuiTypography variant="button" ml={1} mb={1}>
              Country
            </SuiTypography>
            { country ? (
              <SuiSelect
                  placeholder="Country (required)"
                  label="Country"
                  options={countries}
                  name="country (required)"
                  defaultValue={{ value: country, label: country }}
                  success={country} // eslint-disable-line
                  onChange={e => setCountry(e.value)}
              />
            ) : (
              <SuiSelect
                  placeholder="Country (required)"
                  label="Country"
                  options={countries}
                  name="country (required)"
                  success={country} // eslint-disable-line
                  onChange={e => setCountry(e.value)}
              />
            ) }
          </Grid>
          

          <Grid item xs={12}>
            <SuiTypography variant="button" ml={1} mb={1}>
              LinkedIn profile
            </SuiTypography>
            <FormField
              type={linkedin.type}
              label={linkedin.label}
              name={linkedin.name}
              defaultValue={candidate?.linkedin || ""}
              value={linkedinV}
              placeholder={linkedin.placeholder}
              error={errors.linkedin && touched.linkedin}
              success={linkedinV.length > 0 && !errors.linkedin}
            />
          </Grid>

        </Grid>
      </SuiBox>

      <SuiBox width="100%" textAlign="center" mb={4}>
        <SuiTypography variant="h5" fontWeight="regular">Summary</SuiTypography>
      </SuiBox>

      <SuiBox width="100%" textAlign="center" mb={4}>
        <FormField
          type={bio.type}
          label={bio.label}
          name={bio.name}
          value={bioV}
          placeholder={bio.placeholder}
          error={errors.bio && touched.bio}
          success={bioV.length >= 200 && bioV.length <= 500 && !errors.bio}
          multiline
          rows={3}
        />
      </SuiBox>

    </SuiBox >
  )
}

// typechecking props for UserSignUp
BasicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

export default BasicInfo
