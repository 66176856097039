/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from 'prop-types'
import { useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
// Components
import ScreeningProcessModal from './ScreeningProcessModal/ScreeningProcessModal'

function ScreeningProcessPanel({ title, application }) {

  const [showScreeningProcessModal, setShowScreeningProcessModal] = useState(false)

  const getInterviewStateIcon = () => {
    if (application?.screeningInterviewState === -1) return <SuiBox sx={{ width: '.65rem', height: '.65rem', cursor: 'pointer', borderRadius: '50%', backgroundColor: 'gray' }} />

    if (application?.screeningInterviewState === 0) {
      return (
        <Tooltip title='Pending schedule' placement='top' sx={{ mb: 1, ml: .5 }} onClick={() => setShowScreeningProcessModal(true)}>
          <SuiBox sx={{ width: '.65rem', height: '.65rem', cursor: 'pointer', borderRadius: '50%', backgroundColor: 'red' }} />
        </Tooltip>
      )
    }
    if (application?.screeningInterviewState === 1) {
      return (
        <Tooltip title='Scheduled' placement='top' sx={{ mb: 1, ml: .5 }} onClick={() => setShowScreeningProcessModal(true)}>
          <SuiBox sx={{ width: '.65rem', height: '.65rem', cursor: 'pointer', borderRadius: '50%', backgroundColor: 'yellow' }} />
        </Tooltip>
      )
    } 
    return (
      <Tooltip title='Completed' placement='top' sx={{ mb: 1, ml: .5 }} onClick={() => setShowScreeningProcessModal(true)}>
        <SuiBox sx={{ width: '.65rem', height: '.65rem', cursor: 'pointer', borderRadius: '50%', backgroundColor: 'green' }} />
      </Tooltip>
    )
  }

  const getCVStateIcon = () => {
    if (application?.screeningCVState === 0) {
      return (
        <Tooltip title='Pending review' placement='top' sx={{ mb: 1, ml: .5 }} onClick={() => setShowScreeningProcessModal(true)}>
          <SuiBox sx={{ width: '.65rem', height: '.65rem', cursor: 'pointer', borderRadius: '50%', backgroundColor: 'red' }} />
        </Tooltip>
      )
    } 
    if (application?.screeningCVState === 1) {
      return (
        <Tooltip title='Editing' placement='top' sx={{ mb: 1, ml: .5 }} onClick={() => setShowScreeningProcessModal(true)}>
          <SuiBox sx={{ width: '.65rem', height: '.65rem', cursor: 'pointer', borderRadius: '50%', backgroundColor: 'yellow' }} />
        </Tooltip>
      )
    } 
    return (
      <Tooltip title='Sourcer approved' placement='top' sx={{ mb: 1, ml: .5 }} onClick={() => setShowScreeningProcessModal(true)}>
        <SuiBox sx={{ width: '.65rem', height: '.65rem', cursor: 'pointer', borderRadius: '50%', backgroundColor: 'green' }} />
      </Tooltip>
    )
  }

  // Fuction to format date from UTC with zero UTC offset (2023-06-26T15:30:00), to local timezone with this format "MM/DD/YYYY - hh:mm AM/PM"
  const convertToLocalTime = (utcDate) => {
    const utcDateTime = new Date(`${utcDate  }Z`)
  
    // Get the user's locale-specific options
    const localOptions = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }
  
    // Format the date to local time
    return utcDateTime.toLocaleString([], localOptions)
  }

  return (
    <Card sx={{ maxHeight: '44vh', overflow: 'auto' }}>
      <SuiBox pt={2} px={2} display='flex' justifyContent='space-between' alignItems='center' maxWidth='97%'>
        <SuiTypography variant='h6' fontWeight='medium' textTransform='capitalize'>
          {title}
        </SuiTypography>

        <Tooltip
          title='Edit'
          placement='top'
          sx={{ mb: 1, ml: .5, cursor: 'pointer' }}
          onClick={() => setShowScreeningProcessModal(true)}
        >
          <EditIcon />
        </Tooltip>
      </SuiBox>

      <SuiBox p={2} maxWidth='95%'>
        <SuiBox component='ul' display='flex' flexDirection='column' p={0} m={0}>
          
          <SuiBox display='flex' justifyContent='space-between' alignItems='center'>
            <SuiBox display='flex' alignItems='center'>
              <SuiTypography variant='body2' fontWeight='light' mr={2}>Interview</SuiTypography>
              
              {application?.screeningInterviewDate && application?.screeningInterviewState >= 0 && (
                <SuiTypography variant='button' fontSize='.75rem' fontWeight='light'>
                  {convertToLocalTime(application?.screeningInterviewDate)}
                </SuiTypography>
              )}
            </SuiBox>
            
            {getInterviewStateIcon()}
          </SuiBox>

          <SuiBox display='flex' justifyContent='space-between' alignItems='center'>
            <SuiTypography variant='body2' fontWeight='light'>CV</SuiTypography>
            {getCVStateIcon()}
          </SuiBox>
        </SuiBox>
      </SuiBox>

      {showScreeningProcessModal && (
        <ScreeningProcessModal
          showScreeningProcessModal={showScreeningProcessModal}
          setShowScreeningProcessModal={setShowScreeningProcessModal}
          application={application}
        />
      )}
    </Card>
  );
}

// Typechecking props for the ScreeningProcessPanel
ScreeningProcessPanel.propTypes = {
  title: PropTypes.string.isRequired,
  application: PropTypes.object.isRequired, // eslint-disable-line
};

export default ScreeningProcessPanel;
