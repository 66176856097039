/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react"
// @mui material components
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiSelect from "components/SuiSelect"
import SuiButton from "components/SuiButton"
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField"
import Separator from "../../../CustomSeparator/index"

// eslint-disable-next-line
function Education({ recordId, record, candidateEducation, setCandidateEducation, countries, monthOptions, yearOptions }) {
    // const [currentEndDateMonth, setCurrentEndDateMonth] = useState(null)
    const [currentEndDateYear, setCurrentEndDateYear] = useState(null)

    /* Mui hook to read screen size - We use it for mobile rendering */
    const isMobile = useMediaQuery('(max-width:500px)')
    /* Mui hook to read screen size - We use it for small screen rendering */
    const isSmallScreen = useMediaQuery('(max-width: 1201px)')
    /* Function that handles input changes and updates the candidateEducation state.
    As parameters it takes the name of the field to change and the value to be assigned to the field. */
    const handleInputChange = (inputName, value) => {
        // Copy the current state
        // eslint-disable-next-line
        const prevEducationRecords = candidateEducation.slice()
        // Copy the record the user is editing
        const educationRecord = prevEducationRecords.filter(rec => rec.id === recordId)[0]
        // Assign the new  value to the corresponding field
        educationRecord[inputName] = value
        // Replace the state by including the record with the new value
        if (recordId) {
            const tempCandidateEducations = []
            for (const tempCandidateEducation of [...candidateEducation]) { // eslint-disable-line
              if (tempCandidateEducation.id === educationRecord.id) {
                tempCandidateEducations.push(educationRecord)
              } else {
                tempCandidateEducations.push(tempCandidateEducation)
              }
            }

            setCandidateEducation(tempCandidateEducations)
        } else {
            setCandidateEducation([
                educationRecord,
                ...prevEducationRecords.slice(1)
            ])
        }
    }

    const removeRecord = () => {
        // Copy the current state
        const prevEducationRecords = candidateEducation.slice() // eslint-disable-line
        // Filter out the current experience
        const educationRecord = prevEducationRecords.filter(rec => rec.id !== recordId)
        // Replace the state by the filtered array
        setCandidateEducation(educationRecord)
    }

    const handleDateChange = (monthOrYear, value) => {
        // if (monthOrYear === "month" && currentEndDateYear) handleInputChange("endDate", `${value} ${currentEndDateYear}`)
        if (monthOrYear === "year") handleInputChange("year", `${value}`)
    }

    return (
        <SuiBox>

            {recordId !== 0 && <Separator />}

            {candidateEducation.length > 1 && // eslint-disable-line
                <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                    <SuiButton color="error" variant="outlined" onClick={() => removeRecord()}>Remove record</SuiButton>
                </SuiBox>
            }

            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>School</SuiTypography>
                            <FormField
                                type="text"
                                label="Institution name"
                                name="Institution name"
                                placeholder="Institution name (required)"
                                defaultValue={record?.institute}
                                success={record.institute} // eslint-disable-line
                                onChange={e => handleInputChange("institute", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Degree / Program name</SuiTypography>
                            <FormField
                                type="text"
                                label="Degree name"
                                name="Degree name"
                                placeholder="Degree name (required)"
                                defaultValue={record?.title}
                                success={record.title} // eslint-disable-line
                                onChange={e => handleInputChange("title", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>End date</SuiTypography>
                            <SuiBox xs={6} width="100%" display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between">
                                <Grid item xs={12} md={4.5} mb={isMobile && 2}>
                                    {record?.year ? (
                                        <SuiSelect
                                            placeholder="Year"
                                            label="Year"
                                            smallFont={isSmallScreen}
                                            size={isSmallScreen ? "small" : "medium"}
                                            options={yearOptions}
                                            name="EndDateYear"
                                            defaultValue={{ value: record.year, label: record.year }}
                                            success={currentEndDateYear} // eslint-disable-line
                                            onChange={e => {
                                                setCurrentEndDateYear(e.value)
                                                handleDateChange('year', e.value)
                                            }}
                                        />
                                    ) : (
                                        <SuiSelect
                                            placeholder="Year"
                                            label="Year"
                                            smallFont={isSmallScreen}
                                            size={isSmallScreen ? "small" : "medium"}
                                            options={yearOptions}
                                            name="EndDateYear"
                                            success={currentEndDateYear} // eslint-disable-line
                                            onChange={e => {
                                                setCurrentEndDateYear(e.value)
                                                handleDateChange('year', e.value)
                                            }}
                                        />
                                    ) }
                                </Grid>
                            </SuiBox>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SuiTypography variant="button" sx={{ lineHeight: '1rem' }}>Country</SuiTypography>
                            {record?.country ? (
                                <SuiSelect
                                    placeholder="Country"
                                    label="Country"
                                    options={countries}
                                    name="Institution country"
                                    defaultValue={{ value: record.country, label: record.country }}
                                    success={record.country} // eslint-disable-line
                                    onChange={e => handleInputChange("country", e.value)}
                                />
                            ) : (
                                <SuiSelect
                                    placeholder="Country"
                                    label="Country"
                                    options={countries}
                                    name="Institution country"
                                    success={record.country} // eslint-disable-line
                                    onChange={e => handleInputChange("country", e.value)}
                                />
                            ) }
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </SuiBox>
    )
}

export default Education