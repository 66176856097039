/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { ReactSession } from "react-client-session"
// import { useEffect, useState } from "react"
// import { css } from "@emotion/react";
// import MoonLoader from "react-spinners/MoonLoader";
// @mui material components
import Grid from "@mui/material/Grid"
import Card from '@mui/material/Card'
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"

import { useState, useEffect } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
import { ReactSession } from 'react-client-session';
// MUI components
// import { Icon } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress'
// SUI components
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// Formik
import { Formik, Form } from "formik";
// NewUser layout schemas for form and form feilds
import SignUp from "layouts/pages/users/new-user/components/CreateCandidate";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "examples/Modal/CreateCandidate/schemas/initialValues";


function getContent(formData) {
    return <SignUp formData={formData} />;
}

function CreateCandidate() {

  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");

  useEffect(() => {
    const user = ReactSession.get("user");
    if (!user ||  !(user.type === "tecla_admin" || user.type === "tecla_recruiter" || user.type === "tecla_manager")) {
      window.location.href = "/";
    }
  }, [])

  // const [open, setOpen] = useState(false);
  const [candidateId, setCandidateId] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false)

  const [createdCandidate, setCreatedCandidate] = useState(null) // stores the info of the created candidate which will be used in edit profile wizard

  // const handleOpen = () => {
  //     setOpen(true);
  // };
  const handleClose = () => {
      window.location.href = "/";
  };


  // Validation
  const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = false;
  let timerid = "";

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values, actions) => {
      await sleep(1000);

      // eslint-disable-next-line no-alert
      alert(JSON.stringify(values, null, 2));

      // actions.setSubmitting(false);
      actions.resetForm();

      setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
      if (isLastStep) {
          submitForm(values, actions);
      } else {
          // setActiveStep(activeStep + 1);
          actions.setTouched({});
          // actions.setSubmitting(false);
      }
  };

  const redirectProfile = () => {
      if (candidateId) {
          if (createdCandidate) ReactSession.set("CandidateForEdit", createdCandidate);
          window.open(`/r/editCandidateWizard/${candidateId}`, '_blank');
      }
  };

  function getCandidate(responseCandidateId) {
      const headers = { "Content-Type": "application/json" };
      const url = `${process.env.REACT_APP_API_ROUTE}/api/candidates/${responseCandidateId}/teclaGetCandidate/`;
      headers.Authorization = `Token ${token}`;

      fetch(url, { headers, })
          .then(async response => {
            const data = await response.json();
            const newCandidate = { ...data.data };

            ReactSession.set("CandidateForEdit", newCandidate);
            setCreatedCandidate(newCandidate)
            setCandidateId(responseCandidateId);
            setShowSpinner(false);

          })
          .catch(error => {
            console.error('There was an error!', error);
          });
  }

  const signUpClick = async () => {
      await sleep(100);
      if ($("#new-user-form .MuiTypography-caption:visible").text().length === 0) {
          const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/teclaCreateCandidate/`;

          const postBody = {
              'email': $("input[name=email]").val(),
              'first_name': $("input[name=firstName]").val(),
              'last_name': $("input[name=lastName]").val()
          };

          const requestMetadata = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                  'Authorization': `Token ${token}`
              },
              body: new URLSearchParams(postBody)
          };

          setShowSpinner(true);

          fetch(recipeUrl, requestMetadata)
              .then(res => res.json())
              .then(response => {
                  $(".alertErrorBox").hide();
                  if (response.success) {
                      $(".alertSuccesModalBox div div").text("Candidate account created");
                      $(".alertSuccesModalBox").show();

                      if (timerid) {
                          clearTimeout(timerid);
                      }
                      timerid = setTimeout(() => {
                          $(".alertSuccesModalBox").hide();
                      }, 3000);
                      getCandidate(response.data.id);
                  } else {
                      setShowSpinner(false);
                      let errorMsg = "Error creating account";
                      if (response.data && response.data.error) {
                          errorMsg = response.data.error;
                      }
                      // alertSuccesModalBox
                      $(".alertErrorBox div div").text(errorMsg);
                      $(".alertErrorBox").show();
                      if (timerid) {
                          clearTimeout(timerid);
                      }
                      timerid = setTimeout(() => {
                          $(".alertErrorBox").hide();
                      }, 3000);
                  }
              });
      }
  }

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card xs={12} md={12}  sx={{ height: "auto", padding: "2rem" }}>
              <Grid item xs={12}>
                <SuiBox>
                  <SuiBox className="alertErrorBox" display="none">
                    <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
                  </SuiBox>
                  <SuiBox className="alertSuccesModalBox" display="none">
                    <SuiAlert color="info" className="alertSuccesModal">Please fill all the fields in this section before continuing.</SuiAlert>
                  </SuiBox>
                  <SuiBox display="flex">
                      <SuiTypography id="modal-modal-description" variant="h2" fontWeight="bold">
                          Create Candidate
                      </SuiTypography>
                  </SuiBox>
              </SuiBox>

              <SuiBox display="flex">
                  <SuiBox sx={{ width: { xs: "100%", md: "100%" } }} mr={2}>
                      <Formik
                          initialValues={initialValues}
                          validationSchema={currentValidation}
                          onSubmit={handleSubmit}
                      >
                          {({ values, errors, touched, issubmitting }) => (
                              <Form id={formId} autoComplete="off" type="POST" actions="#">
                                  <SuiBox>
                                      {getContent({
                                          values,
                                          touched,
                                          formField,
                                          errors,
                                      })}

                                      <SuiBox mt={4}>
                                          { candidateId ? (
                                              <SuiBox display="flex" justifyContent="space-evenly" alignItems="center" flexWrap="wrap" mt={2} mb={1} className="editProfile">
                                                  <SuiButton size="large" onClick={redirectProfile} mr={2} color="warning" sx={{ color: "#000", width: { xs: "100%", sm: "15rem", md: "initial" }, height: { xs: "44px", sm: "initial" }, marginBottom: 1, backgroundColor: "#feec1e" }}>
                                                      Edit candidate profile
                                                  </SuiButton>
                                              </SuiBox>
                                          ) : (
                                              <SuiBox sx={{ textAlign: "right" }} justifyContent="space-evenly" alignItems="right" flexWrap="wrap" mt={2} mb={1}>
                                                  <SuiButton size="large" color="info" onClick={handleClose} variant="gradient" circular sx={{ marginRight: "10px" }}>
                                                      Cancel
                                                  </SuiButton>
                                                  {showSpinner ? (
                                                    <SuiButton sx={{ background: "#fff !important", boxShadow: "none !important" }} size="large" color="white" variant="gradient" circular>
                                                      <CircularProgress />
                                                    </SuiButton>)
                                                  : (
                                                      <SuiButton size="large" type="submit" color="info" issubmitting={issubmitting} variant="gradient" onClick={signUpClick} circular>
                                                          Create
                                                      </SuiButton>
                                                  ) }
                                              </SuiBox>
                                          ) }
                                      </SuiBox>

                                  </SuiBox>
                              </Form>
                          )}
                      </Formik>
                  </SuiBox>
              </SuiBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>


    </RecruiterDashboardLayout >
  )
}

export default CreateCandidate