/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react"
import { ReactSession } from "react-client-session"
// import { useParams } from "react-router-dom";
// formik components and data
import { Formik, Form } from "formik"
import validations from "layouts/cvBuilder/formSchemas/validations"
import form from "layouts/cvBuilder/formSchemas/form"
// import initialValues from "layouts/cvBuilder/formSchemas/initialValues"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
// import Stepper from "@mui/material/Stepper"
// import Step from "@mui/material/Step"
// import StepLabel from "@mui/material/StepLabel"
import CircularProgress from "@mui/material/CircularProgress"
// import useMediaQuery from "@mui/material/useMediaQuery"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// import SuiButton from "components/SuiButton"
import SuiAlert from "components/SuiAlert"
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
// Wizard page components
import BasicInfo from "layouts/cvBuilder/components/basicInfo/BasicInfo"
import Skills from "layouts/cvBuilder/components/skills/Skills"
import ProfessionalExperience from "layouts/cvBuilder/components/professionalExperience/ProfessionalExperience"
import Education from "layouts/cvBuilder/components/education/Education"
import Download from "layouts/cvBuilder/components/download/Download"


function CvBuilder() {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const { formId, formField } = form
  const [cvBuildData, setCvBuildData] = useState({})

  const currentValidation = validations[0]

  // const { cId } = useParams();

  /* Mui hook to read screen size - We use it for mobile rendering */
  // const isMobile = useMediaQuery('(max-width:500px)')

  /* These state and function are used to diplay the country input options. */
  const [countries, setCountries] = useState([])
  const [country, setCountry] = useState(null)
  /* Spinner that shows when awaiting for the request response */
  const [showSpinner, setShowSpinner] = useState(true)
  const [showButtons, setShowButtons] = useState(false)
  /* These state and function are used to diplay the skills input options. */
  const [candidateSkills, setCandidateSkills] = useState([])
  /* State for Skills, Job experience and Education inputs */
  const [candidateSkillsIds, setCandidateSkillsIds] = useState([])
  const [skillOptions, setSkillOptions] = useState([])
  const [candidateJobExperiences, setCandidateJobExperiences] = useState([{
    id: 0,
    company: "",
    position: "",
    startDate: "",
    endDate: "",
    country: "",
    state: "",
    tasks: [],
    achievements: []
  }])
  const [candidateEducation, setCandidateEducation] = useState([{
    id: 0,
    institute: "",
    country: "",
    state: "",
    title: "",
    year: ""
  }])

  useEffect(() => {
    document.title = `CV Builder | TECLA`;
  }, []);

  const fetchCountryOptions = () => {

    const fetchHeaders = token ?
      { 'Authorization': `Token ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' }
      :
      { 'Content-Type': 'application/x-www-form-urlencoded' }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/countries/`, {
      method: 'GET',
      headers: fetchHeaders
    })
      .then(async response => {
        const data = await response.json()
        const result = []

        if (data.success) {
          Object.values(data.data).forEach(val => result.push({ value: val, label: val }))
        } else {
          throw new Error('Error fetching countries')
        }

        setCountries(result)
      })
      .catch(error => console.error('There was an error!', error))
  }

  const fetchCVBuilderData = () => {
    const currentUrl = window.location.href
    const candidateId = currentUrl.substr(currentUrl.lastIndexOf("/") + 1, currentUrl.length);

    const fetchHeaders = token ?
      { 'Authorization': `Token ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' }
      :
      { 'Content-Type': 'application/x-www-form-urlencoded' }

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/${candidateId}/getCVBuilderData/`, {
      method: 'GET',
      headers: fetchHeaders
    })
      .then(async response => {
        const data = await response.json()

        if (data.success) {
          setCvBuildData(data.data)
          if (data.data?.skills && data.data?.skills.length) {
            const tempSkills = []
            const tempSkillsIds = []
            for (const skill of data.data.skills) { // eslint-disable-line
              tempSkills.push(skill.name)
              tempSkillsIds.push(skill.id)
            }
            setCandidateSkills(tempSkills)
            setCandidateSkillsIds(tempSkillsIds)
          }
          let count = 0;
          if (data.data?.experience && data.data?.experience.length) {
            const tempExperiences = []
            for (const tempExp of data.data.experience) { // eslint-disable-line
              count += 1;
              tempExp.id = count
              tempExperiences.push(tempExp)
            }
            setCandidateJobExperiences(tempExperiences)
          }
          count = 0;
          if (data.data?.education && data.data?.education.length) {
            const tempEducations = []
            for (const tempEdu of data.data.education) { // eslint-disable-line
              count += 1;
              tempEdu.id = count
              tempEducations.push(tempEdu)
            }
            setCandidateEducation(tempEducations)
          }
          if (data.data?.country) {
            setCountry(data.data?.country)
          }
          setShowSpinner(false)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => {
    fetchCVBuilderData();
  }, []);

  /* Year and month options for date pickers */
  const monthOptions = [
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' }
  ]

  const yearOptions = []
  for (let i = new Date().getFullYear(); i >= 1970; i -= 1) yearOptions.push({ value: i, label: i })

  /* Function to show input validation error on each wizard step */
  function showError() {
    let timerSuccessid = "";
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 5000);
  }

  /* State to store the url we get after wizard is submited */
  const [cvUrl, setCvUrl] = useState(null)
  // const [cvUrl, setCvUrl] = useState("http://198.58.104.201:8000/media/uploads/germ%C3%A1n_cocca_w1yph.pdf")

  // Counter and labels used for the stepper
  const [activeStep, setActiveStep] = useState(0)

  // const stepLabels = ["Personal Details", "Skills", "Experience", "Education", "Generate!"]

  /* Function responsible for grouping form inputs, validating them and posting the data to back-end.
  Basic data and Bio come from Formik values. Skills, Job experience and Education have their own state. */
  const postCV = async formValues => {

    let errorsDetected = false
    if ($("form .MuiTypography-caption:visible").text().length || 
      !formValues.firstName || !formValues.firstName.length || 
      !formValues.lastName || !formValues.lastName.length || 
      !formValues.email || !formValues.email.length || 
      !candidateSkillsIds.length) {
      errorsDetected = true
    }

    const newCandidateJobExperiences = [...candidateJobExperiences]
    if (newCandidateJobExperiences.length) {
      for (var i = 0; i < $(".taskBox").length; i++) { // eslint-disable-line
        newCandidateJobExperiences[i].tasks = []
        for (var x = 0; x < $($(".taskBox")[i]).find(".taskInput").length; x++) { // eslint-disable-line
          newCandidateJobExperiences[i].tasks.push($($($(".taskBox")[i]).find(".taskInput")[x]).find("textarea").val())
        }
      }
    }
    candidateJobExperiences.forEach(exp => { // eslint-disable-line
      if (!exp.company || !exp.position || !exp.startDate || !exp.endDate || !exp.tasks.length) {
        errorsDetected = true
      }
    })

    candidateEducation.forEach(record => { // eslint-disable-line
      if (!record.institute || !record.title) {
        errorsDetected = true
      }
    })

    const currentUrl = window.location.href
    const candidateId = currentUrl.substr(currentUrl.lastIndexOf("/") + 1, currentUrl.length);

    if (!errorsDetected) {
      const obj = {
        ...formValues,
        country: country,
        skills: candidateSkillsIds,
        experience: newCandidateJobExperiences,
        education: candidateEducation,
        userId: candidateId
      }

      setShowSpinner(true)

      const fetchHeaders = token ?
        { 'Authorization': `Token ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' }
        :
        { 'Accept': 'application/json', 'Content-Type': 'application/json' }

      await fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/buildCV/`, {
        method: 'POST',
        headers: fetchHeaders,
        body: JSON.stringify(obj)
      })
        .then(async response => {
          const data = await response.json()

          if (data.success) {
            setCvUrl(process.env.REACT_APP_API_ROUTE + data.data.cv)
            setActiveStep(4)
            setShowSpinner(false)
            setShowButtons(true)
          }
        })
        .catch(error => console.error('There was an error!', error))

      setActiveStep(activeStep + 1)

    } else {
      showError()
    }

  }

  // Function to display each wizard step
  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <BasicInfo formData={{ ...formData, activeStep, setActiveStep, showError, countries, country, setCountry }} />
      case 1:
        return <Skills formData={{ ...formData, candidateSkillsIds, setCandidateSkillsIds, activeStep, setActiveStep, showError, candidateSkills, setCandidateSkills, skillOptions, setSkillOptions }} />
      case 2:
        return <ProfessionalExperience formData={{ ...formData, countries, candidateJobExperiences, setCandidateJobExperiences, candidateSkillsIds, candidateSkills, monthOptions, yearOptions, activeStep, setActiveStep, showError, cvBuildData, skillOptions }} />
      case 3:
        return <Education formData={{ ...formData, countries, candidateEducation, setCandidateEducation, monthOptions, yearOptions, activeStep, setActiveStep, showError, postCV, cvBuildData }} />
      case 4:
        return <Download cvUrl={cvUrl} />
      default:
        return null
    }
  }

  useEffect(() => { fetchCountryOptions() }, [])

  const {
    formField: {
      email,
      firstName,
      lastName,
      phone,
      bio,
      linkedin,
    },
  } = form;

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">CV Builder</SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" color="secondary">Tool for creating great-looking new CVs for candidates</SuiTypography>
            </SuiBox>

            

            <SuiBox className="alertErrorBox" display="none">
              <SuiAlert color="error" className="alertError">Please fill all the required fields in this section before continuing.</SuiAlert>
            </SuiBox>

            <Card>
              {!showSpinner ? (
                <SuiBox p={2}>
                  <SuiBox>
                    <Formik validationSchema={currentValidation} initialValues={{
                        [email.name]: cvBuildData?.email || "",
                        [firstName.name]: cvBuildData?.first_name || "",
                        [lastName.name]: cvBuildData?.last_name || "",
                        [phone.name]: cvBuildData?.phone || "",
                        [bio.name]: cvBuildData?.bio || "",
                        [linkedin.name]: cvBuildData?.linkedin || "",
                      }}
                    >
                      {({ values, errors, touched }) => (
                        <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">

                          {!showSpinner ? (
                            <>
                              {!showButtons ? (<>
                                <SuiBox>
                                  {getStepContent(0, { values, touched, formField, errors })}
                                </SuiBox>
                                <SuiBox>
                                  {getStepContent(1, { values, touched, formField, errors })}
                                </SuiBox>
                                <SuiBox>
                                  {getStepContent(2, { values, touched, formField, errors })}
                                </SuiBox>
                                <SuiBox>
                                  {getStepContent(3, { values, touched, formField, errors })}
                                </SuiBox>
                              </>) : (
                                <SuiBox>
                                  {getStepContent(4, { values, touched, formField, errors })}
                                </SuiBox>
                              ) }
                            </>)
                            :
                            (
                              <SuiBox mt={3} sx={{ height: 400 }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                <CircularProgress />
                                <SuiTypography variant="h4" fontWeight="regular" color="secondary" mt={5} textAlign="center">Please wait, your CV is being created.</SuiTypography>
                              </SuiBox>
                            )
                          }

                        </Form>
                      )}
                    </Formik>
                  </SuiBox>
                </SuiBox>
              )
              :
              (
                <SuiBox mt={3} sx={{ height: 400 }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <CircularProgress />
                  <SuiTypography variant="h4" fontWeight="regular" color="secondary" mt={5} textAlign="center">Please wait, CV data is loading.</SuiTypography>
                </SuiBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </RecruiterDashboardLayout>
  )
}

export default CvBuilder
